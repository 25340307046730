export const DATE_FORMAT = {
  YYYYMMDD: "YYYY/MM/DD",
  YYYYMMDDHHmm: "YYYY/MM/DD HH:mm",
  HHmm: "HH:mm",
  YYYY_MM_DD: "YYYY-MM-DD",
  HH00: "HH:00"
};

export const DATE_FORMAT_SEND_TO_SERVER = {
  YYYYMMDDHHmmss: "YYYY-MM-DD HH:mm:ss",
  YYYYMMDD: "YYYY-MM-DD",
  YYYYMMDDHHmm: "YYYY-MM-DD HH:mm",
  YYYYMMDDHH00: "YYYY-MM-DD HH:00",
  YYYYMMDDHHmmssz: "YYYY-MM-DD HH:mm:ssZ",
};
