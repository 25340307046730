import { useEffect, useMemo, useRef, useState } from "react";
import styles from "./ProgressNote.module.scss";
import { Card, Flex } from "antd";
import ProgressNoteHeader from "./header/ProgressNoteHeader";
import "./ProgressNote.scss";
import IcuButton from "../../../common/button/IcuButton";
import { PlusCircleFilled } from "@ant-design/icons";
import TimeButton from "./time-button/TimeButton";
import { cloneDeep } from "lodash";
import dayjs from "dayjs";
import {
  DATE_FORMAT,
  DATE_FORMAT_SEND_TO_SERVER,
} from "../../../../constants/common/dateFormat.constant";
import { useDispatch, useSelector } from "react-redux";
import {
  getProgressNoteList,
  selectProgressNoteList,
  setProgressNoteList,
  setUpdateInfoTime,
} from "../../../../redux/slices/patientSlice";
import { ProgressNoteEntity } from "../../../../types/patient-detail/ProgressNote.model";
import {
  createProgressNote,
  getProgressNoteDetail,
  updateProgressNote,
} from "../../../../services/progress-note/progressNoteService";
import { AppDispatch } from "../../../../redux/store";
import { PayloadAction } from "@reduxjs/toolkit";
import { BaseResponse } from "../../../../types/common/BaseResponse.model";
import { ProgressNoteReadResponse } from "../../../../types/patient-detail/ProgressNoteReadResponse.model";
import { formatProgressNoteList } from "../../../../utils/patient-detail/progress-note/ProgressNote.helper";
import {
  addQueryParamToCurrentURL,
  formatDateBeforeSendToServer,
} from "../../../../utils/helpers";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useTranslation } from "react-i18next";
import { PROGRESS_NOTE_PARAMS } from "../../../../constants/common/queryParams.constant";

interface ProgressNoteProps {
  admissionRecordId: number;
  progressNoteFilterDate?: Date;
  setProgressNoteFilterDate: React.Dispatch<
    React.SetStateAction<Date | undefined>
  >;
  currentProgressNote: ProgressNoteEntity | undefined;
  setCurrentProgressNote: React.Dispatch<
    React.SetStateAction<ProgressNoteEntity | undefined>
  >;
}

const ProgressNote = ({
  admissionRecordId,
  progressNoteFilterDate,
  setProgressNoteFilterDate,
  currentProgressNote,
  setCurrentProgressNote,
}: ProgressNoteProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const progressNoteList = useSelector(selectProgressNoteList);

  const [isDisabledAddButton, setIsDisabledAddButton] = useState(false);

  const progressNoteRef = useRef<ReactQuill>(null);

  const [progressNoteDetail, setProgressNoteDetail] =
    useState(currentProgressNote);

  useEffect(() => {
    const fetchDetail = async () => {
      if (currentProgressNote?.id) {
        const response = await getProgressNoteDetail(currentProgressNote?.id);
        setProgressNoteDetail(() => formatProgressNoteList([response.data])[0]);
      }
    };

    fetchDetail();
  }, [currentProgressNote]);

  useEffect(() => {
    if (
      dayjs(progressNoteFilterDate).format(DATE_FORMAT.YYYYMMDD) !==
      dayjs(new Date()).format(DATE_FORMAT.YYYYMMDD)
    ) {
      setIsDisabledAddButton(true);
    }
    addQueryParamToCurrentURL({
      [PROGRESS_NOTE_PARAMS.progressNoteFilterDate]: dayjs(
        progressNoteFilterDate
      ).format(DATE_FORMAT.YYYYMMDD),
    });
  }, [progressNoteFilterDate]);

  const handleDateChange = async (date: string) => {
    if (
      dayjs(date).format(DATE_FORMAT.YYYYMMDD) !==
      dayjs(new Date()).format(DATE_FORMAT.YYYYMMDD)
    ) {
      setIsDisabledAddButton(true);
    } else {
      setIsDisabledAddButton(false);
    }

    setProgressNoteFilterDate(new Date(date));

    const response = (await dispatch(
      getProgressNoteList({
        admissionRecordId,
        date: formatDateBeforeSendToServer(
          date,
          DATE_FORMAT_SEND_TO_SERVER.YYYYMMDDHHmmss
        ),
      })
    )) as PayloadAction<BaseResponse<ProgressNoteReadResponse[]>>;

    if (response.payload.data.length > 0) {
      setCurrentProgressNote(formatProgressNoteList(response.payload.data)[0]);
    } else {
      setCurrentProgressNote(undefined);
    }
  };

  const handleAddTimeItem = () => {
    setIsDisabledAddButton(true);
    const newProgressNoteList = cloneDeep(progressNoteList).map(
      (note: ProgressNoteEntity) => {
        return {
          ...note,
          isActive: false,
        };
      }
    );

    const newProgressNote = {
      id: 0,
      idAdmissionRecord: progressNoteList.length + 1,
      assessment: "",
      isActive: true,
      createdBy: "",
    };

    newProgressNoteList.unshift(newProgressNote);

    dispatch(setProgressNoteList(newProgressNoteList));
    setCurrentProgressNote(newProgressNote);
  };

  const handleClickTimeBtn = (id: number) => {
    const newProgressNote = progressNoteList.find((note) => {
      return note.id === id;
    });
    if (newProgressNote) {
      setCurrentProgressNote(newProgressNote);
    }
  };

  const progressNoteDate = useMemo(() => {
    return progressNoteFilterDate
      ? formatDateBeforeSendToServer(
          progressNoteFilterDate,
          DATE_FORMAT_SEND_TO_SERVER.YYYYMMDDHHmmss
        )
      : undefined;
  }, [progressNoteFilterDate]);

  const handleBlurProgressNoteInput = (value: string) => {
    setTimeout(async () => {
      if (
        currentProgressNote &&
        currentProgressNote.createdAt === undefined &&
        value === ""
      ) {
        const newProgressNoteList = cloneDeep(progressNoteList);

        newProgressNoteList.shift();

        dispatch(setProgressNoteList(newProgressNoteList));

        if (newProgressNoteList.length > 0) {
          setCurrentProgressNote(newProgressNoteList[0]);
        } else {
          setCurrentProgressNote(undefined);
        }
      } else if (
        currentProgressNote?.createdAt !== undefined &&
        value !== currentProgressNote.assessment
      ) {
        await updateProgressNote({
          id: currentProgressNote.id,
          assessment: value,
        });

        dispatch(
          getProgressNoteList({
            admissionRecordId,
            date: progressNoteDate,
          })
        );

        setCurrentProgressNote({
          ...currentProgressNote,
          assessment: value,
        });
      } else if (
        currentProgressNote &&
        currentProgressNote.createdAt === undefined &&
        value !== ""
      ) {
        await createProgressNote({
          id_admission_record: admissionRecordId,
          assessment: value,
        });

        const response = (await dispatch(
          getProgressNoteList({
            admissionRecordId,
            date: progressNoteDate,
          })
        )) as PayloadAction<BaseResponse<ProgressNoteReadResponse[]>>;

        setCurrentProgressNote(
          formatProgressNoteList(response.payload.data)[0]
        );
      }

      setIsDisabledAddButton(false);
    }, 150);
  };

  const handleChangeProgressNoteInput = () => {
    dispatch(setUpdateInfoTime(dayjs(new Date()).format(DATE_FORMAT.HHmm)));
  };

  return (
    <Card
      title={
        <ProgressNoteHeader
          onDateChange={handleDateChange}
          defaultValue={dayjs(progressNoteFilterDate)}
        />
      }
      bordered={false}
      className={`${styles.progressNoteContainer} progress-note-container`}
    >
      <Flex gap={16}>
        <div>
          <IcuButton
            width={94}
            height={34}
            extraClassName="progress-note-add-btn"
            onClick={handleAddTimeItem}
            disabled={isDisabledAddButton}
          >
            <PlusCircleFilled />
          </IcuButton>

          {currentProgressNote &&
            progressNoteList.map((item) => (
              <TimeButton
                key={item.id}
                data={item}
                author={item.createdAt ? item.createdBy : ""}
                isActive={item.id === currentProgressNote.id}
                onClick={handleClickTimeBtn}
              />
            ))}
        </div>
        {currentProgressNote && (
          <ReactQuill
            key={progressNoteDetail?.id}
            theme="snow"
            value={
              progressNoteDetail?.id === currentProgressNote.id
                ? progressNoteDetail?.assessment
                : ""
            }
            modules={{
              toolbar: ["bold", "image"],
            }}
            onChange={handleChangeProgressNoteInput}
            onKeyDown={(e: any) => {
              if (e.key === "Enter" && e.ctrlKey) {
                const quill = progressNoteRef.current?.getEditor();
                const html = quill?.root.innerHTML ?? "";
                handleBlurProgressNoteInput(html);
              }
            }}
            onBlur={(previousRange, source, editor) => {
              if (source !== "user") {
                return;
              }
              handleBlurProgressNoteInput(editor.getHTML());
            }}
            ref={progressNoteRef}
            placeholder={t("Detail")}
          />
        )}
      </Flex>
    </Card>
  );
};

export default ProgressNote;
