import { Input, InputRef, Modal, Table } from "antd";
import { ColumnType } from "antd/es/table";
import { useTranslation } from "react-i18next";
import {
  MedicineInDataSource,
  MedicineInEntity,
} from "../../../../types/vital-sign/MedicineInEntity.model";
import {
  ChangeEvent,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { SearchOutlined } from "@ant-design/icons";
import "./MedicineModal.scss";
import { MedicineResponse } from "../../../../types/common/BaseResponse.model";
import { MedicineColumn } from "../InTable";
import MedicineListArea from "./MedicineListArea";

interface SelectMedicineModalProps {
  isShow: boolean;
  handleClose: () => void;
  handleFetchMedicineList: (
    page?: number,
    limit?: number,
    search?: string,
    id_in_balance?: number
  ) => void;
  medicineDataResponse: MedicineResponse<MedicineInEntity[]> | undefined;
  handleInsertInDataTable: () => void;
  setSelectedMedicineInKeys: React.Dispatch<SetStateAction<React.Key[]>>;
  selectedKeys?: React.Key[];
  isCopy?: boolean;
  setShowEditModal: React.Dispatch<SetStateAction<boolean>>;
  currentInBalance: MedicineColumn | undefined;
  selectedRows: MedicineInDataSource[];
  setSelectedRows: React.Dispatch<SetStateAction<MedicineInDataSource[]>>;
}

const columns: ColumnType<MedicineInDataSource>[] = [
  {
    title: "CODE",
    dataIndex: "code",
    key: "code",
  },
  {
    title: "NAME",
    dataIndex: "name",
    key: "name",
  },
];

const SelectMedicineModal = ({
  isShow,
  handleClose,
  handleFetchMedicineList,
  medicineDataResponse,
  handleInsertInDataTable,
  selectedKeys,
  setSelectedMedicineInKeys,
  isCopy,
  setShowEditModal,
  currentInBalance,
  selectedRows,
  setSelectedRows,
}: SelectMedicineModalProps) => {
  const { t } = useTranslation();

  const [dataSource, setDataSource] = useState<MedicineInDataSource[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>(
    selectedKeys ?? []
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);

  const pageSize = 5;

  const [search, setSearch] = useState("");

  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    handleFetchMedicineList(
      currentPage,
      pageSize,
      search,
      currentInBalance?.in_balance_id
    );
  }, []);

  useEffect(() => {
    const data = medicineDataResponse?.data.map((medicine) => ({
      key: medicine.id,
      name: medicine.name,
      code: medicine.code,
    }));
    if (data) {
      setDataSource(data);
    }
    setTotal(medicineDataResponse?.total_data ?? 0);
  }, [medicineDataResponse]);

  const handleSlected = (record: MedicineInDataSource, selected: boolean) => {
    const newSelectedRowKeys = selected
      ? [...selectedRowKeys, record.key]
      : selectedRowKeys.filter((key) => key !== record.key);
    const newSelectedRows = selected
      ? [...selectedRows, record]
      : selectedRows.filter((row) => row.key !== record.key);
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedMedicineInKeys(newSelectedRowKeys);
    setSelectedRows(newSelectedRows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (
      selectedKeys: React.Key[],
      currentSelectedRows: MedicineInDataSource[]
    ) => {
      const removeKeys: React.Key[] = [];
      dataSource.forEach((medicine) => {
        if (!selectedKeys.includes(medicine.key)) {
          removeKeys.push(medicine.key);
        }
      });
      const newSelectedRowKeys = selectedRowKeys.filter(
        (key) => !removeKeys.includes(key)
      );

      selectedKeys = selectedKeys.filter(
        (key) => !selectedRowKeys.includes(key)
      );

      const allSelectedKeys = [...newSelectedRowKeys, ...selectedKeys];
      const newSelectedRows = selectedRows.filter(
        (medicine) => !removeKeys.includes(medicine.key)
      );
      currentSelectedRows = currentSelectedRows.filter(
        (medicine) => !selectedRowKeys.includes(medicine.key)
      );
      const allSelectedRows = [...newSelectedRows, ...currentSelectedRows];
      setSelectedMedicineInKeys(allSelectedKeys);
      setSelectedRowKeys(allSelectedKeys);
      setSelectedRows(allSelectedRows);
    },
  };

  const onRowClick = (record: MedicineInDataSource) => {
    handleSlected(record, !selectedRowKeys.includes(record.key));
  };
  const handleTableChange = (pagination: any) => {
    setCurrentPage(pagination.current);
    handleFetchMedicineList(
      pagination.current,
      pageSize,
      search,
      currentInBalance?.in_balance_id
    );
  };

  const handleOk = async () => {
    if (isCopy) {
      setShowEditModal(true);
    } else {
      handleInsertInDataTable();
    }

    handleClose();
  };

  const handleSearchMedicine = (event: ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(1);
    setSearch(event.target.value);
    handleFetchMedicineList(
      1,
      pageSize,
      event.target.value,
      currentInBalance?.in_balance_id
    );
  };
  return (
    <>
      <Modal
        title={t("Select drug")}
        open={isShow}
        onCancel={handleClose}
        width={751}
        className="medicine-modal select-in-medicine-modal"
        okText={isCopy ? t("Next") : t("Save")}
        centered
        onOk={handleOk}
        okButtonProps={{ disabled: !selectedRowKeys.length }}
        maskClosable={false}
      >
        <div>
          <Input
            placeholder={t("Search...")}
            prefix={<SearchOutlined className="medicine-search-outlined" />}
            className="select-in-medicine-search"
            onBlur={(event) => handleSearchMedicine(event)}
            onPressEnter={(e: any) => {
              if (e.keyCode === 13) {
                inputRef.current?.blur();
              }
            }}
            ref={inputRef}
          />
          <MedicineListArea
            medicineList={selectedRows}
            handleSlected={handleSlected}
          />
          <Table
            dataSource={dataSource}
            columns={columns}
            rowSelection={rowSelection}
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              total: total ?? 0,
              showSizeChanger: false,
            }}
            onChange={handleTableChange}
            className="select-in-medicine-table"
            onRow={(record) => ({
              onClick: () => onRowClick(record),
            })}
          />
        </div>
      </Modal>
    </>
  );
};

export default SelectMedicineModal;
