export enum MEDICINE_UNIT {
    SPEED = "ml/hr",
    AMOUNT = "ml"
}

export const MEDICINE_UNIT_OPTIONS = [
    {
        key: 1,
        value: "g",
        text: "g"
    },
    {
        key: 2,
        value: "mg",
        text: "mg"
    },
    {
        key: 3,
        value: "l",
        text: "l"
    },
    {
        key: 4,
        value: "ml",
        text: "ml"
    }
]

export const MEDICINE_DELETE_OPTIONS = {
    forever: "Forever",
    today: "Today"
}

export const MEDICINE_FREQUENCY_OPTIONS = [
    {
        key: 1,
        value: 1,
        text: "1 per day"
    },
    {
        key: 2,
        value: 2,
        text: "2 per day"
    },
    {
        key: 3,
        value: 3,
        text: "3 per day"
    },
    {
        key: 4,
        value: 4,
        text: "4 per day"
    },
    {
        key: 5,
        value: 6,
        text: "6 per day"
    }
]