import { Flex } from "antd";
import dayjs from "dayjs";
import { cloneDeep } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NoteSection from "../../components/patient-detail/note-section/NoteSection";
import PersonalCard from "../../components/patient-detail/personal-card/PersonalCard";
import { ProblemList } from "../../components/patient-detail/problem-list/ProblemList";
import { DATE_FORMAT } from "../../constants/common/dateFormat.constant";
import {
  getPatientById,
  selectPatientInfo,
  selectPatientLoading,
  setPatientLoading,
  setUpdateInfoTime,
} from "../../redux/slices/patientSlice";
import { ProblemItemEntity } from "../../types/patient-detail/ProblemItem.model";
import { TabFilterPatientDetail } from "../../constants/patient-detail/TabFilterPersonalDetail";
import { ProblemListRef } from "../../types/patient-list/ProblemListRef.model";
import { formatProblemList } from "../../utils/patient-detail/problem-list/ProblemList.helper";
import styles from "./PatientDetailPage.module.scss";
import IcuHeader from "../../components/common/header/IcuHeader";
import Wrapper from "../../components/common/wrapper/Wrapper";
import IcuFooter from "../../components/common/footer/IcuFooter";
import { useLocation, useParams } from "react-router-dom";
import { LoadingOverlay } from "../../components/common/loading/LoadingOverlay";
import { AppDispatch } from "../../redux/store";
import {
  createProblemItem,
  deleteProblemItem,
  getProblemList,
  updateProblemItem,
} from "../../services/problem-list/problemListService";
import { UpdatedProblemTypeParams } from "../../types/patient-detail/UpdatedProblemTypeParams.model";
import { PROBLEM_LIST_UPDATE_REQUEST_KEY } from "../../constants/patient-detail/ProblemListUpdateRequestKey.constant";
import { ProgressNoteEntity } from "../../types/patient-detail/ProgressNote.model";
import { DOCUMENT_HEADER } from "../../constants/common/documentHeader.constant";
import { useTranslation } from "react-i18next";
import {
  addQueryParamToCurrentURL,
  changeFavicon,
  removeQueryParam,
} from "../../utils/helpers";
import "./PatientDetailPage.scss";
import {
  PATIENT_DETAIL_PARAMS,
  PROGRESS_NOTE_PARAMS,
} from "../../constants/common/queryParams.constant";

const PatientDetailPage = () => {
  const [problemList, setProblemList] = useState<ProblemItemEntity[]>([]);

  const [activeTab, setActiveTab] = useState(TabFilterPatientDetail.ADMISSION);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const progressNoteFilterDateParam = queryParams.get(
    PROGRESS_NOTE_PARAMS.progressNoteFilterDate
  );

  const [progressNoteFilterDate, setProgressNoteFilterDate] = useState<
    Date | undefined
  >(
    dayjs(progressNoteFilterDateParam).isValid()
      ? dayjs(progressNoteFilterDateParam).toDate()
      : undefined
  );

  const [currentProgressNote, setCurrentProgressNote] = useState<
    ProgressNoteEntity | undefined
  >(undefined);

  const problemListRef = useRef<ProblemListRef>(null);

  const dispatch = useDispatch<AppDispatch>();

  const { t } = useTranslation();

  const loadingGetPatientDetail = useSelector(selectPatientLoading);
  const patientInfo = useSelector(selectPatientInfo);

  const { id: admissionRecordId } = useParams();

  const fetchPatientById = async (admissionRecordId: string | undefined) => {
    if (admissionRecordId) {
      dispatch(getPatientById(Number(admissionRecordId)));
    }
  };

  const fetchProblemList = async (admissionRecordId: string | undefined) => {
    if (admissionRecordId) {
      const response = await getProblemList(Number(admissionRecordId));

      if (response) {
        setProblemList(formatProblemList(response.data));
      }
    }
  };

  useEffect(() => {
    document.title = t(DOCUMENT_HEADER.patientDetail.title);
    changeFavicon(DOCUMENT_HEADER.patientDetail.icon);

    fetchPatientById(admissionRecordId);
    fetchProblemList(admissionRecordId);
  }, []);

  useEffect(() => {
    addQueryParamToCurrentURL({
      [PATIENT_DETAIL_PARAMS.activeTab]: activeTab,
    });
    if (activeTab !== TabFilterPatientDetail.PROGRESS_NOTE) {
      removeQueryParam(PROGRESS_NOTE_PARAMS.progressNodeId);
      removeQueryParam(PROGRESS_NOTE_PARAMS.progressNoteFilterDate);
    }
  }, [activeTab]);

  useEffect(() => {
    if (currentProgressNote?.id) {
      addQueryParamToCurrentURL({
        [PROGRESS_NOTE_PARAMS.progressNodeId]: currentProgressNote?.id,
      });
    } else {
      removeQueryParam(PROGRESS_NOTE_PARAMS.progressNodeId);
    }
  }, [currentProgressNote]);

  const handleAddProblem = () => {
    problemListRef.current?.disableAddBtn();
    const newProblemList = cloneDeep(problemList);

    newProblemList.unshift({
      remarkEntry: "",
      pastMedicalHistoryCheck: false,
      suspicionCheck: false,
      id: undefined,
    });

    setProblemList(newProblemList);
  };

  const handleDeleteProblem = async (id?: number) => {
    if (id === undefined) {
      problemListRef.current?.enableAddBtn();
      const newProblemList = cloneDeep(problemList);
      newProblemList.shift();
      setProblemList(newProblemList);
    } else if (patientInfo) {
      dispatch(setPatientLoading(true));

      await deleteProblemItem(id);

      const { data: newProblemList } = await getProblemList(
        patientInfo.id_admission_record
      );

      setProblemList(formatProblemList(newProblemList));
      dispatch(setPatientLoading(false));
    }

    dispatch(setUpdateInfoTime(dayjs(new Date()).format(DATE_FORMAT.HHmm)));
  };

  const handleBlurProblemInput = async (value: string, id?: number) => {
    let newProblemList: ProblemItemEntity[] = cloneDeep(problemList);

    if (id === undefined && value === "") {
      newProblemList.shift();
      setProblemList(newProblemList);
    } else if (id === undefined && value !== "" && patientInfo) {
      dispatch(setPatientLoading(true));
      const newProblemItem = {
        id_admission_record: patientInfo.id_admission_record,
        suspicion_check: false,
        past_medical_history_check: false,
        remark_entry: value,
      };

      await createProblemItem(newProblemItem);

      const { data: newProblemList } = await getProblemList(
        patientInfo.id_admission_record
      );

      setProblemList(formatProblemList(newProblemList));
    } else if (patientInfo && id) {
      dispatch(setPatientLoading(true));
      await updateProblemItem(id, {
        remark_entry: value,
      });

      const { data: newProblemList } = await getProblemList(
        patientInfo.id_admission_record
      );

      setProblemList(formatProblemList(newProblemList));
    }

    problemListRef.current?.enableAddBtn();
    dispatch(setPatientLoading(false));
  };

  const handleProblemType = async (item: UpdatedProblemTypeParams) => {
    dispatch(setPatientLoading(true));

    if (item.id === undefined && patientInfo) {
      await createProblemItem({
        id_admission_record: patientInfo.id_admission_record,
        past_medical_history_check:
          item.field === PROBLEM_LIST_UPDATE_REQUEST_KEY.pastMedicalHistoryCheck
            ? item.value
            : false,
        suspicion_check:
          item.field === PROBLEM_LIST_UPDATE_REQUEST_KEY.suspicionCheck
            ? item.value
            : false,
        remark_entry: "",
      });
    } else if (item.id) {
      await updateProblemItem(item.id, {
        [item.field]: item.value,
      });
    }

    if (patientInfo) {
      const { data: newProblemList } = await getProblemList(
        patientInfo.id_admission_record
      );

      setProblemList(formatProblemList(newProblemList));
    }

    dispatch(setPatientLoading(false));
  };

  return (
    <>
      <IcuHeader />
      <Wrapper customClassName="patient-detail-wrapper">
        {patientInfo ? (
          <div className={styles.patientDetailPage}>
            <PersonalCard data={patientInfo} />

            <Flex
              justify="space-between"
              gap={9}
              className={styles.mainContent}
            >
              <NoteSection
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                data={patientInfo}
                progressNoteFilterDate={progressNoteFilterDate}
                setProgressNoteFilterDate={setProgressNoteFilterDate}
                currentProgressNote={currentProgressNote}
                setCurrentProgressNote={setCurrentProgressNote}
              />
              <ProblemList
                data={problemList}
                onAddProblem={handleAddProblem}
                onDeleteProblem={handleDeleteProblem}
                onBlurProblemInput={handleBlurProblemInput}
                onUpdateProblemType={handleProblemType}
                ref={problemListRef}
              />
            </Flex>
          </div>
        ) : (
          <></>
        )}
      </Wrapper>
      <IcuFooter />
      {loadingGetPatientDetail && <LoadingOverlay />}
    </>
  );
};

export default PatientDetailPage;
