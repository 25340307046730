import {
  API_CREATE_PROGRESS_NOTE,
  API_GET_PROGRESS_NOTE,
  API_GET_PROGRESS_NOTE_DETAIL,
  API_UPDATE_PROGRESS_NOTE,
} from "../../configs/api/endpoints";
import networkAdapter from "../../configs/api/networkAdapter";
import { DATE_FORMAT_SEND_TO_SERVER } from "../../constants/common/dateFormat.constant";
import { BaseResponse } from "../../types/common/BaseResponse.model";
import { ProgressNoteCreateRequest } from "../../types/patient-detail/ProgressNoteCreateRequest.model";
import { ProgressNotePatchRequest } from "../../types/patient-detail/ProgressNotePatchRequest.model";
import { ProgressNoteReadResponse } from "../../types/patient-detail/ProgressNoteReadResponse.model";
import { formatDateBeforeSendToServer } from "../../utils/helpers";

export const getProgressNotes = (
  admissionRecordId: number,
  date?: string
): Promise<BaseResponse<ProgressNoteReadResponse[]>> => {
  return networkAdapter.get(`${API_GET_PROGRESS_NOTE}/${admissionRecordId}`, {
    date,
  });
};

export const getProgressNoteDetail = (
  progressNoteId: number
): Promise<BaseResponse<ProgressNoteReadResponse>> => {
  return networkAdapter.get(`${API_GET_PROGRESS_NOTE_DETAIL}/${progressNoteId}`);
};

export const createProgressNote = (params: ProgressNoteCreateRequest) => {
  if(!params.created_at) {
    params = {
      ...params,
      created_at: formatDateBeforeSendToServer(new Date, DATE_FORMAT_SEND_TO_SERVER.YYYYMMDDHHmmss)
    }
  }
  return networkAdapter.post(API_CREATE_PROGRESS_NOTE, params);
};

export const updateProgressNote = (data: ProgressNotePatchRequest) => {
  return networkAdapter.patch(API_UPDATE_PROGRESS_NOTE, data);
};
