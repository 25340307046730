import { Flex } from "antd";
import styles from "./PersonalExaminationCard.module.scss";
import { useTranslation } from "react-i18next";
import whiteTextArea from "../../../assets/icons/white-text-area.svg";
import "./PersonalExaminationCard.scss";
import { PatientDetailEntity } from "../../../types/common/PatientDetail.model";
import { EXAMINATION_RESULT_PATH } from "../../../configs/routerPath";
import IcuButton from "../button/IcuButton";
import PatientCardInfo from "../patient-card-info/PatientCardInfo";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { addOpenedTabByAdmissionRecordId } from "../../../redux/slices/tabSlice";
import { ReactNode } from "react";

interface PersonalExaminationCardProps {
  data: PatientDetailEntity;
  customClassName?: string;
  customOrderNumber?: string;
  customOrderNumberClassName?: string;
  dropdownButton?: ReactNode;
  exportPdfButton?: ReactNode;
}

const PersonalExaminationCard = ({
  data,
  customClassName,
  customOrderNumber,
  customOrderNumberClassName,
  dropdownButton,
  exportPdfButton,
}: PersonalExaminationCardProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch<AppDispatch>();

  const handleClickExaminationResultBtn = () => {
    const timeSeriesTab = window.open(
      `${window.location.origin}${EXAMINATION_RESULT_PATH}`.replaceAll(
        ":id",
        data.id_admission_record.toString()
      ),
      "_self"
    );

    dispatch(
      addOpenedTabByAdmissionRecordId({
        id: data.id_admission_record,
        tab: timeSeriesTab,
      })
    );
  };

  return (
    <Flex className={`${styles.patientItem} ${customClassName ?? ""} `}>
      <div
        className={`${styles.orderNumber} ${customOrderNumberClassName ?? ""}`}
      >
        {customOrderNumber ?? data.id_patient}
      </div>
      <div className="exam-card-personal-info-container">
        <PatientCardInfo
          data={data}
          gap={104}
          containerClassName="time-series-exam-personal-info"
        />
      </div>
      <Flex justify="flex-end" className={`${styles.buttonSection} group-btn`} gap={10}>
        <IcuButton
          width={165}
          height={34}
          icon={
            <img
              src={whiteTextArea}
              alt="exam result icon"
              className={styles.timeSeriesExamIcon}
            />
          }
          onClick={handleClickExaminationResultBtn}
          extraClassName="time-series-exam-result-btn"
        >
          {t("Examination result")}
        </IcuButton>

        {dropdownButton || <></>}
        {exportPdfButton || <></>}
      </Flex>
    </Flex>
  );
};

export default PersonalExaminationCard;
