import { Button, ConfigProvider, Input, Table } from "antd";
import { useTranslation } from "react-i18next";
import "./OutTable.scss";
import { OUT_TABLE_CLASSNAME } from "../../../constants/vital-sign/VitalSignTable.constant";
import { useEffect, useRef, useState } from "react";
import { isEqual } from "lodash";
import { IN_OUT_TABLE_HEADER } from "../../../constants/vital-sign/InOutTable.constant";
import { OutEditableCell } from "./editable-cell/OutEditableCell";
import {
  createOutBalanceTimes,
  deleteOutBalance,
  updateOutBalance,
  updateOutBalanceTimes,
} from "../../../services/in-out-balance/inOutBalanceService";
import {
  formatDateBeforeSendToServer,
  generateRandomNumber,
} from "../../../utils/helpers";
import dayjs from "dayjs";
import { DATE_FORMAT_SEND_TO_SERVER } from "../../../constants/common/dateFormat.constant";
import MedicineAdd from "./MedicineAdd";
import trashImg from "../../../assets/img/trash.png";
export interface OutTableType {
  [key: string]: string | OutMedicine | number | OutAmountPerHr;
}

type EditableTableProps = Parameters<typeof Table>[0];
type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

export interface OutMedicine {
  id?: number;
  name?: string;
  isTotal: boolean;
}
export interface OutAmountPerHr {
  id?: number;
  numerator: number;
  denominator: number;
}

interface OutTableProps {
  dataSources: OutTableType[];
  targetDate: dayjs.Dayjs;
  handleGetOutBalanceData: () => void;
  handleOpenCreateOutBalanceModal: () => void;
}

const OutTable = ({
  dataSources,
  targetDate,
  handleGetOutBalanceData,
  handleOpenCreateOutBalanceModal,
}: OutTableProps) => {
  const { t } = useTranslation();
  const nameRefs = useRef<(HTMLSpanElement | null)[]>([]);

  const handleUpdateOutBalance = async (outBalanceId: number, name: string) => {
    await updateOutBalance(outBalanceId, name);
    handleGetOutBalanceData();
  };

  const handleDeleteOutBalance = async (outBalanceId: number) => {
    await deleteOutBalance(outBalanceId);
    handleGetOutBalanceData();
  };

  const generateMedicineElement = (medicine: OutMedicine) => {
    const medicineId = medicine?.id ?? 0;
    return (
      <>
        {!medicine?.isTotal ? (
          <div className="medicine-component">
            <div className="medicine-info">
              <Input
                className="medicine-name"
                defaultValue={medicine.name}
                style={{
                  width: `${nameRefs.current[medicineId]?.offsetWidth}px`,
                  maxWidth: "320px",
                }}
                onBlur={(e) => {
                  handleUpdateOutBalance(medicineId, e.target.value);
                }}
              />
              <span
                className="medicine-name medicine-span-name"
                ref={(el) => (nameRefs.current[medicineId] = el)}
              >
                {medicine.name}
              </span>
              <Button
                className="medicine-delete"
                type="primary"
                danger
                onClick={() => handleDeleteOutBalance(medicineId)}
              >
                <img src={trashImg} alt="" />
              </Button>
            </div>
          </div>
        ) : (
          <div className="in-total-style">{medicine.name}</div>
        )}
      </>
    );
  };

  // create or update out balance time data
  const handleUpdateData = async (
    id_out_balance: number,
    typeId: number,
    value: number | null,
    newRowData: OutTableType,
    createdAt: string
  ) => {
    const outBalanceTimeId = (newRowData[createdAt] as OutAmountPerHr).id ?? 0;
    if (!outBalanceTimeId) {
      const time = formatDateBeforeSendToServer(
        `${targetDate.format(
          DATE_FORMAT_SEND_TO_SERVER.YYYYMMDD
        )} ${createdAt}:00`,
        DATE_FORMAT_SEND_TO_SERVER.YYYYMMDDHHmmss
      );
      await createOutBalanceTimes({
        id_out_balance,
        time,
        value: Number(value),
      });
    } else {
      await updateOutBalanceTimes(
        outBalanceTimeId,
        (newRowData[createdAt] as OutAmountPerHr).numerator
      );
    }
    handleGetOutBalanceData();
  };

  const dynamicColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
    id?: string;
    value?: string | null;
  })[] = IN_OUT_TABLE_HEADER.map((col) => {
    return {
      title: (
        <div className="title">
          <div>{col}</div>
        </div>
      ),
      dataIndex: col,
      key: col,
      align: "center",
      className: "out-table-col",
      width: `80px`,
      editable: true,
      render(outValue, row) {
        const numerator = outValue?.numerator;
        const denominator = outValue?.denominator;
        let cellString = "";
        if (
          (numerator || row.medicine.isTotal) &&
          numerator !== undefined &&
          denominator !== undefined
        ) {
          cellString = numerator + "/" + denominator;
        }

        return (
          <div className="in-table_cell">
            <>{cellString}</>
          </div>
        );
      },
    };
  });

  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
    id?: string;
    value?: string | null;
  })[] = [
    {
      title: "",
      dataIndex: "medicine",
      key: "medicine",
      align: "left",
      className: "",
      width: "400px",
      fixed: "left",
      render(record) {
        return generateMedicineElement(record);
      },
    },
    ...dynamicColumns,
  ];

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => {
        return {
          record,
          editable:
            record?.medicine?.isTotal || !record?.medicine?.id
              ? false
              : col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          id: record.medicine.id ?? undefined,
          typeId: record.typeId,
          handleUpdateData,
        };
      },
    };
  });

  const [outTableHeight, setOutTableHeight] = useState<number>(() => {
    const initVentilatorTableElement =
      document.getElementsByClassName(OUT_TABLE_CLASSNAME);

    if (initVentilatorTableElement.length > 0) {
      return (initVentilatorTableElement[0] as HTMLDivElement).offsetHeight;
    }

    return 0;
  });

  useEffect(() => {
    if (
      !isEqual(
        document.getElementsByClassName(OUT_TABLE_CLASSNAME),
        outTableHeight
      ) &&
      document.getElementsByClassName(OUT_TABLE_CLASSNAME).length > 0
    ) {
      setOutTableHeight(
        (
          document.getElementsByClassName(
            OUT_TABLE_CLASSNAME
          )[0] as HTMLDivElement
        ).offsetHeight
      );
    }
  });
  const components = {
    body: {
      cell: OutEditableCell,
    },
  };

  return (
    <>
      <div
        className={"outTableSection"}
        style={{
          height: outTableHeight + 54,
        }}
      >
        <div
          className={"outTableHeader"}
          style={{
            width: outTableHeight,
            top: outTableHeight + 54,
          }}
        >
          {t("OUT")}
        </div>
        <ConfigProvider
          theme={{
            components: {
              Table: {
                headerBorderRadius: 0,
              },
            },
          }}
        >
          <div className="medicineAdd">
            <MedicineAdd handleOpenAdd={handleOpenCreateOutBalanceModal} />
          </div>
          {dataSources && (
            <Table
              dataSource={dataSources}
              pagination={false}
              bordered
              className={OUT_TABLE_CLASSNAME}
              columns={columns as ColumnTypes}
              components={components}
              rowHoverable={false}
              tableLayout="fixed"
              rowKey={(record) => `${record.typeId}-${generateRandomNumber()}`}
              scroll={{
                x: 80,
              }}
            />
          )}
        </ConfigProvider>
      </div>
    </>
  );
};
export default OutTable;
