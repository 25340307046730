import { ConfigProvider, Table } from "antd";
import { BALANCE_TABLE_CLASSNAME } from "../../../constants/vital-sign/VitalSignTable.constant";
import { useEffect, useState } from "react";
import { isEqual } from "lodash";
import { IN_OUT_TABLE_HEADER } from "../../../constants/vital-sign/InOutTable.constant";
import { ColumnType } from "antd/es/table";
export interface BalanceType {
  key: number;
  balance_title: string;
  [key: string]: BalancePerHour | string | number;
}

interface BalanceTableProps {
  dataSource: BalanceType[];
}

interface BalancePerHour {
  numerator: number | undefined;
  denominator: number | undefined;
}

const BalanceTable = ({ dataSource }: BalanceTableProps) => {
  const dynamicColumns: ColumnType<BalanceType>[] = IN_OUT_TABLE_HEADER.map(
    (col) => {
      return {
        title: (
          <div className="title">
            <div>{col}</div>
          </div>
        ),
        dataIndex: col,
        key: col,
        align: "center",
        className: "ventilator-table-first-col",
        width: `80px`,
        render(record) {
          return (
            <div className="in-table_cell">
              {!isNaN(record?.numerator) && !isNaN(record?.denominator) ? (
                <>
                  {record?.numerator ?? 0}/{record?.denominator ?? 0}
                </>
              ) : (
                <>{""}</>
              )}
            </div>
          );
        },
      };
    }
  );

  const columns: ColumnType<BalanceType>[] = [
    {
      title: "",
      dataIndex: "balance_title",
      key: "medicine",
      align: "left",
      className: "",
      width: "439px",
      fixed: "left",
    },
    ...dynamicColumns,
  ];

  const [balanceTableHeight, setBalanceTableHeight] = useState<number>(() => {
    const initVentilatorTableElement = document.getElementsByClassName(
      BALANCE_TABLE_CLASSNAME
    );

    if (initVentilatorTableElement.length > 0) {
      return (initVentilatorTableElement[0] as HTMLDivElement).offsetHeight;
    }

    return 0;
  });

  useEffect(() => {
    if (
      !isEqual(
        document.getElementsByClassName(BALANCE_TABLE_CLASSNAME),
        setBalanceTableHeight
      ) &&
      document.getElementsByClassName(BALANCE_TABLE_CLASSNAME).length > 0
    ) {
      setBalanceTableHeight(
        (
          document.getElementsByClassName(
            BALANCE_TABLE_CLASSNAME
          )[0] as HTMLDivElement
        ).offsetHeight
      );
    }
  });

  return (
    <>
      <div
        className={"balanceTableSection"}
        style={{
          height: balanceTableHeight,
        }}
      >
        <ConfigProvider
          theme={{
            components: {
              Table: {
                headerBorderRadius: 0,
              },
            },
          }}
        >
          <Table
            dataSource={dataSource}
            pagination={false}
            bordered
            className={BALANCE_TABLE_CLASSNAME}
            columns={columns}
            rowHoverable={false}
            tableLayout="fixed"
            scroll={{
              x: 80,
            }}
          />
        </ConfigProvider>
      </div>
    </>
  );
};
export default BalanceTable;
