import { Button, ConfigProvider, Input, Select, Table, Tree } from "antd";
import { useTranslation } from "react-i18next";
import "./InTable.scss";
import { IN_TABLE_CLASSNAME } from "../../../constants/vital-sign/VitalSignTable.constant";
import React, { SetStateAction, useEffect, useState } from "react";
import { isEqual } from "lodash";
import {
  BALANCE_IN_DELETE_REACTIVE,
  BALANCE_IN_DELETE_TEMP,
  BALANCE_IN_STATUS,
  IN_OUT_TABLE_HEADER,
  MEDICINE_CATEGORIES,
} from "../../../constants/vital-sign/InOutTable.constant";
import pencilBookImg from "../../../assets/img/pencil-book.png";
import copyImg from "../../../assets/img/copy.png";
import undoImg from "../../../assets/img/undo.png";
import MedicineAdd from "./MedicineAdd";
import EditMedicineModal from "./medicine/EditMedicineModal";
import { MEDICINE_UNIT_OPTIONS } from "../../../constants/vital-sign/Medicine.constant";
import {
  updateInBalanceCategory,
  updateInBalanceMedicine,
  updateInBalanceStatus,
  updateInBalanceTime,
} from "../../../services/in-out-balance/inOutBalanceService";
import {
  EditInBalanceRequest,
  UpdateMedicineEntityRequest,
} from "../../../types/vital-sign/MedicineRequest.model";
import InBalanceDelete from "./InBalanceDelete";
import TemporaryDeleteModal from "./medicine/TemporaryDeleteModal";
import { InEditableCell } from "./editable-cell/InEditableCell";
import CompletelyDeleteModal from "./medicine/CompletelyDeleteModal";
import dayjs from "dayjs";
import { MedicineInDataSource } from "../../../types/vital-sign/MedicineInEntity.model";
export interface InTableType {
  [key: string]: string | MedicineColumn | AmountPerHr | number | undefined;
}

export interface MedicineColumn {
  in_balance_id?: number;
  category?: string;
  name?: string;
  medicines?: Medicine[];
  amount?: number;
  speed?: number;
  frequency?: number;
  unit?: string;
  isTotal?: boolean;
  start_date?: string;
  end_date?: string;
  start_time?: string;
  end_time?: string;
}

export interface AmountPerHr {
  id?: number;
  numerator: number;
  denominator: number;
  stoped?: boolean;
}

export interface Medicine {
  id: number;
  name?: string;
  dose_unit?: string;
  dose_amount?: number;
  code?: string;
  isEditing?: boolean;
  medicine_id: number;
}
interface InTableProps {
  handleOpenMedicineInModal: () => void;
  inTableDataSource: InTableType[];
  setIsLoading: React.Dispatch<SetStateAction<boolean>>;
  handleGetInBalanceData: () => void;
  setSelectedMedicineInKeys: React.Dispatch<SetStateAction<React.Key[]>>;
  isShowEditModal: boolean;
  setShowEditModal: React.Dispatch<SetStateAction<boolean>>;
  setIsCopy: React.Dispatch<SetStateAction<boolean>>;
  isCopy: boolean;
  handleCreateCopyInBalance: (medicineInRequest: EditInBalanceRequest) => void;
  currentInBalance: MedicineColumn | undefined;
  setCurrentInBalance: React.Dispatch<
    SetStateAction<MedicineColumn | undefined>
  >;
  targetDate: dayjs.Dayjs;
  selectedRows: MedicineInDataSource[];
  setSelectedRows: React.Dispatch<SetStateAction<MedicineInDataSource[]>>;
}

type EditableTableProps = Parameters<typeof Table>[0];
type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

const InTable = ({
  handleOpenMedicineInModal,
  inTableDataSource,
  setIsLoading,
  handleGetInBalanceData,
  setSelectedMedicineInKeys,
  isShowEditModal,
  setShowEditModal,
  setIsCopy,
  isCopy,
  handleCreateCopyInBalance,
  currentInBalance,
  setCurrentInBalance,
  targetDate,
  setSelectedRows,
}: InTableProps) => {
  const { t } = useTranslation();

  const [deleteInBalanceId, setDeleteInBalanceId] = useState<number>(0);
  const [isShowTemporaryDeleteModal, setShowTemporaryDeleteModal] =
    useState(false);
  const [isShowCompletelyDeleteModal, setShowCompletelyDeleteModal] =
    useState(false);

  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };

  const handleOpenEditModal = (record: MedicineColumn) => {
    setIsCopy(false);
    setCurrentInBalance(record);
    setShowEditModal(true);
  };

  const handleShowDeleteOptionModal = (
    id_in_balance: number,
    option: string
  ) => {
    setDeleteInBalanceId(id_in_balance);
    if (option === BALANCE_IN_DELETE_TEMP) {
      setShowTemporaryDeleteModal(true);
    } else {
      setShowCompletelyDeleteModal(true);
    }
  };

  // delete in balance
  const handleDeleteInBalance = async (
    option: string,
    suspendStartTime?: string,
    suspendEndTime?: string,
    inBalanceId?: number
  ) => {
    await updateInBalanceStatus({
      id_in_balance: inBalanceId ?? deleteInBalanceId,
      status: option,
      suspend_start_time: suspendStartTime,
      suspend_end_time: suspendEndTime,
    });
    handleGetInBalanceData();
  };

  // update medicine info of in balance
  const handleUpdateInBalanceMedicine = async (
    id_in_balance: number,
    medicine: Medicine
  ) => {
    const updateInBalanceMedicineRequest: UpdateMedicineEntityRequest = {
      id_in_balance: id_in_balance,
      id_medicine: medicine.medicine_id,
      dose_amount: Number(medicine.dose_amount),
      dose_unit: medicine.dose_unit ?? "",
    };
    await updateInBalanceMedicine(updateInBalanceMedicineRequest);
    handleGetInBalanceData();
  };

  // generate one medicine element in_balance (name, amount, unit)
  const generateMedicineElement = (
    id_in_balance: number,
    medicine: Medicine
  ) => {
    return (
      <div className="medicine-component">
        <div className="medicine-info">
          <span className="medicine-name">{medicine.name}</span>
          <Input
            type="number"
            className="medicine-ml"
            defaultValue={medicine.dose_amount}
            min={0}
            onBlur={(e) => {
              handleUpdateInBalanceMedicine(id_in_balance, {
                ...medicine,
                dose_amount: Number(e.target.valueAsNumber),
              });
            }}
            key={`${medicine.dose_amount}-${medicine.id}-${medicine.dose_unit}-amount`}
          />
          <Select
            className="unit-options"
            defaultValue={medicine.dose_unit}
            onChange={(value) => {
              handleUpdateInBalanceMedicine(id_in_balance, {
                ...medicine,
                dose_unit: value,
              });
            }}
            options={MEDICINE_UNIT_OPTIONS.map((option) => ({
              value: option.value,
              label: option.text,
            }))}
            key={`${medicine.dose_amount}-${medicine.id}-${medicine.dose_unit}-unit`}
          />
        </div>
      </div>
    );
  };

  // generate total medicine info element of in balance (amount, speed, frequency)
  const generateMedicineAmountElement = (
    amount: number,
    speed: number,
    frequency: number,
    unit: string
  ) => {
    return (
      <div className="medicine-component">
        <div className="medicine-info">
          <span className="medicine-ml">
            {amount}
            {unit}
          </span>
          <span className="medicine-ml">
            {speed}
            {unit}/hr
          </span>
          <span className="medicine-ml">
            {frequency > 1 ? `${frequency} times/day` : `${frequency} time/day`}
          </span>
        </div>
      </div>
    );
  };

  // handle reactive in balance when it be stoped temporaryly
  const handleUndoInActiveBalance = (record: MedicineColumn) => {
    handleDeleteInBalance(
      BALANCE_IN_DELETE_REACTIVE,
      "",
      "",
      record?.in_balance_id
    );
  };

  // function open Select drug modal to add new in balance
  const handleAddNewInBalance = () => {
    setIsCopy(false);
    setSelectedMedicineInKeys([]);
    setSelectedRows([]);
    setCurrentInBalance(undefined);
    handleOpenMedicineInModal();
  };

  // call api to update category of medicine in balance
  const handleUpdateCategory = async (
    inBalanceId: number,
    category: string
  ) => {
    await updateInBalanceCategory(inBalanceId, category);
  };

  // generate medicine tree for in balance form many medicine element and one total medicine info element
  const generateMedicineTree = (record: MedicineColumn, row: InTableType) => {
    const medicines = record.medicines ?? [];
    if (!medicines.length) {
      return <></>;
    }
    const treeData = [
      {
        title: (
          <Select
            style={{ width: 120 }}
            defaultValue={record.category ?? MEDICINE_CATEGORIES[0]}
            options={MEDICINE_CATEGORIES.map((item) => ({
              value: item,
              label: item,
            }))}
            className="medicine-tree-title"
            onChange={(value) => {
              handleUpdateCategory(record?.in_balance_id ?? 0, value);
            }}
          />
        ),
        key: `0-0`,
        children: [
          ...medicines.map((medicine) => ({
            title: generateMedicineElement(
              Number(record.in_balance_id),
              medicine
            ),
            key: `0-0-${record.in_balance_id}__${medicine.id}`,
          })),
          {
            title: generateMedicineAmountElement(
              Number(record.amount),
              Number(record.speed),
              Number(record.frequency),
              record.unit ?? ""
            ),
            key: `0-0-${record.in_balance_id}`,
          },
        ],
      },
    ];
    return (
      <>
        <div className="medicine-cell">
          <Tree
            treeData={treeData}
            showLine
            switcherIcon={null}
            defaultExpandAll
            className="medicine-tree"
          />

          <div className="medicine-action">
            {row.status === BALANCE_IN_STATUS.active ? (
              <InBalanceDelete
                inBalanceId={record?.in_balance_id ?? 0}
                handleShowDeleteOptionModal={handleShowDeleteOptionModal}
              />
            ) : (
              record?.in_balance_id && (
                <Button
                  className="medicine-undo"
                  type="primary"
                  onClick={() => handleUndoInActiveBalance(record)}
                >
                  <img src={undoImg} alt="" />
                </Button>
              )
            )}

            <Button
              className="medicine-copy"
              type="primary"
              onClick={() => handleCopyInBalance(row)}
            >
              <img src={copyImg} alt="" />
            </Button>
            <Button
              className="medicine-edit"
              type="primary"
              onClick={() => {
                handleOpenEditModal(record);
              }}
            >
              <img src={pencilBookImg} alt="" />
            </Button>
          </div>
        </div>
      </>
    );
  };

  // call api to update in balance time data
  const handleUpdateData = async (
    id_out_balance: number,
    typeId: number,
    value: number | null,
    newRowData: InTableType,
    createdAt: string
  ) => {
    const inBalanceTimeId = (newRowData[createdAt] as AmountPerHr).id ?? 0;
    await updateInBalanceTime(inBalanceTimeId, Number(value));
    handleGetInBalanceData();
  };

  // generate in balance time data columns for in table
  const dynamicColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
    id?: string;
    value?: string | null;
  })[] = IN_OUT_TABLE_HEADER.map((col) => {
    return {
      title: (
        <div className="title">
          <div>{col}</div>
        </div>
      ),
      dataIndex: col,
      key: col,
      align: "center",
      className: "in-table-col",
      width: `80px`,
      editable: !targetDate.isBefore(dayjs(), "day"),
      render(record, row) {
        const numerator = record?.numerator;
        const denominator = record?.denominator;
        let cellString = "";
        if (
          (numerator || row.medicines.isTotal) &&
          numerator !== undefined &&
          denominator !== undefined
        ) {
          cellString = numerator + "/" + denominator;
        }
        return (
          <div>
            {cellString ? (
              <div className={`${!row.status ? "inactive" : ""}`}>
                <div className="in-table_cell">
                  <div>{cellString}</div>
                </div>

                <div className="in-table-status">
                  <span className="active"></span>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        );
      },
    };
  });

  // generate first column in table
  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
    id?: string;
    value?: string | null;
  })[] = [
    {
      title: "",
      dataIndex: "medicines",
      key: "medicines",
      align: "left",
      className: "",
      width: "400px",
      fixed: "left",
      render(record, row) {
        if (record?.isTotal) {
          return (
            <>
              <div className="in-total-style">{record.name}</div>
            </>
          );
        } else {
          return generateMedicineTree(record, row);
        }
      },
    },
    ...dynamicColumns,
  ];

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => {
        return {
          record,
          editable:
            record?.medicines?.isTotal ||
            record.status === BALANCE_IN_STATUS.inActive
              ? false
              : col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          id: record?.medicines?.id ?? undefined,
          typeId: record.typeId,
          handleUpdateData,
        };
      },
    };
  });

  const [inTableHeight, setInTableHeight] = useState<number>(() => {
    const initVentilatorTableElement =
      document.getElementsByClassName(IN_TABLE_CLASSNAME);

    if (initVentilatorTableElement.length > 0) {
      return (initVentilatorTableElement[0] as HTMLDivElement).offsetHeight;
    }

    return 0;
  });

  useEffect(() => {
    if (
      !isEqual(
        document.getElementsByClassName(IN_TABLE_CLASSNAME),
        inTableHeight
      ) &&
      document.getElementsByClassName(IN_TABLE_CLASSNAME).length > 0
    ) {
      setInTableHeight(
        (
          document.getElementsByClassName(
            IN_TABLE_CLASSNAME
          )[0] as HTMLDivElement
        ).offsetHeight
      );
    }
  });

  const components = {
    body: {
      cell: InEditableCell,
    },
  };

  const handleCopyInBalance = (record: InTableType) => {
    const currentInBalance = record?.medicines as MedicineColumn;
    const selectedKeys = (currentInBalance?.medicines ?? []).map(
      (medicine: Medicine) => medicine.medicine_id
    );
    const selectedRows: MedicineInDataSource[] = (
      currentInBalance?.medicines ?? []
    ).map((medicine: Medicine) => ({
      key: medicine.id,
      code: "",
      name: medicine.name ?? "",
    }));
    setCurrentInBalance(currentInBalance);
    setSelectedMedicineInKeys(selectedKeys);
    setSelectedRows(selectedRows);
    setIsCopy(true);
    handleOpenMedicineInModal();
  };

  return (
    <>
      <div
        className={"inTableSection"}
        style={{
          height: inTableHeight,
        }}
      >
        <div
          className={"intableHeader"}
          style={{
            width: inTableHeight - 65,
            top: inTableHeight,
          }}
        >
          {t("IN")}
        </div>
        <div className="medicineAdd">
          <MedicineAdd handleOpenAdd={handleAddNewInBalance} />
        </div>
        <ConfigProvider
          theme={{
            components: {
              Table: {
                headerBorderRadius: 0,
              },
            },
          }}
        >
          <Table
            dataSource={inTableDataSource}
            pagination={false}
            bordered
            className={IN_TABLE_CLASSNAME}
            columns={columns as ColumnTypes}
            rowHoverable={false}
            components={components}
            tableLayout="fixed"
            scroll={{
              x: 80,
            }}
          />
        </ConfigProvider>
      </div>
      {isShowEditModal && (
        <EditMedicineModal
          isShow={isShowEditModal}
          handleClose={handleCloseEditModal}
          currentInBalance={currentInBalance}
          setIsLoading={setIsLoading}
          handleGetInBalanceData={handleGetInBalanceData}
          isCopy={isCopy}
          handleCreateCopyInBalance={handleCreateCopyInBalance}
        />
      )}

      {isShowTemporaryDeleteModal && (
        <TemporaryDeleteModal
          isShow={isShowTemporaryDeleteModal}
          handleClose={() => setShowTemporaryDeleteModal(false)}
          inBalanceId={deleteInBalanceId}
          handleDeleteInBalance={handleDeleteInBalance}
        />
      )}

      {isShowCompletelyDeleteModal && (
        <CompletelyDeleteModal
          isShow={isShowCompletelyDeleteModal}
          handleClose={() => setShowCompletelyDeleteModal(false)}
          inBalanceId={deleteInBalanceId}
          handleDeleteInBalance={handleDeleteInBalance}
        />
      )}
    </>
  );
};
export default InTable;
