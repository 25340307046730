import { DatePicker, Form, Modal } from "antd";
import "./UploadImageModal.scss";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import dayjs from "dayjs";
import { DATE_FORMAT } from "../../../constants/common/dateFormat.constant";

interface UploadImageDateModalProps {
  isShow: boolean;
  handleClose: () => void;
  handleOk: (date: string) => void;
  showTime?: boolean;
  format?: string;
}
const UploadImageDateModal = ({
  isShow,
  handleClose,
  handleOk,
  showTime,
  format,
}: UploadImageDateModalProps) => {
  const dateFormat = format ?? DATE_FORMAT.YYYY_MM_DD;

  const { t } = useTranslation();
  const [date, setDate] = useState<string>(dayjs().format(dateFormat));

  return (
    <>
      <Modal
        title={t("Select date")}
        open={isShow}
        onCancel={handleClose}
        className="image-form-modal"
        okText="Save"
        onOk={() => handleOk(String(date))}
        centered
        okButtonProps={{ disabled: !date }}
      >
        <Form layout="vertical">
          <Form.Item name="date">
            <DatePicker
              value={dayjs(date)}
              pickerValue={dayjs(date)}
              format={dateFormat}
              onChange={(value) => {
                setDate(value.format(dateFormat));
              }}
              defaultValue={dayjs(date, dateFormat)}
              placeholder=""
              allowClear={false}
              showTime={showTime}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default UploadImageDateModal;
