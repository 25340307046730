import { useEffect, useRef, useState } from "react";
import IcuHeader from "../../components/common/header/IcuHeader";
import Wrapper from "../../components/common/wrapper/Wrapper";
import IcuFooter from "../../components/common/footer/IcuFooter";
import styles from "./VitalSignPage.module.scss";
import PersonalExaminationCard from "../../components/common/personal-examination-card/PersonalExaminationCard";
import { PatientDetailEntity } from "../../types/common/PatientDetail.model";
import VitalSignGraph from "../../components/vital-sign/graph-section/VitalSignGraph";
import VitalSignTable from "../../components/vital-sign/vital-sign-table/VitalSignTable";
import {
  formatVentilatorSettingReadResponse,
  formatVitalSignReadResponse,
  transformPdfSubHeaderColumn,
  transformResponseToVentilatorTableData,
  transformResponseToVitalSignRangeTable,
  transformResponseToVitalSignTableData,
  transformVitalSignToGraphData,
  transformVitalSignToPdfGraphData,
} from "../../utils/vital-sign/VitalSign.helper";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import {
  DATE_FORMAT,
  DATE_FORMAT_SEND_TO_SERVER,
} from "../../constants/common/dateFormat.constant";
import VentilatorTable from "../../components/vital-sign/ventilator-table/VentilatorTable";
import { LOCAL_STORAGE_KEY } from "../../constants/common/localStorageKey.constant";
import {
  createVitalSignOCR,
  getAllVitalSigns,
  putVitalSign,
  updateVitalSign,
} from "../../services/vital-sign/vitalSignService";
import {
  createVentilatorSettingOCR,
  getAllVentilatorSettings,
  putVentilatorSetting,
  updateVentilatorSetting,
} from "../../services/ventilator-setting/ventilatorSettingService";
import CustomDatePicker from "../../components/common/custom-date-picker/CustomDatePicker";
import { Flex } from "antd";
import {
  addQueryParamToCurrentURL,
  changeFavicon,
  convertUTCtoLocalDateWithNoFormat,
  formatDateBeforeSendToServer,
  generateRandomNumber,
} from "../../utils/helpers";
import { LoadingOverlay } from "../../components/common/loading/LoadingOverlay";
import { VitalSignTableEntity } from "../../types/vital-sign/VitalSignTableEntity.model";
import "./VitalSignPage.scss";
import { ChartDataEntity } from "../../types/vital-sign/ChartDataEntity.model";
import { VitalSignRangeEntity } from "../../types/vital-sign/VitalSignRangeEntity.model";
import { isEqual } from "lodash";
import {
  BALANCE_TABLE_CLASSNAME,
  IN_TABLE_CLASSNAME,
  OUT_TABLE_CLASSNAME,
  VENTILATOR_TABLE_CLASSNAME,
  VITAL_SIGN_TABLE_CLASSNAME,
} from "../../constants/vital-sign/VitalSignTable.constant";
import { useLocation, useParams } from "react-router-dom";
import { getPatientDetail } from "../../services/patient/patientService";
import { formatPatientDetail } from "../../utils/patient-detail/PatientDetail.helper";
import { DOCUMENT_HEADER } from "../../constants/common/documentHeader.constant";
import UploadImageButton from "../../components/vital-sign/upload-image-button/UploadImageButton";
import { RcFile } from "antd/es/upload";
import { useUploadImage } from "../../hooks/useUploadImage";
import { UPLOAD_IMAGE_STATUS } from "../../constants/common/uploadImageStatus.constant";
import InOutBalanceSection from "../../components/vital-sign/in-out-balance/InOutBalanceSection";
import OcrImageModal from "../../components/vital-sign/image-section/OcrImageModal";
import { getS3Url } from "../../services/ocrImageService";
import { OCR_IMAGE_MODES } from "../../constants/vital-sign/OcrImageMode.constant";
import { VentilatorTableEntity } from "../../types/vital-sign/VentilatorTableEntity.model";
import UploadImageDateModal from "../../components/common/image-upload/UploadImageDateModal";
import { VITAL_SIGN_PARAMS } from "../../constants/common/queryParams.constant";
import { IN_OUT_TABLE_HEADER } from "../../constants/vital-sign/InOutTable.constant";
import IcuButton from "../../components/common/button/IcuButton";
import filePdf from "../../assets/icons/file-pdf.svg";
import { useReactToPrint } from "react-to-print";
import PdfVitalSignGraph from "../../components/vital-sign/graph-section/PdfVitalSignGraph";
import PdfVitalSignTable from "../../components/vital-sign/vital-sign-table/PdfVitalSignTable";
import PdfVentilatorTable from "../../components/vital-sign/ventilator-table/PdfVentilatorTable";

const VitalSignPage = () => {
  const { t } = useTranslation();

  const orderNumber =
    localStorage.getItem(LOCAL_STORAGE_KEY.patientOrderNumber) ?? "";

  const [vitalSignTableValues, setVitalSignTableValues] =
    useState<VitalSignTableEntity>();

  const [chartData, setChartData] = useState<ChartDataEntity>({
    labels: [],
    datasets: [],
  });

  const [pdfChartData, setPdfChartData] = useState<ChartDataEntity[]>([
    {
      labels: [],
      datasets: [],
    },
  ]);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const targetDateQuery = queryParams.get(VITAL_SIGN_PARAMS.targetDate);

  const [targetDate, setTargetDate] = useState(
    targetDateQuery
      ? dayjs(targetDateQuery)
      : dayjs(
          new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate()
          )
        )
  );

  useEffect(() => {
    addQueryParamToCurrentURL({
      [VITAL_SIGN_PARAMS.targetDate]: dayjs(targetDate).format(
        DATE_FORMAT.YYYYMMDD
      ),
    });
  }, [targetDate]);

  const [isLoading, setIsLoading] = useState(false);

  const [isShowOcrImage, setIsShowOcrImage] = useState(false);
  const [ocrImageUrl, setOcrImageUrl] = useState("");
  const [ocrImageDate, setOcrImageDate] = useState("");
  const [ocrImageMode, setOcrImageMode] = useState("");

  const [ventilatorTableValues, setVentilatorTableValues] =
    useState<VentilatorTableEntity>();

  const [vitalSignRangeDataSource, setVitalSignRangeDataSource] =
    useState<VitalSignRangeEntity[]>();

  const [vitalSignTableHeight, setVitalSignTableHeight] = useState<number>(
    () => {
      const initVitalSignTableElement = document.getElementsByClassName(
        VITAL_SIGN_TABLE_CLASSNAME
      );

      if (initVitalSignTableElement.length > 0) {
        return (initVitalSignTableElement[0] as HTMLDivElement).offsetHeight;
      }

      return 0;
    }
  );

  const [ventilatorTableHeight, setVentilatorTableHeight] = useState<number>(
    () => {
      const initVentilatorTableElement = document.getElementsByClassName(
        VENTILATOR_TABLE_CLASSNAME
      );

      if (initVentilatorTableElement.length > 0) {
        return (initVentilatorTableElement[0] as HTMLDivElement).offsetHeight;
      }

      return 0;
    }
  );

  const [patientInfo, setPatientInfo] = useState<PatientDetailEntity>();

  const [vitalSignBase64String, setVitalSignBase64String] = useState<string>();
  const [ventilatorSettingBase64String, setVentilatorSettingBase64String] =
    useState<string>();
  const [isShowImageDateModal, setShowImageDateModal] = useState(false);
  const [imageUploadMode, setImageUploadMode] = useState<string>();

  const { id: admissionRecordId } = useParams();

  const { contextHolder, handleUploadImageCallback } = useUploadImage();

  const vitalSignRef = useRef<HTMLDivElement>(null);

  const fetchPatientInfo = async () => {
    if (admissionRecordId) {
      const response = await getPatientDetail(Number(admissionRecordId));
      setPatientInfo(formatPatientDetail(response.data));
    }
  };

  const fetchAllVitalSigns = async (
    admissionRecordId: number,
    date?: string
  ) => {
    setIsLoading(true);

    let response = formatVitalSignReadResponse(
      await getAllVitalSigns(admissionRecordId, date)
    );

    if (response) {
      setVitalSignTableValues(
        transformResponseToVitalSignTableData(response.data.result)
      );

      const newChartData = transformVitalSignToGraphData(response.data.result);
      setChartData(newChartData);
      setPdfChartData(transformVitalSignToPdfGraphData(newChartData));
      setVitalSignRangeDataSource(
        transformResponseToVitalSignRangeTable(response.data.result)
      );
    }

    setIsLoading(false);

    if (response.data.result.length > 0) {
      const vitalSignResults = response.data.result.flatMap(
        (item) => item.result
      );

      if (vitalSignResults.length > 0) {
        return convertUTCtoLocalDateWithNoFormat(
          vitalSignResults[0].created_at
        );
      }
    }

    return dayjs(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      )
    );
  };

  const fetchAllVentilatorSettings = async (
    admissionRecordId: number,
    date?: string
  ) => {
    setIsLoading(true);

    const response = formatVentilatorSettingReadResponse(
      await getAllVentilatorSettings(admissionRecordId, date)
    );

    if (response) {
      setVentilatorTableValues(
        transformResponseToVentilatorTableData(response.data.result)
      );
    }

    setIsLoading(false);
  };

  useEffect(() => {
    document.title = t(DOCUMENT_HEADER.vitalSign.title);
    changeFavicon(DOCUMENT_HEADER.vitalSign.icon);

    fetchPatientInfo();
    if (targetDateQuery) {
      fetchAllVitalSigns(
        Number(admissionRecordId),
        formatDateBeforeSendToServer(
          targetDateQuery,
          DATE_FORMAT_SEND_TO_SERVER.YYYYMMDDHHmmss
        )
      ).then((latestDateWithVitalSignResult) => {
        if (!targetDateQuery) {
          setTargetDate(latestDateWithVitalSignResult);
        }
      });

      fetchAllVentilatorSettings(
        Number(admissionRecordId),
        formatDateBeforeSendToServer(
          targetDateQuery,
          DATE_FORMAT_SEND_TO_SERVER.YYYYMMDDHHmmss
        )
      );
    } else {
      fetchAllVitalSigns(Number(admissionRecordId)).then(
        (latestDateWithVitalSignResult) => {
          if (!targetDateQuery) {
            setTargetDate(latestDateWithVitalSignResult);
          }
        }
      );
      fetchAllVentilatorSettings(Number(admissionRecordId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTables = () => {
    return [
      document.querySelector("#vitalSignScrollBar"),
      document.querySelector("#graph-container"),
      document.querySelector("#graphContentTimeLine"),
      document.querySelector(`.${IN_TABLE_CLASSNAME} .ant-table-content`),
      document.querySelector(`.${OUT_TABLE_CLASSNAME} .ant-table-content`),
      document.querySelector(`.${BALANCE_TABLE_CLASSNAME} .ant-table-content`),
      document.querySelector(
        `.${VITAL_SIGN_TABLE_CLASSNAME} .ant-table-content`
      ),
      document.querySelector(
        `.${VENTILATOR_TABLE_CLASSNAME} .ant-table-content`
      ),
    ];
  };

  useEffect(() => {
    const tables = getTables();
    const syncScroll = (source: Element) => {
      const scrollLeft = source.scrollLeft;
      tables.forEach((table) => {
        if (table !== source && table) table.scrollLeft = scrollLeft;
      });
    };
    tables.forEach((table) => {
      if (table) {
        table.addEventListener("scroll", () => syncScroll(table));
      }
    });

    return () => {
      tables.forEach((table) => {
        if (table) {
          table.removeEventListener("scroll", () => syncScroll(table));
        }
      });
    };
  });

  useEffect(() => {
    if (
      !isEqual(
        document.getElementsByClassName(VITAL_SIGN_TABLE_CLASSNAME),
        vitalSignTableHeight
      ) &&
      document.getElementsByClassName(VITAL_SIGN_TABLE_CLASSNAME).length > 0
    ) {
      setVitalSignTableHeight(
        (
          document.getElementsByClassName(
            VITAL_SIGN_TABLE_CLASSNAME
          )[0] as HTMLDivElement
        ).offsetHeight
      );
    }
  });

  useEffect(() => {
    if (
      !isEqual(
        document.getElementsByClassName(VENTILATOR_TABLE_CLASSNAME),
        vitalSignTableHeight
      ) &&
      document.getElementsByClassName(VENTILATOR_TABLE_CLASSNAME).length > 0
    ) {
      setVentilatorTableHeight(
        (
          document.getElementsByClassName(
            VENTILATOR_TABLE_CLASSNAME
          )[0] as HTMLDivElement
        ).offsetHeight
      );
    }
  });

  const pdfSubColHeaderArray = transformPdfSubHeaderColumn(IN_OUT_TABLE_HEADER);

  const handleUpdateVitalSignCell = async (
    id: number | undefined,
    typeId: number,
    value: number | null,
    createdAt: string | number
  ) => {
    setIsLoading(true);

    if (id !== undefined) {
      await updateVitalSign(id, value);
    } else {
      await putVitalSign(
        Number(admissionRecordId),
        typeId,
        value,
        formatDateBeforeSendToServer(
          `${targetDate.format(
            DATE_FORMAT_SEND_TO_SERVER.YYYYMMDD
          )} ${createdAt}:00`
        )
      );
    }

    const response = formatVitalSignReadResponse(
      await getAllVitalSigns(
        Number(admissionRecordId),
        formatDateBeforeSendToServer(
          `${dayjs(targetDate).format(
            DATE_FORMAT_SEND_TO_SERVER.YYYYMMDD
          )} 00:00`,
          DATE_FORMAT_SEND_TO_SERVER.YYYYMMDDHHmmss
        )
      )
    );

    setVitalSignTableValues(
      transformResponseToVitalSignTableData(response.data.result)
    );

    const newChartData = transformVitalSignToGraphData(response.data.result);
    setChartData(newChartData);
    setPdfChartData(transformVitalSignToPdfGraphData(newChartData));
    setIsLoading(false);
  };

  const handleShowOcrImage = async (imageName: string, imageDate?: string) => {
    setOcrImageDate(String(imageDate).replace("T", " "));
    const response = await getS3Url(imageName);
    setIsLoading(false);
    if (response) {
      setOcrImageUrl(response.data.presigned_url);
      setIsShowOcrImage(true);
    }
  };

  const handleShowVitalSignImage = (imageName: string, imageDate?: string) => {
    setOcrImageMode(OCR_IMAGE_MODES.vitalSign);
    handleShowOcrImage(imageName, imageDate);
  };

  const handleShowVentilatorImage = (imageName: string, imageDate?: string) => {
    setOcrImageMode(OCR_IMAGE_MODES.ventilator);
    handleShowOcrImage(imageName, imageDate);
  };

  const handleCloseOcrImageModal = () => {
    setIsShowOcrImage(false);
  };

  const handleUpdateVentilatorCell = async (
    id: number | undefined,
    typeId: number,
    value: string | null,
    createdAt: string | number
  ) => {
    setIsLoading(true);

    if (id !== undefined) {
      await updateVentilatorSetting(id, value);
    } else {
      await putVentilatorSetting(
        Number(admissionRecordId),
        typeId,
        value,
        formatDateBeforeSendToServer(
          `${targetDate.format(
            DATE_FORMAT_SEND_TO_SERVER.YYYYMMDD
          )} ${createdAt}:00`
        )
      );
    }

    const response = formatVentilatorSettingReadResponse(
      await getAllVentilatorSettings(
        Number(admissionRecordId),
        formatDateBeforeSendToServer(
          `${dayjs(targetDate).format(
            DATE_FORMAT_SEND_TO_SERVER.YYYYMMDD
          )} 00:00`,
          DATE_FORMAT_SEND_TO_SERVER.YYYYMMDDHHmmss
        )
      )
    );

    setVentilatorTableValues(
      transformResponseToVentilatorTableData(response.data.result)
    );
    setIsLoading(false);
  };

  const handleDateChange = (date: string) => {
    fetchAllVitalSigns(
      Number(admissionRecordId),
      formatDateBeforeSendToServer(
        `${dayjs(date).format(DATE_FORMAT_SEND_TO_SERVER.YYYYMMDD)} 00:00`,
        DATE_FORMAT_SEND_TO_SERVER.YYYYMMDDHHmmss
      )
    );

    fetchAllVentilatorSettings(
      Number(admissionRecordId),
      formatDateBeforeSendToServer(
        `${dayjs(date).format(DATE_FORMAT_SEND_TO_SERVER.YYYYMMDD)} 00:00`,
        DATE_FORMAT_SEND_TO_SERVER.YYYYMMDDHHmmss
      )
    );
    resetScroll();
    setTargetDate(dayjs(date));
  };

  const resetScroll = () => {
    const tables = getTables();
    tables.forEach((table) => {
      if (table) table.scrollLeft = 0;
    });
  };

  const handleUploadVitalSignImageWithDate = async (date: string) => {
    setIsLoading(true);
    if (vitalSignBase64String && admissionRecordId) {
      const vitalSignOcrResponse = await createVitalSignOCR(
        admissionRecordId.toString(),
        vitalSignBase64String,
        date
      );

      if (typeof vitalSignOcrResponse !== "string") {
        const vitalSignsDataResponse = formatVitalSignReadResponse(
          await getAllVitalSigns(
            Number(admissionRecordId),
            formatDateBeforeSendToServer(
              `${dayjs(targetDate).format(
                DATE_FORMAT_SEND_TO_SERVER.YYYYMMDD
              )} 00:00`,
              DATE_FORMAT_SEND_TO_SERVER.YYYYMMDDHHmmss
            )
          )
        );

        setVitalSignTableValues(
          transformResponseToVitalSignTableData(
            vitalSignsDataResponse.data.result
          )
        );

        const newChartData = transformVitalSignToGraphData(
          vitalSignsDataResponse.data.result
        );
        setChartData(newChartData);
        setPdfChartData(transformVitalSignToPdfGraphData(newChartData));

        setIsLoading(false);

        handleUploadImageCallback(UPLOAD_IMAGE_STATUS.success);
      } else {
        handleUploadImageCallback(UPLOAD_IMAGE_STATUS.failed);
      }
      setShowImageDateModal(false);
      setIsLoading(false);
    }
  };

  const handleUploadVitalSignImage = (file: RcFile) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = async function (event) {
      const vitalSignBase64String = event.target?.result
        ?.toString()
        .split(",")[1];
      setVitalSignBase64String(vitalSignBase64String);
      setImageUploadMode(OCR_IMAGE_MODES.vitalSign);
      setShowImageDateModal(true);
    };
    return false;
  };

  const handleUploadVetilatorSettingImageWithDate = async (date: string) => {
    if (ventilatorSettingBase64String && admissionRecordId) {
      setIsLoading(true);
      const ventilatorSettingOcrResponse = await createVentilatorSettingOCR(
        admissionRecordId.toString(),
        ventilatorSettingBase64String,
        date
      );

      if (typeof ventilatorSettingOcrResponse !== "string") {
        const ventilatorSettingsResponse = formatVentilatorSettingReadResponse(
          await getAllVentilatorSettings(
            Number(admissionRecordId),
            formatDateBeforeSendToServer(
              `${dayjs(targetDate).format(
                DATE_FORMAT_SEND_TO_SERVER.YYYYMMDD
              )} 00:00`,
              DATE_FORMAT_SEND_TO_SERVER.YYYYMMDDHHmmss
            )
          )
        );

        setVentilatorTableValues(
          transformResponseToVentilatorTableData(
            ventilatorSettingsResponse.data.result
          )
        );

        handleUploadImageCallback(UPLOAD_IMAGE_STATUS.success);
      } else {
        handleUploadImageCallback(UPLOAD_IMAGE_STATUS.failed);
      }
      setShowImageDateModal(false);
      setIsLoading(false);
    }
  };

  const handleUploadVetilatorSettingImage = (file: RcFile) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = async function (event) {
      const ventilatorSettingBase64String = event.target?.result
        ?.toString()
        .split(",")[1];
      setVentilatorSettingBase64String(ventilatorSettingBase64String);
      setImageUploadMode(OCR_IMAGE_MODES.ventilator);
      setShowImageDateModal(true);
    };

    return false;
  };

  const handleExportPdf = useReactToPrint({
    content: () => vitalSignRef.current,
    bodyClass: "vital-sign-page-pdf",
  });

  const checkExistVitalSignData = () => {
    return (
      vitalSignTableValues &&
      (vitalSignTableValues.tableData.length ?? 0) > 0 &&
      (vitalSignTableValues.colHeaders.length ?? 0) > 0
    );
  };

  const checkExistVentilatorData = () => {
    return (
      ventilatorTableValues &&
      (ventilatorTableValues.tableData.length ?? 0) > 0 &&
      (ventilatorTableValues.colHeaders.length ?? 0) > 0
    );
  };

  return (
    <div ref={vitalSignRef} className="vital-sign-container">
      {contextHolder}
      <IcuHeader />
      <Wrapper customClassName={styles.vitalSignPageWrapper}>
        <div className={`${styles.vitalSignPage} vital-sign-page`}>
          {patientInfo ? (
            <PersonalExaminationCard
              data={patientInfo}
              customClassName="vital-sign-personal-card"
              customOrderNumber={orderNumber}
              dropdownButton={
                <UploadImageButton
                  onUploadVitalSignImage={handleUploadVitalSignImage}
                  onUploadVetilatorSettingImage={
                    handleUploadVetilatorSettingImage
                  }
                />
              }
              exportPdfButton={
                <IcuButton
                  width={40}
                  height={40}
                  backgroundColor="secondary"
                  extraClassName="pdf-export-btn"
                >
                  <img
                    src={filePdf}
                    alt="PDF file icon"
                    onClick={handleExportPdf}
                  />
                </IcuButton>
              }
            />
          ) : (
            <></>
          )}

          <Flex justify="flex-end" className={styles.datePicker}>
            <CustomDatePicker
              onDateChange={handleDateChange}
              defaultValue={targetDate}
            />
          </Flex>

          {!!(
            vitalSignRangeDataSource?.length &&
            vitalSignRangeDataSource.length > 0
          ) && (
            <>
              <VitalSignGraph
                inputData={chartData}
                vitalSignRangeDataSource={vitalSignRangeDataSource}
                isShowGraph={!!vitalSignTableValues?.colHeaders.length}
                resetScroll={resetScroll}
              />
              <div className="pdf-vital-sign-graph">
                {pdfChartData.map((item) => (
                  <PdfVitalSignGraph
                    inputData={item}
                    vitalSignRangeDataSource={vitalSignRangeDataSource}
                    isShowGraph={!!vitalSignTableValues?.colHeaders.length}
                    key={`${generateRandomNumber()}`}
                  />
                ))}
              </div>
            </>
          )}

          {checkExistVitalSignData() ? (
            <div
              className={`${styles.vitalSignTableSection} vital-sign-table-section`}
              style={{
                height: vitalSignTableHeight,
              }}
            >
              <div
                className={styles.vitalSignHeader}
                style={{
                  width: vitalSignTableHeight,
                  top: vitalSignTableHeight,
                }}
              >
                {t("Vital signs")}
              </div>
              <VitalSignTable
                data={vitalSignTableValues?.tableData ?? []}
                colHeaders={IN_OUT_TABLE_HEADER}
                onUpdateCell={handleUpdateVitalSignCell}
                showOcrImage={handleShowVitalSignImage}
              />
            </div>
          ) : (
            <></>
          )}

          {checkExistVitalSignData() ? (
            <div>
              {pdfSubColHeaderArray.map((item) => (
                <div
                  className={`${styles.vitalSignTableSection} pdf-vital-sign-table-section`}
                  style={{
                    height: vitalSignTableHeight,
                  }}
                  key={`${generateRandomNumber()}`}
                >
                  <div
                    className={`${styles.vitalSignHeader} pdf-vital-sign-header`}
                    style={{
                      width: vitalSignTableHeight,
                      top: vitalSignTableHeight,
                    }}
                  >
                    {t("Vital signs")}
                  </div>
                  <PdfVitalSignTable
                    data={vitalSignTableValues?.tableData ?? []}
                    colHeaders={item}
                  />
                </div>
              ))}
            </div>
          ) : (
            <></>
          )}

          {checkExistVentilatorData() ? (
            <div
              className={`${styles.vetilatorTableSection} ventilator-table-section`}
              style={{
                height: ventilatorTableHeight,
              }}
            >
              <div
                className={`${styles.ventilatorHeader} ventilator-header`}
                style={{
                  width: ventilatorTableHeight,
                  top: ventilatorTableHeight,
                }}
              >
                {t("Ventilator settings")}
              </div>
              <VentilatorTable
                data={ventilatorTableValues?.tableData ?? []}
                colHeaders={IN_OUT_TABLE_HEADER}
                onUpdateCell={handleUpdateVentilatorCell}
                showOcrImage={handleShowVentilatorImage}
              />
            </div>
          ) : (
            <></>
          )}

          {checkExistVentilatorData() ? (
            <div>
              {pdfSubColHeaderArray.map((item) => (
                <div
                  className={`${styles.vetilatorTableSection} pdf-ventilator-table-section`}
                  style={{
                    height: ventilatorTableHeight,
                  }}
                  key={`${generateRandomNumber()}`}
                >
                  <div
                    className={`${styles.ventilatorHeader} ventilator-header pdf-ventilator-header`}
                    style={{
                      width: ventilatorTableHeight,
                      top: ventilatorTableHeight,
                    }}
                  >
                    {t("Ventilator settings")}
                  </div>
                  <PdfVentilatorTable
                    data={ventilatorTableValues?.tableData ?? []}
                    colHeaders={item}
                  />
                </div>
              ))}
            </div>
          ) : (
            <></>
          )}

          <br></br>
          <Flex justify="flex-end" className={styles.datePicker}>
            <CustomDatePicker
              onDateChange={handleDateChange}
              defaultValue={targetDate}
            />
          </Flex>
          <InOutBalanceSection
            setIsLoading={setIsLoading}
            targetDate={targetDate}
          />
          <div className={styles.vitalSignScrollBar} id="vitalSignScrollBar">
            <div></div>
          </div>
        </div>
      </Wrapper>
      <IcuFooter />
      {isShowOcrImage && (
        <OcrImageModal
          isShow={isShowOcrImage}
          ocrImageUrl={ocrImageUrl}
          handleCloseOcrImageModal={handleCloseOcrImageModal}
          idAdmissionRecord={patientInfo?.id_admission_record ?? 0}
          ocrImageDate={ocrImageDate}
          ocrImageMode={ocrImageMode}
          setIsLoading={setIsLoading}
        />
      )}
      {isShowImageDateModal && (
        <UploadImageDateModal
          handleClose={() => setShowImageDateModal(false)}
          isShow={isShowImageDateModal}
          handleOk={
            imageUploadMode === OCR_IMAGE_MODES.vitalSign
              ? handleUploadVitalSignImageWithDate
              : handleUploadVetilatorSettingImageWithDate
          }
        />
      )}
      {isLoading && <LoadingOverlay />}
    </div>
  );
};

export default VitalSignPage;
