import { API_GET_S3_IMAGE_URL } from "../configs/api/endpoints";
import networkAdapter from "../configs/api/networkAdapter";
import { BaseResponse } from "../types/common/BaseResponse.model";
import { OcrImageReadResponse } from "../types/ocr-image/OcrImageReadResponse.model";

export const getS3Url = (
  imageName: string,
): Promise<BaseResponse<OcrImageReadResponse>> => {
  return networkAdapter.get(`${API_GET_S3_IMAGE_URL}`, {
    image_key: imageName
  });
};
