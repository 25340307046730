import { DATE_FORMAT } from "../../constants/common/dateFormat.constant";
import {
  VitalSignEntity,
  VitalSignReadResponse,
  VitalSignReadResponseData,
} from "../../types/vital-sign/VitalSignReadResponse.model";
import dayjs from "dayjs";
import { VitalSignTableRow } from "../../types/vital-sign/VitalSignTableRow.model";
import { COLOR_VALUE } from "../../constants/common/color.constant";
import {
  VentilatorReadResponse,
  VentilatorReadResponseData,
} from "../../types/vital-sign/VentilatorReadResponse.model";
import { convertUTCtoLocalDate } from "../helpers";
import { max, min } from "lodash";
import { VentilatorSettingCategory } from "../../constants/vital-sign/VentilatorSettingCategory.constant";
import { TRIANGLE_POINT_STYLE_VITAL_SIGN_LIST } from "../../constants/vital-sign/TrianglePointStyleVitalSignList.constant";
import arrowUpIcon from "../../assets/icons/arrow-up.svg";
import arrowDownIcon from "../../assets/icons/arrow-down.svg";
import { VITAL_SIGN_NAMES_ORDER } from "../../constants/vital-sign/InitialShowVitalSignNames.constant";
import imageBtnImg from "../../assets/img/image-btn.png";
import { BaseResponse } from "../../types/common/BaseResponse.model";
import { VitalSignRecordType } from "../../constants/vital-sign/VitalSignRecordType";
import { VitalSignHourCategory } from "../../types/vital-sign/VitalSignHourCategory.model";
import { HourVitalSignCategoryByLabel } from "../../types/vital-sign/VitalSignHourCategoryByLabel.model";
import { IN_OUT_TABLE_HEADER } from "../../constants/vital-sign/InOutTable.constant";
import { VitalSignRangeEntity } from "../../types/vital-sign/VitalSignRangeEntity.model";
import { VITAL_SIGN_NAMES } from "../../constants/vital-sign/VitalSignGraph.constants";
import { ChartDataEntity } from "../../types/vital-sign/ChartDataEntity.model";
import { PDF_VITAL_SIGN_DISPLAYED_COLUMN } from "../../constants/vital-sign/PdfVitalSignDisplayedColumn.constant";

const getHourLabels = (
  data: VitalSignReadResponse[] | VentilatorReadResponse[]
) => {
  const hourSet = new Set<string>();

  data.forEach((item) => {
    if (item.result.length > 0) {
      item.result.forEach(({ created_at }) => {
        hourSet.add(
          dayjs(convertUTCtoLocalDate(created_at)).format(DATE_FORMAT.HHmm)
        );
      });
    }
  });

  const hourLabels = Array.from(hourSet);

  hourLabels.sort((a, b) => a.localeCompare(b));

  return {
    hourLabels,
  };
};

const generateImageButtonItem = () => {
  return (
    <div className="img-btn">
      <img src={imageBtnImg} />
    </div>
  );
};

const checkSkippedValue = (ctx: any, value: number[]) =>
  ctx.p0.skip || ctx.p1.skip ? value : undefined;

export const getColorByVitalSignName = (
  dataSource: VitalSignReadResponse[],
  name: string
) => {
  if (TRIANGLE_POINT_STYLE_VITAL_SIGN_LIST.includes(name)) {
    return "#00ff00";
  }

  return dataSource.find((item) => item.name === name)?.color;
};

const calculateVitalSignStepSize = (
  minScale: number,
  maxScale: number,
  total: number
) => {
  return (maxScale - minScale) / (total - 1);
};

const getMinMaxByVitalSignName = (
  dataSource: VitalSignReadResponse[],
  name: string
) => {
  const targetVitalSign = dataSource.find((item) => item.name === name);

  if (targetVitalSign) {
    const minScale = min(targetVitalSign?.ranges);
    const maxScale = max(targetVitalSign?.ranges);

    return {
      stepSize: calculateVitalSignStepSize(
        minScale,
        maxScale,
        targetVitalSign.ranges.length
      ),
      maxScale,
      minScale,
    };
  }

  return {
    stepSize: 0,
    maxScale: 0,
    minScale: 0,
  };
};

export const getPointStyleByVitalSignName = (name: string) => {
  const sbpPointImage = new Image(16, 16);
  const dbpPointImage = new Image(16, 16);

  sbpPointImage.src = arrowDownIcon;
  dbpPointImage.src = arrowUpIcon;

  switch (name) {
    case "SBP":
      return sbpPointImage;
    case "DBP":
      return dbpPointImage;

    default:
      return "circle";
  }
};

export const transformVitalSignToGraphData = (
  data: VitalSignReadResponse[]
) => {
  data = data.filter((item) => {
    return item.name !== VitalSignRecordType.IMAGE;
  });
  const hourVitalSignCategoryByLabelList: HourVitalSignCategoryByLabel[] = [];

  data.forEach((item) => {
    if (item.result.length > 0) {
      const { allVitalSignDataByHour } = filterLatestVitalSignDataByHour(
        item.result
      );

      hourVitalSignCategoryByLabelList.push({
        vitalSignName: item.name,
        value: allVitalSignDataByHour,
      });
    }
  });

  let xLabels = IN_OUT_TABLE_HEADER;

  xLabels.sort((a, b) => a.localeCompare(b));

  const vitalSignValueMap = new Map<string, (number | null | string)[]>();

  data.forEach((item) => {
    const newVitalSignValues = xLabels.map((hourLabel) => {
      const { latestVitalSignDataByHourList } = filterLatestVitalSignDataByHour(
        item.result
      );

      const correspondValue = latestVitalSignDataByHourList.find(
        ({ created_at }) =>
          dayjs(convertUTCtoLocalDate(created_at)).hour() ===
          Number(hourLabel.split(":")[0])
      );

      if (correspondValue) {
        return correspondValue.value;
      }

      return null;
    });

    vitalSignValueMap.set(item.name, newVitalSignValues);
  });

  const vitalSignList = Array.from(vitalSignValueMap, ([label, value]) => ({
    label,
    value,
  }));

  vitalSignList.forEach((vitalSign) => {
    const valueList = vitalSign.value;

    if (valueList.length > 0) {
      for (let index = 0; index < valueList.length; index++) {
        if (!valueList[index]) {
          valueList[index] = null;
        }
      }
    }
  });

  const datasets = vitalSignList.map((item, index) => ({
    label: item.label,
    data: item.value,
    borderColor: getColorByVitalSignName(data, item.label) ?? "",
    backgroundColor: getColorByVitalSignName(data, item.label) ?? "",
    yAxisID: `y${index}`,
    pointRadius: 2,
    pointHoverRadius: 4,
    spanGaps: true,
    segment: {
      borderDash: (ctx: any) =>
        checkSkippedValue(
          ctx,
          TRIANGLE_POINT_STYLE_VITAL_SIGN_LIST.includes(item.label)
            ? [10, 5]
            : [6, 0]
        ),
    },
    pointStyle: getPointStyleByVitalSignName(item.label),
    borderDash: TRIANGLE_POINT_STYLE_VITAL_SIGN_LIST.includes(item.label)
      ? [10, 5]
      : undefined,
    hourVitalSignCategoryByLabel: hourVitalSignCategoryByLabelList.find(
      (category) => category.vitalSignName === item.label
    ),
  }));

  const scaleOption = datasets
    .map((item) => {
      const { stepSize, maxScale, minScale } = getMinMaxByVitalSignName(
        data,
        item.label
      );

      return {
        yAxisID: item.yAxisID,
        stepSize,
        max: maxScale,
        min: minScale,
      };
    })
    .reduce(
      (prev, current) => {
        return {
          ...prev,
          [current.yAxisID]: {
            type: "linear",
            display: false,
            min: current.min - current.stepSize,
            max: current.max + current.stepSize,
            ticks: {
              stepSize: current.stepSize,
            },
          },
        };
      },
      {
        x: {
          ticks: {
            font: {
              size: 14,
              weight: 500,
              family: "Manrope, Poppins, sans-serif",
              color: COLOR_VALUE.grey1,
            },
          },
          grid: {
            lineWidth: 0.25,
          },
          border: {
            display: false,
          },
        },
      }
    );

  return {
    datasets,
    labels: xLabels,
    scaleOption,
    hourVitalSignCategoryByLabelList,
  };
};

const filterVentilatorSettingByCategory = (
  list: VentilatorReadResponse[],
  category: string
) => {
  const resultTable = [
    {
      name:
        category === VentilatorSettingCategory.MEASURE
          ? `${category}d`
          : category,
    },
  ];

  const colHeaderSet = new Set<string>();

  list
    .filter((item) => item.category === category)
    .forEach((item) => {
      const latestVitalSignDataByHourList = filterLatestVitalSignDataByHour(
        item.result
      ).latestVitalSignDataByHourList;

      const rowData = latestVitalSignDataByHourList.reduce(
        (prev, current) => {
          const colHeader = `${String(
            dayjs(convertUTCtoLocalDate(current.created_at)).hour()
          ).padStart(2, "0")}:00`;

          colHeaderSet.add(colHeader);
          return {
            ...prev,
            id: current.id,
            [`${colHeader}utcDate`]: current.created_at,
            [`${colHeader}`]:
              item.name === VitalSignRecordType.IMAGE
                ? generateImageButtonItem()
                : current.value ?? undefined,
            date: dayjs(convertUTCtoLocalDate(current.created_at)).format(
              DATE_FORMAT.YYYYMMDDHHmm
            ),
            [`${colHeader}Id`]: current.id,
            [`${colHeader}value`]: current.value,
          };
        },
        {
          name: item.name,
          typeId: item.id,
        }
      );
      resultTable.push(rowData);
    });

  return {
    resultTable,
    colHeaders: Array.from(colHeaderSet),
  };
};

export const transformResponseToVitalSignTableData = (
  data: VitalSignReadResponse[]
) => {
  if (data.length > 0) {
    let tableData: VitalSignTableRow[] = [];
    const colHeaderSet = new Set<string>();

    data.sort((a, b) => a.name.localeCompare(b.name));

    const vitalSignSortedByName: VitalSignReadResponse[] = [];

    VITAL_SIGN_NAMES_ORDER.forEach((vitalSignName) => {
      const targetVitalSign = data.find((item) => item.name === vitalSignName);

      if (targetVitalSign) {
        vitalSignSortedByName.push(targetVitalSign);
      }
    });
    vitalSignSortedByName.forEach((item) => {
      const latestVitalSignDataByHourList = filterLatestVitalSignDataByHour(
        item.result
      ).latestVitalSignDataByHourList;
      const rowData = latestVitalSignDataByHourList.reduce(
        (prev, current) => {
          const colHeader = `${String(
            dayjs(convertUTCtoLocalDate(current.created_at)).hour()
          ).padStart(2, "0")}:00`;

          colHeaderSet.add(colHeader);
          return {
            ...prev,
            id: current.id,
            [`${colHeader}utcDate`]: current.created_at,
            [`${colHeader}`]:
              item.name === VitalSignRecordType.IMAGE
                ? generateImageButtonItem()
                : current.value ?? undefined,
            date: dayjs(convertUTCtoLocalDate(current.created_at)).format(
              DATE_FORMAT.YYYYMMDDHHmm
            ),
            [`${colHeader}Id`]: current.id,
            [`${colHeader}value`]: current.value,
          };
        },
        {
          name: item.name,
          typeId: item.id,
        }
      );
      tableData.push(rowData);
    });

    const finalColHeaders = Array.from(colHeaderSet);

    finalColHeaders.sort((a, b) => {
      return parseInt(a.split(":")[0]) - parseInt(b.split(":")[0]);
    });

    return {
      colHeaders: finalColHeaders,
      tableData,
    };
  }
  return {
    colHeaders: [],
    tableData: [],
  };
};
export const formatVitalSignReadResponse = (
  response: BaseResponse<VitalSignReadResponseData>
) => {
  const imageResult = response.data.images.map((image) => ({
    id: image.id,
    value: image.name,
    created_at: image.created_at,
    update_at: image.update_at,
  }));
  return {
    ...response,
    data: {
      ...response.data,
      result: [
        ...response.data.result,
        {
          id: response.data.result.length + 1,
          name: VitalSignRecordType.IMAGE,
          ranges: [],
          color: "",
          result: imageResult,
        },
      ],
    },
  };
};

export const formatVentilatorSettingReadResponse = (
  response: BaseResponse<VentilatorReadResponseData>
) => {
  const imageResult = response.data.images.map((image) => ({
    id: image.id,
    value: image.name,
    created_at: image.created_at,
    update_at: image.update_at,
  }));
  return {
    ...response,
    data: {
      ...response.data,
      result: [
        ...response.data.result,
        {
          id: response.data.result.length + 1,
          name: VitalSignRecordType.IMAGE,
          category: "Setting",
          result: imageResult,
        },
      ],
    },
  };
};

export const transformResponseToVentilatorTableData = (
  data: VentilatorReadResponse[]
) => {
  if (data.length > 0) {
    let tableData: VitalSignTableRow[] = [];
    const dataImage = data.filter((item) => {
      return item.name === VitalSignRecordType.IMAGE;
    });
    data = data.filter((item) => {
      return item.name !== VitalSignRecordType.IMAGE;
    });

    data.sort((a, b) => a.name.localeCompare(b.name));
    data = [...data, ...dataImage];
    const { resultTable: measureData, colHeaders: measureColHeaders } =
      filterVentilatorSettingByCategory(
        data,
        VentilatorSettingCategory.MEASURE
      );
    const { resultTable: settingData, colHeaders: settingColHeaders } =
      filterVentilatorSettingByCategory(
        data,
        VentilatorSettingCategory.SETTING
      );

    tableData = [...measureData, ...settingData];

    const colHeaderSet = new Set<string>([
      ...measureColHeaders,
      ...settingColHeaders,
    ]);

    const finalColHeaders = Array.from(colHeaderSet);

    finalColHeaders.sort((a, b) => {
      return parseInt(a.split(":")[0]) - parseInt(b.split(":")[0]);
    });
    return {
      colHeaders: finalColHeaders,
      tableData,
    };
  }
  return {
    colHeaders: [],
    tableData: [],
  };
};

export const sortVitalSignByCreateAt = (
  a: VitalSignEntity,
  b: VitalSignEntity
) => {
  if (dayjs(a.created_at).isBefore(dayjs(b.created_at))) return -1;
  if (dayjs(a.created_at).isAfter(dayjs(b.created_at))) return 1;

  return 0;
};

export const calculateGraphContentWidth = (totalLabels: number) => {
  const colWidth = 80;

  return colWidth * (totalLabels - 1) + 55;
};

export const transformResponseToVitalSignRangeTable = (
  data: VitalSignReadResponse[]
) => {
  data = data.filter((item) => {
    return item.name !== VitalSignRecordType.IMAGE;
  });
  data.forEach((item) => {
    if (item.ranges.length > 0) {
      item.ranges.sort((rangeA, rangeB) => rangeB - rangeA);
    }
  });

  const vitalSignSortedByName: VitalSignReadResponse[] = [];

  VITAL_SIGN_NAMES_ORDER.forEach((vitalSignName) => {
    const targetVitalSign = data.find((item) => item.name === vitalSignName);

    if (targetVitalSign) {
      vitalSignSortedByName.push(targetVitalSign);
    }
  });

  const vitalSignRangeDataSource: VitalSignRangeEntity[] = [
    {
      id: 0,
      color: "",
      name: VITAL_SIGN_NAMES.all,
      ranges: [],
    },
    ...vitalSignSortedByName.map((item) => ({
      id: item.id,
      name: item.name,
      ranges: item.ranges,
      color: item.color,
    })),
  ];

  return vitalSignRangeDataSource;
};

const getMinMaxVitalSignCreateAt = (data: VitalSignEntity[]) => {
  const hourSet = new Set<number>();

  data.forEach((item) => {
    hourSet.add(dayjs(convertUTCtoLocalDate(item.created_at)).hour());
  });

  const hourLabels = Array.from(hourSet);

  hourLabels.sort((a, b) => a - b);

  if (hourLabels.length > 0) {
    return [hourLabels[0], hourLabels[hourLabels.length - 1]];
  }

  return [];
};

export const getVitalSignDataByHour = (data: VitalSignEntity[]) => {
  const hourCategory: VitalSignHourCategory = {};

  const [minCreateAt, maxCreateAt] = getMinMaxVitalSignCreateAt(data);

  for (let value = minCreateAt; value <= maxCreateAt; value++) {
    const vitalSignDataByHourList: VitalSignEntity[] = [];
    data.forEach((item) => {
      if (dayjs(convertUTCtoLocalDate(item.created_at)).hour() === value) {
        vitalSignDataByHourList.push(item);
      }
    });

    if (vitalSignDataByHourList.length > 0) {
      hourCategory[value] = vitalSignDataByHourList;
    }
  }

  return hourCategory;
};

export const filterLatestVitalSignDataByHour = (data: VitalSignEntity[]) => {
  const hourCategory = getVitalSignDataByHour(data);
  const latestHourCategory: VitalSignHourCategory = {};

  for (let hourValue in hourCategory) {
    if (hourCategory[hourValue].length > 0) {
      const latestVitalDataByHour = hourCategory[hourValue].reduce(
        (latestItem, currentItem) => {
          return dayjs(currentItem.created_at).isAfter(
            dayjs(latestItem.created_at)
          ) ||
            (dayjs(currentItem.created_at).isSame(
              dayjs(latestItem.created_at)
            ) &&
              currentItem.id > latestItem.id)
            ? currentItem
            : latestItem;
        }
      );

      latestHourCategory[hourValue] = [latestVitalDataByHour];
    }
  }

  return {
    latestVitalSignDataByHourList: Object.values(latestHourCategory).flat(),
    allVitalSignDataByHour: hourCategory,
  };
};

export const transformVitalSignToPdfGraphData = (
  chartData: ChartDataEntity
) => {
  const subChartDataCount = Math.ceil(chartData.labels.length / PDF_VITAL_SIGN_DISPLAYED_COLUMN);

  const subChartDataArray: ChartDataEntity[] = [];

  for (let index = 0; index < subChartDataCount; index++) {
    const startIndex = PDF_VITAL_SIGN_DISPLAYED_COLUMN * index;
    const endIndex = PDF_VITAL_SIGN_DISPLAYED_COLUMN * (index + 1);

    const newDatasets = chartData.datasets.map(item => ({
      ...item,
      data: item.data.slice(startIndex, endIndex)
    }));

    const newSubChartData: ChartDataEntity = {
      datasets: newDatasets,
      labels: chartData.labels.slice(startIndex, endIndex),
      scaleOption: chartData.scaleOption
    }

    subChartDataArray.push(newSubChartData);
  }

  return subChartDataArray;
};

export const transformPdfSubHeaderColumn = (colHeaders: string[]) => {
  const subTableCount = Math.ceil(colHeaders.length / PDF_VITAL_SIGN_DISPLAYED_COLUMN);
  const pdfColHeaderArray: string[][] = [];

  for (let index = 0; index < subTableCount; index++) {
    const startIndex = PDF_VITAL_SIGN_DISPLAYED_COLUMN * index;
    const endIndex = PDF_VITAL_SIGN_DISPLAYED_COLUMN * (index + 1);
    const newPdfColHeaders = colHeaders.slice(startIndex, endIndex);

    pdfColHeaderArray.push(newPdfColHeaders);
  }

  return pdfColHeaderArray;
}
