export const LOCAL_STORAGE_KEY = {
  accessToken: "ACCESS_TOKEN",
  refreshToken: "REFRESH_TOKEN",
  userName: "USER_NAME",
  patientOrderNumber: "PATIENT_ORDER_NUMBER",
  openingTabs: "OPENING_TABS",
  afterLoginUrl: "AFTER_LOGIN_URL",
  detailActiveTab: "DETAIL_ACTIVE_TAB",
  detailActiveProgressId: "DETAIL_ACTIVE_PROGRESS_ID"
};
