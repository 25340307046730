import React, { useEffect, useState } from "react";
import styles from "./TimeSeriesExamResultPage.module.scss";
import { Flex } from "antd";
import CustomDatePicker from "../../components/common/custom-date-picker/CustomDatePicker";
import dayjs from "dayjs";
import { PatientDetailEntity } from "../../types/common/PatientDetail.model";
import IcuButton from "../../components/common/button/IcuButton";
import { useTranslation } from "react-i18next";
import greyBiochemistryIcon from "../../assets/icons/grey-chemistry-1.svg";
import greyBloodIcon from "../../assets/icons/grey-chemistry.svg";
import greyCoagulationIcon from "../../assets/icons/grey-blood.svg";
import "./TimeSeriesExamResultPage.scss";
import {
  EXAMINATION_CATEGORY_VALUE,
  ExaminationCategory,
} from "../../constants/examination-result/ExaminationCategory.constant";
import whiteBiochemistryIcon from "../../assets/icons/white-chemistry-1.svg";
import whiteBloodIcon from "../../assets/icons/white-chemistry.svg";
import whiteCoagulationIcon from "../../assets/icons/white-blood.svg";
import PersonalExaminationCard from "../../components/common/personal-examination-card/PersonalExaminationCard";
import { formatTimeSeriesExamData } from "../../utils/time-series-exam-result/TimeSeriesExamResult.helper";
import TimeSeriesExamTable from "../../components/time-series-exam-result/table/TimeSeriesExamTable";
import IcuHeader from "../../components/common/header/IcuHeader";
import Wrapper from "../../components/common/wrapper/Wrapper";
import IcuFooter from "../../components/common/footer/IcuFooter";
import {
  getAllExamResults,
  putExaminationResult,
  updateExaminationResult,
} from "../../services/examination-result/examinationResultService";
import { TimeSeriesDataSource } from "../../types/time-series-exam-table/TimeSeriesDataSource.model";
import { LoadingOverlay } from "../../components/common/loading/LoadingOverlay";
import {
  DATE_FORMAT,
  DATE_FORMAT_SEND_TO_SERVER,
} from "../../constants/common/dateFormat.constant";
import {
  changeFavicon,
  convertUTCtoLocalDateWithNoFormat,
  formatDateBeforeSendToServer,
} from "../../utils/helpers";
import { useParams, useSearchParams } from "react-router-dom";
import { getPatientDetail } from "../../services/patient/patientService";
import { formatPatientDetail } from "../../utils/patient-detail/PatientDetail.helper";
import { DOCUMENT_HEADER } from "../../constants/common/documentHeader.constant";
import { TimeSeriesSelectedDateByTab } from "../../types/time-series-exam-table/TimeSeriesSelectedDateByTab.model";
import greyBloodGasIcon from "../../assets/icons/grey-blood-gas.svg";
import greyUrinaryIcon from "../../assets/icons/grey-urinary.svg";
import whiteBloodGasIcon from "../../assets/icons/white-blood-gas.svg";
import whiteUrinaryIcon from "../../assets/icons/white-urinary.svg";

const TimeSeriesExamResultPage = () => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState(ExaminationCategory.BIOCHEMISTRY);

  const [timeSeriesDataSource, setTimeSeriesDataSource] =
    useState<TimeSeriesDataSource>();

  const [isLoading, setIsLoading] = useState(false);

  const [searchParams] = useSearchParams();

  const [targetDateByTab, setTargetDateByTab] =
    useState<TimeSeriesSelectedDateByTab>({
      [ExaminationCategory.BIOCHEMISTRY]: searchParams.get("date")
        ? dayjs(searchParams.get("date"))
        : undefined,
      [ExaminationCategory.BLOOD_COUNT]: searchParams.get("date")
        ? dayjs(searchParams.get("date"))
        : undefined,
      [ExaminationCategory.COAGULATION]: searchParams.get("date")
        ? dayjs(searchParams.get("date"))
        : undefined,
      [ExaminationCategory.BLOOD_GAS_ANALYSIS]: searchParams.get("date")
        ? dayjs(searchParams.get("date"))
        : undefined,
      [ExaminationCategory.URINARY_ANALYSIS]: searchParams.get("date")
        ? dayjs(searchParams.get("date"))
        : undefined,
    });

  const [patientInfo, setPatientInfo] = useState<PatientDetailEntity>();

  const { id: admissionRecordId } = useParams();

  const fetchPatientInfo = async () => {
    if (admissionRecordId) {
      const response = await getPatientDetail(Number(admissionRecordId));

      setPatientInfo(formatPatientDetail(response.data));
    }
  };

  const fetchAllExamResults = async (
    admissionRecordId: number,
    category: number,
    targetDate?: string
  ) => {
    setIsLoading(true);

    const response = await getAllExamResults(
      admissionRecordId,
      category,
      targetDate
    );

    setTimeSeriesDataSource(
      response ? formatTimeSeriesExamData(response.data) : undefined
    );
    setIsLoading(false);

    if (response.data.length > 0 && response.data[0].result.length > 0) {
      return convertUTCtoLocalDateWithNoFormat(
        response.data[0].result[0].created_at
      );
    }

    return dayjs(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      )
    );
  };

  useEffect(() => {
    document.title = t(DOCUMENT_HEADER.timeSeries.title);
    changeFavicon(DOCUMENT_HEADER.timeSeries.icon);

    fetchPatientInfo();

    fetchAllExamResults(
      Number(admissionRecordId),
      EXAMINATION_CATEGORY_VALUE.biochemistry,
      targetDateByTab[ExaminationCategory.BIOCHEMISTRY]
        ? formatDateBeforeSendToServer(
            targetDateByTab[ExaminationCategory.BIOCHEMISTRY].toDate(),
            DATE_FORMAT_SEND_TO_SERVER.YYYYMMDDHHmmss
          )
        : undefined
    ).then((latestExamResultDate) => {
      setTargetDateByTab((prevState) => ({
        ...prevState,
        [ExaminationCategory.BIOCHEMISTRY]: latestExamResultDate,
      }));
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDateChange = (date: string) => {
    fetchAllExamResults(
      Number(admissionRecordId),
      EXAMINATION_CATEGORY_VALUE[activeTab],
      formatDateBeforeSendToServer(
        `${dayjs(date).format(DATE_FORMAT_SEND_TO_SERVER.YYYYMMDD)} 00:00`,
        DATE_FORMAT_SEND_TO_SERVER.YYYYMMDDHHmmss
      )
    );

    setTargetDateByTab((prevState) => ({
      ...prevState,
      [activeTab]: dayjs(date),
    }));
  };

  const getClassNameByActiveTab = (currentTab: ExaminationCategory) => {
    if (currentTab === activeTab) {
      return "time-series-active-tab";
    }

    return "";
  };

  const handleClickResultTypeBtn = (selectedTab: ExaminationCategory) => {
    if (selectedTab !== activeTab) {
      setActiveTab(selectedTab);

      fetchAllExamResults(
        Number(admissionRecordId),
        EXAMINATION_CATEGORY_VALUE[selectedTab],
        targetDateByTab[selectedTab] !== undefined
          ? formatDateBeforeSendToServer(
              `${targetDateByTab[selectedTab]!.format(
                DATE_FORMAT_SEND_TO_SERVER.YYYYMMDD
              )} 00:00`
            )
          : undefined
      ).then((latestExamResultDate) => {
        setTargetDateByTab((prevState) => ({
          ...prevState,
          [selectedTab]:
            targetDateByTab[selectedTab] !== undefined
              ? targetDateByTab[selectedTab]!
              : latestExamResultDate,
        }));
      });
    }
  };

  const handleUpdateCell = async (
    id: number | undefined,
    value: string | null,
    typeId: number,
    createdAt: string | number
  ) => {
    setIsLoading(true);

    if (id !== undefined) {
      await updateExaminationResult(id, value);
    } else if (typeId) {
      await putExaminationResult(
        Number(admissionRecordId),
        typeId,
        value,
        `${createdAt}:00`.replaceAll("/", "-")
      );
    }

    if (targetDateByTab[activeTab]) {
      const response = await getAllExamResults(
        Number(admissionRecordId),
        EXAMINATION_CATEGORY_VALUE[activeTab],
        formatDateBeforeSendToServer(
          `${targetDateByTab[activeTab]!.format(
            DATE_FORMAT_SEND_TO_SERVER.YYYYMMDD
          )} 00:00`
        )
      );

      setTimeSeriesDataSource(formatTimeSeriesExamData(response.data));
    }

    setIsLoading(false);
  };

  return (
    <>
      <IcuHeader />
      <Wrapper>
        <div className={styles.timeSeriesExamPage}>
          {patientInfo ? (
            <PersonalExaminationCard
              data={patientInfo}
              customOrderNumber={
                searchParams.get("date") ??
                dayjs(
                  new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    new Date().getDate()
                  )
                ).format(DATE_FORMAT.YYYYMMDD)
              }
              customOrderNumberClassName="time-series-selected-date"
            />
          ) : (
            <></>
          )}

          <Flex justify="flex-end" className={styles.btnGroup} gap={16}>
            <Flex gap={10} className={styles.examTypeButtons}>
              <IcuButton
                backgroundColor="secondary"
                color="grey-2"
                icon={
                  <img
                    src={
                      activeTab === ExaminationCategory.BIOCHEMISTRY
                        ? whiteBiochemistryIcon
                        : greyBiochemistryIcon
                    }
                    alt="bio icon"
                  />
                }
                width={161}
                height={42}
                extraClassName={`time-series-bio-btn ${getClassNameByActiveTab(
                  ExaminationCategory.BIOCHEMISTRY
                )}`}
                onClick={() =>
                  handleClickResultTypeBtn(ExaminationCategory.BIOCHEMISTRY)
                }
              >
                {t("Biochemistry")}
              </IcuButton>
              <IcuButton
                backgroundColor="secondary"
                color="grey-2"
                icon={
                  <img
                    src={
                      activeTab === ExaminationCategory.BLOOD_COUNT
                        ? whiteBloodIcon
                        : greyBloodIcon
                    }
                    alt="blood count icon"
                  />
                }
                width={151}
                height={42}
                extraClassName={`time-series-blood-count-btn ${getClassNameByActiveTab(
                  ExaminationCategory.BLOOD_COUNT
                )}`}
                onClick={() =>
                  handleClickResultTypeBtn(ExaminationCategory.BLOOD_COUNT)
                }
              >
                {t("Blood count")}
              </IcuButton>
              <IcuButton
                backgroundColor="secondary"
                color="grey-2"
                icon={
                  <img
                    src={
                      activeTab === ExaminationCategory.COAGULATION
                        ? whiteCoagulationIcon
                        : greyCoagulationIcon
                    }
                    alt="coagulation icon"
                  />
                }
                width={153}
                height={42}
                extraClassName={`time-series-coagulation-btn ${getClassNameByActiveTab(
                  ExaminationCategory.COAGULATION
                )}`}
                onClick={() =>
                  handleClickResultTypeBtn(ExaminationCategory.COAGULATION)
                }
              >
                {t("Coagulation")}
              </IcuButton>
              <IcuButton
                backgroundColor="secondary"
                color="grey-2"
                icon={
                  <img
                    src={
                      activeTab === ExaminationCategory.BLOOD_GAS_ANALYSIS
                        ? whiteBloodGasIcon
                        : greyBloodGasIcon
                    }
                    alt="blood gas icon"
                  />
                }
                width={189}
                height={42}
                extraClassName={`time-series-blood-gas-btn ${getClassNameByActiveTab(
                  ExaminationCategory.BLOOD_GAS_ANALYSIS
                )}`}
                onClick={() =>
                  handleClickResultTypeBtn(
                    ExaminationCategory.BLOOD_GAS_ANALYSIS
                  )
                }
              >
                {t("Blood Gas Analysis")}
              </IcuButton>

              <IcuButton
                backgroundColor="secondary"
                color="grey-2"
                icon={
                  <img
                    src={
                      activeTab === ExaminationCategory.URINARY_ANALYSIS
                        ? whiteUrinaryIcon
                        : greyUrinaryIcon
                    }
                    alt="urinary icon"
                  />
                }
                width={176}
                height={42}
                extraClassName={`time-series-urinary-btn ${getClassNameByActiveTab(
                  ExaminationCategory.URINARY_ANALYSIS
                )}`}
                onClick={() =>
                  handleClickResultTypeBtn(ExaminationCategory.URINARY_ANALYSIS)
                }
              >
                {t("Urinary Analysis")}
              </IcuButton>
            </Flex>
            {/* Hide date picker on changing requirement temporarily */}
            {false ? (
              <CustomDatePicker
                onDateChange={handleDateChange}
                maxDate={dayjs()}
                defaultValue={targetDateByTab[activeTab]!}
              />
            ) : (
              <></>
            )}
          </Flex>

          {timeSeriesDataSource &&
            timeSeriesDataSource.tableData.length > 0 && (
              <div className={styles.timeSeriesExamTable}>
                <TimeSeriesExamTable
                  data={timeSeriesDataSource.tableData}
                  colHeaders={timeSeriesDataSource.colHeaders}
                  onUpdateCell={handleUpdateCell}
                />
              </div>
            )}
        </div>
      </Wrapper>

      <IcuFooter />
      {isLoading && <LoadingOverlay />}
    </>
  );
};

export default TimeSeriesExamResultPage;
