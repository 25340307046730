import { Select, Tag } from "antd";
import { MedicineInDataSource } from "../../../../types/vital-sign/MedicineInEntity.model";

interface MedicineListAreaProps {
  medicineList: MedicineInDataSource[];
  handleSlected: (record: MedicineInDataSource, selected: boolean) => void;
}

const MedicineListArea = ({
  medicineList,
  handleSlected,
}: MedicineListAreaProps) => {
  const options = medicineList.map((medicine) => ({
    label: medicine.name,
    value: medicine.key,
  }));
  const medicineValues = medicineList.map((medicine) => medicine.key);

  const tagRender = ({
    label,
    value,
    closable,
  }: {
    label: React.ReactNode;
    value: string;
    closable: boolean;
  }) => (
    <Tag closable={closable} onClose={() => unSelectedMedicine(Number(value))}>
      {label}
    </Tag>
  );

  const unSelectedMedicine = (key: number) => {
    const record = medicineList.find((medicinine) => medicinine.key === key);
    if (!record) {
      return;
    }
    handleSlected(record, false);
  };
  return (
    <>
      <Select
        mode="multiple"
        value={medicineValues}
        style={{ width: "100%" }}
        open={false}
        placeholder=""
        tagRender={tagRender}
        className="medicine-area-component"
        options={options}
        suffixIcon={null}
      ></Select>
    </>
  );
};

export default MedicineListArea;
