import { useEffect, useRef, useState } from "react";
import styles from "./ExaminationResultPage.module.scss";
import ExaminationCard from "../../components/examination-result/examination-card/ExaminationCard";
import { PatientDetailEntity } from "../../types/common/PatientDetail.model";
import CustomDatePicker from "../../components/common/custom-date-picker/CustomDatePicker";
import { Flex } from "antd";
import ExaminationResultTable from "../../components/examination-result/table/ExaminationResultTable";
import {
  tranformExaminationResultToTableData,
  transformExaminationResultToPdfTableData,
} from "../../utils/examination-result/ExaminationResult.helper";
import { useTranslation } from "react-i18next";
import chemistryIcon1 from "../../assets/icons/chemistry-1.svg";
import chemistryIcon from "../../assets/icons/chemistry.svg";
import bloodIcon from "../../assets/icons/blood.svg";
import "./ExaminationResultPage.scss";
import dayjs, { Dayjs } from "dayjs";
import { useReactToPrint } from "react-to-print";
import IcuHeader from "../../components/common/header/IcuHeader";
import Wrapper from "../../components/common/wrapper/Wrapper";
import IcuFooter from "../../components/common/footer/IcuFooter";
import {
  createExaminationResultOCR,
  getLatestExamResult,
  putExaminationResult,
  updateExaminationResult,
} from "../../services/examination-result/examinationResultService";
import {
  DATE_FORMAT,
  DATE_FORMAT_SEND_TO_SERVER,
} from "../../constants/common/dateFormat.constant";
import { FormatExaminationResultDataOutput } from "../../types/examination-result/FormatExaminationResultDataOutput.model";
import { LoadingOverlay } from "../../components/common/loading/LoadingOverlay";
import {
  addQueryParamToCurrentURL,
  changeFavicon,
  convertUTCtoLocalDateWithNoFormat,
  formatDateBeforeSendToServer,
  generateRandomNumber,
} from "../../utils/helpers";
import { ExaminationCategory } from "../../constants/examination-result/ExaminationCategory.constant";
import { useLocation, useParams } from "react-router-dom";
import { getPatientDetail } from "../../services/patient/patientService";
import { formatPatientDetail } from "../../utils/patient-detail/PatientDetail.helper";
import { DOCUMENT_HEADER } from "../../constants/common/documentHeader.constant";
import { useUploadImage } from "../../hooks/useUploadImage";
import { RcFile } from "antd/es/upload";
import { UPLOAD_IMAGE_STATUS } from "../../constants/common/uploadImageStatus.constant";
import blueBloodGasIcon from "../../assets/icons/blue-blood-gas.svg";
import blueUrinaryIcon from "../../assets/icons/blue-urinary.svg";
import ExaminationResultPdfTable from "../../components/examination-result/table/ExaminationResultPdfTable";
import { ExaminationResultQueryTypeDate } from "../../constants/examination-result/ExaminationResultQueryTypeDate.constant";
import IcuErrorModal from "../../components/common/error-modal/IcuErrorModal";

import { EXAMINATION_RESULT_PARAMS } from "../../constants/common/queryParams.constant";
import UploadImageDateModal from "../../components/common/image-upload/UploadImageDateModal";

const ExaminationResultPage = () => {
  const { t } = useTranslation();

  const examinationResultRef = useRef<HTMLDivElement>(null);

  const [biochemistryResults, setBiochemistryResults] = useState<
    FormatExaminationResultDataOutput | undefined
  >();
  const [bloodCountResults, setBloodCountResults] = useState<
    FormatExaminationResultDataOutput | undefined
  >();
  const [coagulationResults, setCoagulationResults] = useState<
    FormatExaminationResultDataOutput | undefined
  >();
  const [bloodGasResults, setBloodGasResults] = useState<
    FormatExaminationResultDataOutput | undefined
  >();
  const [urinaryResults, setUrinaryResults] = useState<
    FormatExaminationResultDataOutput | undefined
  >();

  const [biochemistrySubTable, setBiochemistrySubTable] =
    useState<(FormatExaminationResultDataOutput | undefined)[]>();
  const [bloodCountSubTable, setBloodCountSubTable] =
    useState<(FormatExaminationResultDataOutput | undefined)[]>();
  const [coagulationSubTable, setCoagulationSubTable] =
    useState<(FormatExaminationResultDataOutput | undefined)[]>();
  const [bloodGasSubTable, setBloodGasSubTable] =
    useState<(FormatExaminationResultDataOutput | undefined)[]>();
  const [urinarySubTable, setUrinarySubTable] =
    useState<(FormatExaminationResultDataOutput | undefined)[]>();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const targetDateQuery = queryParams.get(EXAMINATION_RESULT_PARAMS.targetDate);

  const [targetDate, setTargetDate] = useState(
    dayjs(targetDateQuery).isValid()
      ? dayjs(targetDateQuery)
      : dayjs(
          new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate()
          )
        )
  );

  useEffect(() => {
    addQueryParamToCurrentURL({
      [EXAMINATION_RESULT_PARAMS.targetDate]: dayjs(targetDate).format(
        DATE_FORMAT.YYYYMMDD
      ),
    });
  }, [targetDate]);

  const [isLoading, setIsLoading] = useState(false);

  const [patientInfo, setPatientInfo] = useState<PatientDetailEntity>();
  const [base64String, setBase64String] = useState<string>();
  const [isShowImageDateModal, setShowImageDateModal] = useState(false);

  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const [maxTargetDate, setMaxTargetDate] = useState(
    dayjs(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      )
    )
  );

  const { id: admissionRecordId } = useParams();

  const { contextHolder, handleUploadImageCallback } = useUploadImage();

  const fetchPatientInfo = async () => {
    if (admissionRecordId) {
      const response = await getPatientDetail(Number(admissionRecordId));

      setPatientInfo(formatPatientDetail(response.data));
    }
  };

  const fetchLatestExamResult = async (
    admissionRecordId: number,
    targetDate: string,
    queryTypeDate?: ExaminationResultQueryTypeDate
  ) => {
    setIsLoading(true);
    const response = await getLatestExamResult(
      admissionRecordId,
      targetDate,
      queryTypeDate
    );

    const {
      biochemistryList,
      bloodCountList,
      coagulationList,
      bloodGasList,
      urinaryList,
    } = tranformExaminationResultToTableData(response.data);

    setBiochemistryResults(biochemistryList);
    setBloodCountResults(bloodCountList);
    setCoagulationResults(coagulationList);
    setBloodGasResults(bloodGasList);
    setUrinaryResults(urinaryList);

    const {
      biochemistrySubTableData,
      bloodCountSubTableData,
      coagulationSubTableData,
      bloodGasSubTableData,
      urinarySubTableData,
    } = transformExaminationResultToPdfTableData(response.data);

    setBiochemistrySubTable(biochemistrySubTableData);
    setBloodCountSubTable(bloodCountSubTableData);
    setCoagulationSubTable(coagulationSubTableData);
    setBloodGasSubTable(bloodGasSubTableData);
    setUrinarySubTable(urinarySubTableData);

    setIsLoading(false);

    if (
      response.data.length > 0 &&
      response.data[0].examination_results.length > 0
    ) {
      return convertUTCtoLocalDateWithNoFormat(response.latest_date);
    }

    return dayjs(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      )
    );
  };

  useEffect(() => {
    document.title = t(DOCUMENT_HEADER.examResult.title);
    changeFavicon(DOCUMENT_HEADER.examResult.icon);

    fetchPatientInfo();
    fetchLatestExamResult(
      Number(admissionRecordId),
      formatDateBeforeSendToServer(
        `${targetDate.format(DATE_FORMAT_SEND_TO_SERVER.YYYYMMDD)} 00:00`
      ),
      ExaminationResultQueryTypeDate.BEFORE
    ).then((latestDateWithExamResult) => {
      if (!targetDateQuery) {
        setTargetDate(latestDateWithExamResult);
      }
      setMaxTargetDate(latestDateWithExamResult);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDateChange = (date: string) => {
    fetchLatestExamResult(
      Number(admissionRecordId),
      formatDateBeforeSendToServer(
        `${dayjs(date).format(DATE_FORMAT_SEND_TO_SERVER.YYYYMMDD)} 00:00`,
        DATE_FORMAT_SEND_TO_SERVER.YYYYMMDDHHmmss
      ),
      ExaminationResultQueryTypeDate.TODAY
    );

    setTargetDate(dayjs(date));
  };

  const handleExportPdf = useReactToPrint({
    content: () => examinationResultRef.current,
    bodyClass: "examination-page-pdf",
  });

  const handleUpdateCell = async (
    id: number,
    value: string | null,
    typeId: number
  ) => {
    if (id !== null) {
      await updateExaminationResult(id, value);
    } else {
      await putExaminationResult(
        Number(admissionRecordId),
        typeId,
        value,
        formatDateBeforeSendToServer(
          targetDate.toDate(),
          DATE_FORMAT_SEND_TO_SERVER.YYYYMMDDHHmmss
        )
      );
    }

    await fetchLatestExamResult(
      Number(admissionRecordId),
      formatDateBeforeSendToServer(
        `${targetDate.format(DATE_FORMAT_SEND_TO_SERVER.YYYYMMDD)} 00:00`
      ),
      ExaminationResultQueryTypeDate.TODAY
    );
  };

  const handleUploadImageWithDate = async (date: string) => {
    setIsLoading(true);
    if (base64String && admissionRecordId) {
      const examinationResultOcrResponse = await createExaminationResultOCR(
        admissionRecordId,
        base64String,
        formatDateBeforeSendToServer(
          date,
          DATE_FORMAT_SEND_TO_SERVER.YYYYMMDDHHmmss
        )
      );

      if (typeof examinationResultOcrResponse !== "string") {
        const currentDay = targetDate;
        const latestExamResultResponse = await getLatestExamResult(
          Number(admissionRecordId),
          formatDateBeforeSendToServer(
            `${currentDay.format(DATE_FORMAT_SEND_TO_SERVER.YYYYMMDD)} 00:00`
          ),
          ExaminationResultQueryTypeDate.TODAY
        );

        const {
          biochemistryList,
          bloodCountList,
          coagulationList,
          bloodGasList,
          urinaryList,
        } = tranformExaminationResultToTableData(latestExamResultResponse.data);

        setBiochemistryResults(biochemistryList);
        setBloodCountResults(bloodCountList);
        setCoagulationResults(coagulationList);
        setBloodGasResults(bloodGasList);
        setUrinaryResults(urinaryList);

        const {
          biochemistrySubTableData,
          bloodCountSubTableData,
          coagulationSubTableData,
          bloodGasSubTableData,
          urinarySubTableData,
        } = transformExaminationResultToPdfTableData(
          latestExamResultResponse.data
        );

        setBiochemistrySubTable(biochemistrySubTableData);
        setBloodCountSubTable(bloodCountSubTableData);
        setCoagulationSubTable(coagulationSubTableData);
        setBloodGasSubTable(bloodGasSubTableData);
        setUrinarySubTable(urinarySubTableData);

        handleUploadImageCallback(UPLOAD_IMAGE_STATUS.success);

        setTargetDate(currentDay);

        setMaxTargetDate(
          convertUTCtoLocalDateWithNoFormat(
            latestExamResultResponse.latest_date
          )
        );
      } else {
        handleUploadImageCallback(UPLOAD_IMAGE_STATUS.failed);
      }
      setShowImageDateModal(false);
      setIsLoading(false);
    }
  };

  const handleUploadImage = (file: RcFile) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = async function (event) {
      const base64String = event.target?.result?.toString().split(",")[1];
      setBase64String(base64String);
      setShowImageDateModal(true);
    };

    return false;
  };

  const handleClickPrevDateBtn = async (date: Dayjs) => {
    setIsLoading(true);
    const response = await getLatestExamResult(
      Number(admissionRecordId),
      formatDateBeforeSendToServer(
        `${date.format(DATE_FORMAT_SEND_TO_SERVER.YYYYMMDD)} 00:00`
      ),
      ExaminationResultQueryTypeDate.BEFORE
    );

    const {
      biochemistryList,
      bloodCountList,
      coagulationList,
      bloodGasList,
      urinaryList,
    } = tranformExaminationResultToTableData(response.data);

    if (biochemistryList) {
      setBiochemistryResults(biochemistryList);
    }
    if (bloodCountList) {
      setBloodCountResults(bloodCountList);
    }
    if (coagulationList) {
      setCoagulationResults(coagulationList);
    }
    if (bloodGasList) {
      setBloodGasResults(bloodGasList);
    }
    if (urinaryList) {
      setUrinaryResults(urinaryList);
    }

    const {
      biochemistrySubTableData,
      bloodCountSubTableData,
      coagulationSubTableData,
      bloodGasSubTableData,
      urinarySubTableData,
    } = transformExaminationResultToPdfTableData(response.data);

    if (biochemistrySubTableData.length > 0) {
      setBiochemistrySubTable(biochemistrySubTableData);
    }
    if (bloodCountSubTableData.length > 0) {
      setBloodCountSubTable(bloodCountSubTableData);
    }
    if (coagulationSubTableData.length > 0) {
      setCoagulationSubTable(coagulationSubTableData);
    }
    if (bloodGasSubTableData.length > 0) {
      setBloodGasSubTable(bloodGasSubTableData);
    }
    if (urinarySubTableData.length > 0) {
      setUrinarySubTable(urinarySubTableData);
    }

    setIsLoading(false);

    if (
      response.data.length > 0 &&
      response.data[0].examination_results.length > 0
    ) {
      setTargetDate(
        convertUTCtoLocalDateWithNoFormat(
          response.data[0].examination_results[0].created_at
        )
      );

      return;
    }

    setErrorMessage(t("No data available"));
  };

  const handleClickNextDateBtn = async (date: Dayjs) => {
    setIsLoading(true);
    const response = await getLatestExamResult(
      Number(admissionRecordId),
      formatDateBeforeSendToServer(
        `${date.format(DATE_FORMAT_SEND_TO_SERVER.YYYYMMDD)} 00:00`
      ),
      ExaminationResultQueryTypeDate.AFTER
    );

    const {
      biochemistryList,
      bloodCountList,
      coagulationList,
      bloodGasList,
      urinaryList,
    } = tranformExaminationResultToTableData(response.data);

    if (biochemistryList) {
      setBiochemistryResults(biochemistryList);
    }
    if (bloodCountList) {
      setBloodCountResults(bloodCountList);
    }
    if (coagulationList) {
      setCoagulationResults(coagulationList);
    }
    if (bloodGasList) {
      setBloodGasResults(bloodGasList);
    }
    if (urinaryList) {
      setUrinaryResults(urinaryList);
    }

    const {
      biochemistrySubTableData,
      bloodCountSubTableData,
      coagulationSubTableData,
      bloodGasSubTableData,
      urinarySubTableData,
    } = transformExaminationResultToPdfTableData(response.data);

    if (biochemistrySubTableData.length > 0) {
      setBiochemistrySubTable(biochemistrySubTableData);
    }
    if (bloodCountSubTableData.length > 0) {
      setBloodCountSubTable(bloodCountSubTableData);
    }
    if (coagulationSubTableData.length > 0) {
      setCoagulationSubTable(coagulationSubTableData);
    }
    if (bloodGasSubTableData.length > 0) {
      setBloodGasSubTable(bloodGasSubTableData);
    }
    if (urinarySubTableData.length > 0) {
      setUrinarySubTable(urinarySubTableData);
    }

    setIsLoading(false);

    if (
      response.data.length > 0 &&
      response.data[0].examination_results.length > 0
    ) {
      setTargetDate(
        convertUTCtoLocalDateWithNoFormat(
          response.data[0].examination_results[0].created_at
        )
      );

      return;
    }

    setErrorMessage(t("No data available"));
  };

  const handleCloseErrorModal = () => {
    setErrorMessage(undefined);
  };

  return (
    <>
      {contextHolder}

      <Flex
        vertical
        ref={examinationResultRef}
        className={styles.examResultWrapper}
      >
        <IcuHeader />
        <Wrapper>
          <div className={styles.examinationResultPage}>
            {patientInfo ? (
              <ExaminationCard
                data={patientInfo}
                onClickExportPdf={handleExportPdf}
                onUploadImageCallback={handleUploadImage}
                setIsLoading={setIsLoading}
                targetDate={targetDate}
              />
            ) : (
              <></>
            )}

            <Flex justify="flex-end" className={styles.datePicker}>
              <CustomDatePicker
                onDateChange={handleDateChange}
                maxDate={maxTargetDate}
                defaultValue={targetDate}
                handleClickPrevDateBtn={handleClickPrevDateBtn}
                handleClickNextDateBtn={handleClickNextDateBtn}
              />
            </Flex>

            {biochemistryResults && (
              <div className={`${styles.bioTable} bio-chemistry-screen-table`}>
                <ExaminationResultTable
                  data={biochemistryResults}
                  firstColHeader={t("Biochemistry")}
                  firstColHeaderIcon={chemistryIcon1}
                  onUpdateCell={handleUpdateCell}
                  tableType={ExaminationCategory.BIOCHEMISTRY}
                />
              </div>
            )}
            {bloodCountResults && (
              <div
                className={`${styles.bloodCountTable} blood-count-screen-table`}
              >
                <ExaminationResultTable
                  data={bloodCountResults}
                  firstColHeader={t("Blood count")}
                  firstColHeaderIcon={chemistryIcon}
                  onUpdateCell={handleUpdateCell}
                  tableType={ExaminationCategory.BLOOD_COUNT}
                />
              </div>
            )}
            {coagulationResults && (
              <div
                className={`${styles.coagulationTable} coagulation-screen-table`}
              >
                <ExaminationResultTable
                  data={coagulationResults}
                  firstColHeader={t("Coagulation")}
                  firstColHeaderIcon={bloodIcon}
                  onUpdateCell={handleUpdateCell}
                  tableType={ExaminationCategory.COAGULATION}
                />
              </div>
            )}
            {bloodGasResults && (
              <div className={`${styles.bloodGasTable} blood-gas-screen-table`}>
                <ExaminationResultTable
                  data={bloodGasResults}
                  firstColHeader={t("Blood gas analysis")}
                  firstColHeaderIcon={blueBloodGasIcon}
                  onUpdateCell={handleUpdateCell}
                  tableType={ExaminationCategory.BLOOD_GAS_ANALYSIS}
                  firstColHeaderIconClassName="blood-gas-icon"
                />
              </div>
            )}

            {urinaryResults && (
              <div className={`${styles.urinaryTable} urinary-screen-table`}>
                <ExaminationResultTable
                  data={urinaryResults}
                  firstColHeader={t("Urinary analysis")}
                  firstColHeaderIcon={blueUrinaryIcon}
                  onUpdateCell={handleUpdateCell}
                  tableType={ExaminationCategory.URINARY_ANALYSIS}
                />
              </div>
            )}

            {biochemistrySubTable?.length !== undefined &&
              biochemistrySubTable?.length > 0 &&
              biochemistrySubTable?.map((item) => {
                if (item) {
                  return (
                    <div
                      className={`${styles.bioTable} bio-chemistry-pdf-table`}
                      key={`${generateRandomNumber()}`}
                    >
                      <ExaminationResultPdfTable
                        data={item}
                        firstColHeader={t("Biochemistry")}
                        firstColHeaderIcon={chemistryIcon1}
                      />
                    </div>
                  );
                }

                return <></>;
              })}
            {bloodCountSubTable?.length !== undefined &&
              bloodCountSubTable?.length > 0 &&
              bloodCountSubTable?.map((item) => {
                if (item) {
                  return (
                    <div
                      className={`${styles.bloodCountTable} blood-count-pdf-table`}
                      key={`${generateRandomNumber()}`}
                    >
                      <ExaminationResultPdfTable
                        data={item}
                        firstColHeader={t("Blood count")}
                        firstColHeaderIcon={chemistryIcon}
                      />
                    </div>
                  );
                }

                return <></>;
              })}
            {coagulationSubTable?.length !== undefined &&
              coagulationSubTable?.length > 0 &&
              coagulationSubTable?.map((item) => {
                if (item) {
                  return (
                    <div
                      className={`${styles.coagulationTable} coagulation-pdf-table`}
                      key={`${generateRandomNumber()}`}
                    >
                      <ExaminationResultPdfTable
                        data={item}
                        firstColHeader={t("Coagulation")}
                        firstColHeaderIcon={bloodIcon}
                      />
                    </div>
                  );
                }

                return <></>;
              })}
            {bloodGasSubTable?.length !== undefined &&
              bloodGasSubTable?.length > 0 &&
              bloodGasSubTable?.map((item) => {
                if (item) {
                  return (
                    <div
                      className={`${styles.bloodGasTable} blood-gas-pdf-table`}
                      key={`${generateRandomNumber()}`}
                    >
                      <ExaminationResultPdfTable
                        data={item}
                        firstColHeader={t("Blood gas analysis")}
                        firstColHeaderIcon={blueBloodGasIcon}
                        firstColHeaderIconClassName="blood-gas-icon"
                      />
                    </div>
                  );
                }
                return <></>;
              })}
            {urinarySubTable?.length !== undefined &&
              urinarySubTable?.length > 0 &&
              urinarySubTable?.map((item) => {
                if (item) {
                  return (
                    <div
                      className={`${styles.urinaryTable} urinary-pdf-table`}
                      key={`${generateRandomNumber()}`}
                    >
                      <ExaminationResultPdfTable
                        data={item}
                        firstColHeader={t("Urinary analysis")}
                        firstColHeaderIcon={blueUrinaryIcon}
                      />
                    </div>
                  );
                }
                return <></>;
              })}
            {errorMessage !== undefined && (
              <IcuErrorModal
                content={t(errorMessage)}
                title="Error"
                handleCancel={handleCloseErrorModal}
                isOpen={errorMessage !== undefined}
              />
            )}
          </div>
        </Wrapper>
        <IcuFooter />
        {isShowImageDateModal && (
          <UploadImageDateModal
            handleClose={() => setShowImageDateModal(false)}
            isShow={isShowImageDateModal}
            handleOk={handleUploadImageWithDate}
            showTime={true}
            format={DATE_FORMAT.YYYYMMDDHHmm}
          />
        )}
        {isLoading && <LoadingOverlay />}
      </Flex>
    </>
  );
};

export default ExaminationResultPage;
