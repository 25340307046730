import { Button, Dropdown, MenuProps } from "antd";
import { useState } from "react";
import { DELETE_OPTIONS } from "../../../constants/vital-sign/InOutTable.constant";
import trashImg from "../../../assets/img/trash.png";
interface MenuItem {
  key: number;
  text: string;
  id: string;
}

interface InBalanceDeleteProps {
  inBalanceId: number,
  handleShowDeleteOptionModal: (inBalanceId: number, option: string) => void;
}


const InBalanceDelete = (
  {
    inBalanceId,
    handleShowDeleteOptionModal
  }: InBalanceDeleteProps
) => {
  const menu = DELETE_OPTIONS.map((option) => (
    {
      key: option.key,
      text: option.label,
      id: option.value
    }
  ));

  const [aciveItem, setActiveItem] = useState('');

  const handleClickMenuItem = (item: MenuItem) => {
    setActiveItem(item.id);
    handleShowDeleteOptionModal(inBalanceId, item.id);
  }

  const items: MenuProps['items'] = menu.map((item) => {
    return {
      key: `${inBalanceId}-${item.key}`,
      label: (
        <div
          className={`delete-menu-item ${(item.id === aciveItem) ? 'active' : ''}`}
          onClick={() => {
            handleClickMenuItem(item)
          }}
        >
          {item.text}
        </div>
      )
    }
  })

  return (
    <>
      <Dropdown menu={{ items }} placement="bottomLeft" overlayClassName="inbalance-delete-dropdown-overlay">
        <Button className="medicine-delete" type="primary" danger><img src={trashImg} alt="" /></Button>
      </Dropdown>
    </>
  )
}

export default InBalanceDelete;