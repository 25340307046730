import { DatePicker, Form, Modal } from "antd";
import { useTranslation } from "react-i18next";
import "./MedicineModal.scss";
import { useState } from "react";
import { BALANCE_IN_DELETE_TEMP } from "../../../../constants/vital-sign/InOutTable.constant";
import { formatDateBeforeSendToServer } from "../../../../utils/helpers";
import { DATE_FORMAT_SEND_TO_SERVER } from "../../../../constants/common/dateFormat.constant";

interface TemporaryDeleteModalProps {
  isShow: boolean;
  handleClose: () => void;
  inBalanceId: number;
  handleDeleteInBalance: (option: string, suspendStartTime: string) => void;
}

const TemporaryDeleteModal = ({
  isShow,
  handleClose,
  handleDeleteInBalance,
}: TemporaryDeleteModalProps) => {
  const { t } = useTranslation();
  const [deleteTimeFrom, setDeleteTimeFrom] = useState("");

  const handleOk = () => {
    const suspend_start_time = deleteTimeFrom
      ? formatDateBeforeSendToServer(
          deleteTimeFrom,
          DATE_FORMAT_SEND_TO_SERVER.YYYYMMDDHHmmss
        )
      : "";

    handleDeleteInBalance(BALANCE_IN_DELETE_TEMP, suspend_start_time);
    handleClose();
  };
  return (
    <>
      <Modal
        title={t("Stop drug")}
        open={isShow}
        onCancel={handleClose}
        className="medicine-modal form-medicine-modal"
        okText="Save"
        onOk={handleOk}
        centered
        okButtonProps={{ disabled: !deleteTimeFrom }}
      >
        <Form layout="vertical">
          <Form.Item label={t("Stop at")} name="start_date">
            <DatePicker
              value={deleteTimeFrom}
              format={DATE_FORMAT_SEND_TO_SERVER.YYYYMMDDHH00}
              onChange={(value) => {
                setDeleteTimeFrom(value);
              }}
              placeholder=""
              showTime={true}
              showNow={false}
            />
          </Form.Item>
        </Form>
        <Form layout="vertical"></Form>
      </Modal>
    </>
  );
};

export default TemporaryDeleteModal;
