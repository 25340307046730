import { Form, Input, Modal } from "antd"
import { useTranslation } from "react-i18next"
import './MedicineModal.scss'
import { useState } from "react";
import { createOutBalance } from "../../../../services/in-out-balance/inOutBalanceService";
import { useParams } from "react-router-dom";

interface TemporaryDeleteModalProps {
    isShow: boolean;
    handleClose: () => void;
    handleGetOutBalanceData: () => void
}

const CreateOutBalanceModal = (
    { isShow, handleClose, handleGetOutBalanceData }: TemporaryDeleteModalProps
) => {
    const { t } = useTranslation();
    const [name, setName] = useState('');
    const { id: admissionRecordId } = useParams();

    const handleOk = async () => {
        await createOutBalance(Number(admissionRecordId), name);
        handleGetOutBalanceData();
        handleClose();
    }
    return (
        <>
            <Modal title={t("Add OUT")}
                open={isShow}
                onCancel={handleClose}
                className="medicine-modal form-medicine-modal"
                okText="Save"
                onOk={handleOk}
                centered
                okButtonProps={{ disabled: !name }}
            >
                <Form
                    layout="vertical"
                >
                    <Form.Item
                        name="name"
                        label=""
                    >
                        <Input
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value);
                            }}
                            placeholder=""
                            className="medicine-name-input"
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default CreateOutBalanceModal;