import { AmountPerHr, InTableType, MedicineColumn } from "../../components/vital-sign/in-out-balance/InTable";
import { DATE_FORMAT } from "../../constants/common/dateFormat.constant";
import { BaseResponse } from "../../types/common/BaseResponse.model";
import { InBalanceListResponse, OutBalanceListResponse, OutBalanceTimeResponseEntity } from "../../types/vital-sign/MedicineRequest.model";
import dayjs from "dayjs";
import { convertUTCtoLocalDate } from "../helpers";
import { OutAmountPerHr, OutMedicine, OutTableType } from "../../components/vital-sign/in-out-balance/OutTable";
import { BALANCE_IN_STATUS, IN_OUT_TABLE_HEADER } from "../../constants/vital-sign/InOutTable.constant";
import { BalanceType } from "../../components/vital-sign/in-out-balance/BalanceTable";

// format in balance time data
const formatMedicineTimeOfDataSource = (dataSource: InTableType, inBalance: InBalanceListResponse) => {
  const inBalanceTimes = inBalance.in_balance_times
  inBalanceTimes.forEach((balance_time) => {
    const keyTime = dayjs(convertUTCtoLocalDate(balance_time.time)).format(DATE_FORMAT.HH00);
    dataSource = {
      ...dataSource,
      [keyTime]: {
        id: balance_time.id,
        numerator: balance_time.value,
        denominator: 0,
      }
    }
  });
  return dataSource;
}

// calc cell width form time data
export const calcCellWidth = (amountPerHr: AmountPerHr | OutAmountPerHr)=> {
    const cellString = amountPerHr.numerator + "/" + amountPerHr.denominator;
    return  cellString.length * 8 + 16 * 2;
}

let currentIn: InTableType[] = [];
let currentOut: OutTableType[] = [];

const isStoped = (time: dayjs.Dayjs, suspend_start_time: string, suspend_end_time: string) => {
  return false;
}

// calc denominator from in balance time data
const calcDenominatorDataSource = (dataSource: InTableType, totalInItem: InTableType, inBalance: InBalanceListResponse, targetDate: dayjs.Dayjs) => {
  let denominator = 0;
  let totalDenominator = 0;
  let status = BALANCE_IN_STATUS.active;
  const targetDay = targetDate.format(DATE_FORMAT.YYYY_MM_DD);
  IN_OUT_TABLE_HEADER.forEach((key) => {
    const currentNumerator = Number((dataSource[key] as AmountPerHr)?.numerator ?? 0);
    denominator = denominator + currentNumerator;
    const time = dayjs(`${targetDay} ${key}`);
    const stoped = isStoped(time, inBalance.suspend_start_time, inBalance.suspend_end_time);
    if (dataSource[key]) {
      dataSource = {
        ...dataSource,
        [key]: {
          ...dataSource[key] as AmountPerHr,
          denominator: currentNumerator ?  denominator : 0,
          stoped: stoped
        }
      }
    }else {
      dataSource = {
        ...dataSource,
        [key]: {
          numerator: 0,
          denominator: 0,
          stoped: stoped
        }
      }
    }
    if(stoped) status = BALANCE_IN_STATUS.inActive;
    const totalNumerator = ((totalInItem[key] as AmountPerHr)?.numerator ?? 0) + currentNumerator;
    totalDenominator = totalDenominator + totalNumerator;
    totalInItem = {
      ...totalInItem,
      [key]: {
        numerator: totalNumerator,
        denominator: totalDenominator
      }
    }
  })
  dataSource = {...dataSource, status}
  return { dataSource, totalInItem }
}

// format in balance data
export const formatInBalanceListResponse = (response: BaseResponse<InBalanceListResponse[]>, targetDate: dayjs.Dayjs) => {
  let totalInItem: InTableType = {
    key: response.data.length + 1,
    medicines: {
      isTotal: true,
      name: "IN TOTAL"
    }
  }
  let dataSources: InTableType[] = response.data.map((item, index) => {
    const start_date = item.start_time ? convertUTCtoLocalDate(item.start_time, DATE_FORMAT.YYYY_MM_DD) : '';
    const end_date = item.end_time ? convertUTCtoLocalDate(item.end_time, DATE_FORMAT.YYYY_MM_DD) : '';
    const start_time = item.start_time ? convertUTCtoLocalDate(item.start_time, DATE_FORMAT.HHmm) : '';
    const end_time = item.end_time ? convertUTCtoLocalDate(item.end_time, DATE_FORMAT.HHmm) : '';
    let dataSource: InTableType = {
      key: index + 1,
      status: item.status,
      medicines: {
        in_balance_id: item.id,
        amount: item.amount,
        speed: item.speed,
        frequency: item.frequency,
        unit: item.unit,
        start_date: start_date,
        end_date: end_date,
        start_time: start_time,
        end_time: end_time,
        category: item.category,
        medicines: item.in_balance_medicines.map((medicine) => ({
          id: medicine.id,
          name: medicine.medicine_name,
          dose_unit: medicine.dose_unit,
          dose_amount: medicine.dose_amount,
          code: medicine.medicine_code,
          isTotal: false,
          medicine_id: medicine.medicine_id,
        })),
      } as MedicineColumn
    }
    dataSource = formatMedicineTimeOfDataSource(dataSource,  item);
    
    const denominatorResult = calcDenominatorDataSource(dataSource, totalInItem, item, targetDate);
    dataSource = denominatorResult.dataSource;
    totalInItem = denominatorResult.totalInItem;
    return dataSource;
  });
  currentIn = [
    ...dataSources,
    totalInItem
  ];
  return {
    dataSources:[
      ...dataSources,
      totalInItem
    ],
    balanceDataSource: calcBalanceTable(currentIn, currentOut)
  };
}

// format out balance time data
const formatMedicineTimeOfOutDataSource = (dataSource: OutTableType, outBalanceTimes: OutBalanceTimeResponseEntity[], targetDateUTCString: string, outBalanceId: number) => {
  outBalanceTimes.forEach((balance_time) => {
    const keyTime = dayjs(convertUTCtoLocalDate(balance_time.time)).format(DATE_FORMAT.HHmm);
    dataSource = {
      ...dataSource,
      [keyTime]: {
        id: balance_time.id,
        numerator: balance_time.value,
        denominator: 0
      } as OutAmountPerHr
    }
  });

  return dataSource;
}

// calculates the denominator values for each key in the dataSource and updates the totalOutItem accordingly
export const calcDenominatorOutDataSource = (dataSource: OutTableType, totalOutItem: OutTableType) => {
  let denominator = 0;
  let totalDenominator = 0;
  IN_OUT_TABLE_HEADER.forEach((key) => {
    const currentNumerator = Number((dataSource[key] as OutAmountPerHr)?.numerator ?? 0);
    denominator = denominator + currentNumerator;
    
    if (dataSource[key]) {
      dataSource = {
        ...dataSource,
        [key]: {
          ...dataSource[key] as OutAmountPerHr,
          denominator: currentNumerator ?  denominator : 0
        }
      }
    }
    const totalNumerator = ((totalOutItem[key] as OutAmountPerHr)?.numerator ?? 0) + currentNumerator;
    totalDenominator = totalDenominator + totalNumerator;
    totalOutItem = {
      ...totalOutItem,
      [key]: {
        numerator: totalNumerator,
        denominator: totalDenominator
      }
    }
  })
  return { dataSource, totalOutItem }
}
// format out balance
export const formatOutBalanceListResponse = (response: BaseResponse<OutBalanceListResponse[]>, targetDateUTCString: string) => {
  let totalOutItem: OutTableType = {
    key: response.data.length + 1,
    medicine: {
      isTotal: true,
      name: "OUT TOTAL"
    }
  }
  let dataSources: OutTableType[] = response.data.map((item, index) => {
    let dataSource: OutTableType = {
      key: index + 1,
      medicine: {
        name: item.name,
        id: item.id,
      } as OutMedicine
    }
    dataSource = formatMedicineTimeOfOutDataSource(dataSource, item.out_balance_times, targetDateUTCString, item.id);
    const denominatorResult = calcDenominatorOutDataSource(dataSource, totalOutItem);
    dataSource = denominatorResult.dataSource;
    totalOutItem = denominatorResult.totalOutItem;
    return dataSource;
  });
  currentOut = [
    ...dataSources,
    totalOutItem
  ];
  
  return {
    dataSources: [
      ...dataSources,
      totalOutItem
    ],
    balanceDataSource: calcBalanceTable(currentIn, currentOut)
  };
}

// caculates balance table from in and out
export const calcBalanceTable = (inTableDataSources: InTableType[], outTableDataSource: OutTableType[]) => {
  let balanceDataSource: BalanceType = {
    key: 1,
    balance_title: "IN OUT BALANCE",
  };
  let numerator;
  let denominator;
  const inTableDataTotal = inTableDataSources[inTableDataSources.length - 1] ?? {};
  const outTableDataTotal = outTableDataSource[outTableDataSource.length - 1] ?? {};

  IN_OUT_TABLE_HEADER.forEach((header) => {
    const inNumerator =
      (inTableDataTotal[header] as AmountPerHr)?.numerator;
    const inDenominator =
      (inTableDataTotal[header] as AmountPerHr)?.denominator;

    const outNumerator =
      (outTableDataTotal[header] as OutAmountPerHr)?.numerator;
    const outDenominator =
      (outTableDataTotal[header] as OutAmountPerHr)?.denominator;

      numerator = (inNumerator === undefined && outNumerator === undefined) ? undefined : (inNumerator ?? 0) - (outNumerator ?? 0);
      denominator = (inDenominator === undefined && outDenominator === undefined) ? undefined : (inDenominator ?? 0) - (outDenominator ?? 0);
      balanceDataSource = {
        ...balanceDataSource,
        [header]: {
          numerator: numerator,
          denominator: denominator,
        },
      };
  });
  return {balanceDataSource}
}