import { ConfigProvider, Table } from "antd";
import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import { VitalSignRecordType } from "../../../constants/vital-sign/VitalSignRecordType";
import { PDF_VENTILATOR_TABLE_CLASSNAME } from "../../../constants/vital-sign/VitalSignTable.constant";
import { VitalSignTableRow } from "../../../types/vital-sign/VitalSignTableRow.model";
import { generateRandomNumber } from "../../../utils/helpers";
import "./VentilatorTable.scss";

interface PdfVentilatorTableProps {
  data: VitalSignTableRow[];
  colHeaders: string[];
}

type EditableTableProps = Parameters<typeof Table>[0];
type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

const PdfVentilatorTable = ({ data, colHeaders }: PdfVentilatorTableProps) => {
  const [dataSource, setDataSource] = useState(data);

  useEffect(() => {
    if (!isEqual(data, dataSource)) {
      setDataSource(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const dynamicColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
    id?: string;
    value?: string | null;
    utcDate?: string;
  })[] = colHeaders.map((header) => {
    return {
      align: "center",
      dataIndex: header,
      width: "80px",
      id: `${header}Id`,
      value: `${header}value`,
      utcDate: `${header}utcDate`,
      title: (
        <div>
          <div>{header}</div>
        </div>
      ),
      render(value, record) {
        return !record.typeId ? (
          <div className="ventilator-title-cell"></div>
        ) : (
          <div>{value ?? "-"}</div>
        );
      },
    };
  });

  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
    id?: string;
    value?: string | null;
    utcDate?: string;
  })[] = [
    {
      dataIndex: "name",
      align: "center",
      className: "ventilator-table-first-col",
      width: "108px",
      fixed: "left",
      render: (_, record) => {
        return (
          <div className={!record.typeId ? "ventilator-title-cell" : ""}>
            {record.name}
          </div>
        );
      },
    },
    ...dynamicColumns,
  ];

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => {
        return {
          record,
          editable:
            record.name === VitalSignRecordType.IMAGE ? false : col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          id: col.id ? record[col.id] : undefined,
          typeId: record.typeId,
        };
      },
    };
  });

  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            headerBorderRadius: 0,
          },
        },
      }}
    >
      <Table
        dataSource={dataSource}
        pagination={false}
        bordered
        className={PDF_VENTILATOR_TABLE_CLASSNAME}
        columns={columns as ColumnTypes}
        rowHoverable={false}
        rowKey={(record) => `${record.typeId}-${generateRandomNumber()}`}
        scroll={{
          x: 109,
        }}
      />
    </ConfigProvider>
  );
};

export default PdfVentilatorTable;
