import { InTableType } from "../../components/vital-sign/in-out-balance/InTable";
import { API_CREATE_IN_BALANCE, API_CREATE_OUT_BALANCE, API_CREATE_OUT_BALANCE_TIME, API_DELETE_OUT_BALANCE, API_GET_IN_BALANCE_LIST, API_GET_MEDICINE_LIST, API_GET_OUT_BALANCE_LIST, API_UPDATE_IN_BALANCE, API_UPDATE_IN_BALANCE_CATEGORY, API_UPDATE_IN_BALANCE_MEDICINE, API_UPDATE_IN_BALANCE_STATUS, API_UPDATE_IN_BALANCE_TIME, API_UPDATE_OUT_BALANCE, API_UPDATE_OUT_BALANCE_TIME } from "../../configs/api/endpoints";
import networkAdapter from "../../configs/api/networkAdapter";
import { BaseResponse, MedicineResponse } from "../../types/common/BaseResponse.model";
import { MedicineInEntity } from "../../types/vital-sign/MedicineInEntity.model";
import { CreateInBalanceRequest, CreateOutBalanceTimeRequest, EditInBalanceRequest, InBalanceListResponse, OutBalanceListResponse, UpdateInBalanceStatusRequest, UpdateMedicineEntityRequest } from "../../types/vital-sign/MedicineRequest.model";


export const getInMedicineList = (
  page?: number,
  limit?: number,
  search?: string,
  id_in_balance?: number
): Promise<MedicineResponse<MedicineInEntity[]>> => {
  let request = {};
  if (page) request = { ...request, page };
  if (limit) request = { ...request, limit };
  if (search) request = { ...request, search };
  if (id_in_balance) request = { ...request, id_in_balance };
  return networkAdapter.get(`${API_GET_MEDICINE_LIST}`, request);
};

export const getInBalanceList = (
  admissionRecordId: number,
  date?: string
): Promise<BaseResponse<InBalanceListResponse[]>> => {
  return networkAdapter.get(`${API_GET_IN_BALANCE_LIST}/${admissionRecordId}`, {
    date,
  });
};

export const createInBalance = (
  inBalanceRequest: CreateInBalanceRequest
): Promise<BaseResponse<InTableType>> => {
  return networkAdapter.post(`${API_CREATE_IN_BALANCE}`, inBalanceRequest);
};

export const updateInBalance = (
  inBalanceId: number,
  inBalanceRequest: EditInBalanceRequest
): Promise<BaseResponse<InTableType>> => {
  return networkAdapter.patch(`${API_UPDATE_IN_BALANCE}/${inBalanceId}`, inBalanceRequest);
};

export const updateInBalanceCategory = (
  id_in_balance: number,
  category: string
): Promise<BaseResponse<InTableType>> => {
  return networkAdapter.post(`${API_UPDATE_IN_BALANCE_CATEGORY}`, { id_in_balance, category });
};

export const updateInBalanceStatus = (
  inBalanceStatusRequest: UpdateInBalanceStatusRequest
): Promise<BaseResponse<InTableType[]>> => {
  let request: UpdateInBalanceStatusRequest = {id_in_balance: inBalanceStatusRequest.id_in_balance, status: inBalanceStatusRequest.status};
    if(inBalanceStatusRequest.suspend_start_time) request = {...request, suspend_start_time: inBalanceStatusRequest.suspend_start_time}
    if(inBalanceStatusRequest.suspend_end_time) request = {...request, suspend_end_time: inBalanceStatusRequest.suspend_end_time}
    return networkAdapter.post(`${API_UPDATE_IN_BALANCE_STATUS}`, request);
};

export const updateInBalanceMedicine = (
  updateInBalanceMedicineRequest: UpdateMedicineEntityRequest
): Promise<BaseResponse<InTableType[]>> => {
  return networkAdapter.post(`${API_UPDATE_IN_BALANCE_MEDICINE}`, updateInBalanceMedicineRequest);
};

export const updateInBalanceTime = (
  inBalanceTimeId: number,
  value: number
): Promise<BaseResponse<[]>> => {
  return networkAdapter.patch(`${API_UPDATE_IN_BALANCE_TIME}/${inBalanceTimeId}`, { value });
};

export const getOutBalanceList = (
  admissionRecordId: number,
  date?: string
): Promise<BaseResponse<OutBalanceListResponse[]>> => {
  return networkAdapter.get(`${API_GET_OUT_BALANCE_LIST}/${admissionRecordId}`, {
    date,
  });
};

export const createOutBalance = (
  admissionRecordId: number,
  name: string
): Promise<BaseResponse<OutBalanceListResponse>> => {
  return networkAdapter.post(`${API_CREATE_OUT_BALANCE}`, { id_admission_record: admissionRecordId, name });
};

export const updateOutBalance = (
  id_out_balance: number,
  name: string
): Promise<BaseResponse<OutBalanceListResponse>> => {
  return networkAdapter.patch(`${API_UPDATE_OUT_BALANCE}`, { id_out_balance, name });
};

export const deleteOutBalance = (
  id_out_balance: number
): Promise<BaseResponse<OutBalanceListResponse>> => {
  return networkAdapter.delete(`${API_DELETE_OUT_BALANCE}/${ id_out_balance }`);
};
export const createOutBalanceTimes = (
  createOutBalanceTimeRequest: CreateOutBalanceTimeRequest
): Promise<BaseResponse<OutBalanceListResponse[]>> => {
  return networkAdapter.post(`${API_CREATE_OUT_BALANCE_TIME}`, createOutBalanceTimeRequest);
};

export const updateOutBalanceTimes = (
  id_out_balance_times: number,
  value: number
): Promise<BaseResponse<OutBalanceListResponse[]>> => {
  return networkAdapter.patch(`${API_UPDATE_OUT_BALANCE_TIME}`, {
    id_out_balance_times,
    value
  });
};