export const DEFAULT_GRAPH_CONTAINER_STYLE = {
  height: "585px",
  paddingRight: "0px",
};

export const GRAPH_CONTAINER_STYLE_WITH_SCROLL = {
  height: "590px",
  paddingRight: "8px",
};

export const MAX_LABEL_WITH_NO_SCROLL_BY_SCREEN_WIDTH: { [x: string]: number } =
  {
    screenWidth1152: 11,
    screenWidth1280: 12,
    screenWidth1360: 13,
    screenWidth1366: 13,
    screenWidth1388: 14,
    screenWidth1390: 14,
    screenWidth1391: 14,
    screenWidth1400: 14,
    screenWidth1402: 14,
    screenWidth1410: 14,
    screenWidth1440: 14,
    screenWidth1536: 15,
    screenWidth1543: 15,
    screenWidth1544: 15,
    screenWidth1545: 15,
    screenWidth1546: 15,
    screenWidth1580: 16,
    screenWidth1600: 17,
    screenWidth1680: 17,
    screenWidth1872: 19,
    screenWidth1920: 20,
  };

export const DEFAULT_GRAPH_CONTENT_WIDTH = "1245px";

export const HORIZONTAL_SEGMENT_LINE_ID = "horizontalSegmentLine";
export const HORIZONTAL_SEGMENT_LINE_INITIAL_Y_COORDINATES = 48;
export const HORIZONTAL_SEGMENT_LINE_SPACING = 42;
export const HORIZONTAL_SEGMENT_SPACE_NUMBER_BETWEEN = 11;
export const X_SCALE_TYPE = "category";


export enum VITAL_SIGN_NAMES {
  all = "ALL"
}