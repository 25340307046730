import { Button, Modal } from "antd";
import pencil from "../../../assets/img/pencil.png";
import "./OcrImageModal.scss";
import { createProgressNote } from "../../../services/progress-note/progressNoteService";
import { useNavigate } from "react-router-dom";
import { TabFilterPatientDetail } from "../../../constants/patient-detail/TabFilterPersonalDetail";
import { useCreateProgress } from "../../../hooks/useCreateProgress";
import { CREATE_PROGRESS_STATUS } from "../../../constants/common/createProgressStatus.constant";
import { useRef, useState } from "react";
import { OCR_IMAGE_MODES } from "../../../constants/vital-sign/OcrImageMode.constant";
import {
  PATIENT_DETAIL_PARAMS,
  PROGRESS_NOTE_PARAMS,
} from "../../../constants/common/queryParams.constant";
import { convertUTCtoLocalDate } from "../../../utils/helpers";
import { DATE_FORMAT } from "../../../constants/common/dateFormat.constant";

interface OcrImageModalProps {
  title?: string;
  isShow: boolean;
  handleCloseOcrImageModal: () => void;
  ocrImageUrl: string;
  idAdmissionRecord: number;
  ocrImageDate: string;
  setIsLoading: (isLoading: boolean) => void;
  ocrImageMode: string;
}
const OcrImageModal = ({
  title,
  isShow,
  handleCloseOcrImageModal,
  ocrImageUrl,
  idAdmissionRecord,
  ocrImageDate,
  setIsLoading,
  ocrImageMode,
}: OcrImageModalProps) => {
  const navigate = useNavigate();
  const imgRef = useRef<HTMLImageElement | null>(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const { contextHolder, handleCreateProgressCallback } = useCreateProgress();

  const handleImageLoad = () => {
    setIsDisabled(false);
  };

  const handleCreateProgressNote = async (base64String: string) => {
    const response = await createProgressNote({
      id_admission_record: idAdmissionRecord,
      assessment: '<img src="' + base64String + '"/>',
      created_at: ocrImageDate,
    });

    const progressNoteFilterDate = convertUTCtoLocalDate(
      ocrImageDate,
      DATE_FORMAT.YYYYMMDD
    );
    if (response.data?.id) {
      navigate(
        `/patient-detail/${idAdmissionRecord}?${PATIENT_DETAIL_PARAMS.activeTab}=${TabFilterPatientDetail.PROGRESS_NOTE}&${PROGRESS_NOTE_PARAMS.progressNodeId}=${response?.data?.id}&${PROGRESS_NOTE_PARAMS.progressNoteFilterDate}=${progressNoteFilterDate}`
      );
    } else {
      handleCreateProgressCallback(CREATE_PROGRESS_STATUS.failed, response);
      console.error("Failed to create progress");
    }
    setIsLoading(false);
  };

  const handleBlobCanvas = (blob: Blob | null) => {
    if (blob) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        handleCreateProgressNote(String(reader.result));
      };
      reader.readAsDataURL(blob);
    } else {
      setIsLoading(false);
      console.error("Failed to convert canvas to Blob");
    }
  };

  const handleSaveImageProgressNote = async () => {
    setIsLoading(true);
    const img = imgRef.current;
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (img?.complete && img?.naturalHeight !== 0 && ctx) {
      canvas.width = img.naturalWidth;
      canvas.height = img.naturalHeight;

      ctx.drawImage(img, 0, 0);

      canvas.toBlob(
        (blob: Blob | null) => handleBlobCanvas(blob),
        "image/jpeg"
      );
    } else {
      console.error("Image is not loaded or invalid!");
    }
  };

  const generateModalFooter = () => {
    return (
      <Button
        className="pencil-btn"
        type="primary"
        onClick={handleSaveImageProgressNote}
        disabled={isDisabled}
      >
        <img src={pencil} />
      </Button>
    );
  };

  return (
    <>
      {contextHolder}
      <Modal
        title={title}
        open={isShow}
        onCancel={handleCloseOcrImageModal}
        footer={
          ocrImageMode === OCR_IMAGE_MODES.vitalSign
            ? generateModalFooter()
            : null
        }
        className="ocr-image-modal"
      >
        <img
          ref={imgRef}
          src={ocrImageUrl}
          crossOrigin="anonymous"
          onLoad={handleImageLoad}
        />
      </Modal>
    </>
  );
};
export default OcrImageModal;
