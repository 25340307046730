import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { LOGIN_PATH } from "../../../configs/routerPath";
import { checkLogin } from "../../../utils/helpers";
import { LOCAL_STORAGE_KEY } from "../../../constants/common/localStorageKey.constant";

const PrivateRoute = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const search = location.search;

  const fullPath = `${pathname}${search}`;
  if (checkLogin()) {
    return <Outlet />;
  } else {
    localStorage.setItem(LOCAL_STORAGE_KEY.afterLoginUrl, fullPath);
    return <Navigate to={LOGIN_PATH} />;
  }
};

export default PrivateRoute;
