import { Modal } from "antd"
import { useTranslation } from "react-i18next"
import './MedicineModal.scss'
import { BALANCE_IN_DELETE_COMP } from "../../../../constants/vital-sign/InOutTable.constant";

interface TemporaryDeleteModalProps {
    isShow: boolean;
    handleClose: () => void;
    inBalanceId: number;
    handleDeleteInBalance: (option: string, suspendTime: string) => void
}

interface Action {
    key: number,
    text: string,
    id: string,
    active: boolean
}

const CompletelyDeleteModal = (
    { isShow, handleClose, inBalanceId, handleDeleteInBalance }: TemporaryDeleteModalProps
) => {
    const { t } = useTranslation();

    const handleOk = () => {
        handleDeleteInBalance(BALANCE_IN_DELETE_COMP, "");
        handleClose();
    }
    return (
        <>
            <Modal title={t("Delete confirmation")}
                open={isShow}
                onCancel={handleClose}
                className="medicine-modal form-medicine-modal"
                okText="Save"
                onOk={handleOk}
                centered
            >
                <div style={{ textAlign: 'center', color: "#CC0000" }}>
                    {t("Delete this drug will also delete all of it's records.")}
                    <br />
                    {t("Are you sure?")}
                </div>
            </Modal>
        </>
    )
}

export default CompletelyDeleteModal;