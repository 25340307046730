import { useEffect, useState } from "react";
import { ConfigProvider, Table } from "antd";
import { VentilatorEditableCell } from "./editable-cell/VentilatorEditableCell";
import "./VentilatorTable.scss";
import { VitalSignTableRow } from "../../../types/vital-sign/VitalSignTableRow.model";
import { isEqual } from "lodash";
import { VENTILATOR_TABLE_CLASSNAME } from "../../../constants/vital-sign/VitalSignTable.constant";
import { generateRandomNumber } from "../../../utils/helpers";
import { VitalSignRecordType } from "../../../constants/vital-sign/VitalSignRecordType";

interface VentilatorTableProps {
  data: VitalSignTableRow[];
  colHeaders: string[];
  onUpdateCell?: (
    id: number,
    typeId: number,
    value: string | null,
    createdAt: string | number
  ) => void;
  showOcrImage?: (imageName: string, imageDate?: string) => void;
}

type EditableTableProps = Parameters<typeof Table>[0];
type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

const VentilatorTable = ({
  data,
  colHeaders,
  onUpdateCell,
  showOcrImage,
}: VentilatorTableProps) => {
  const [dataSource, setDataSource] = useState(data);

  useEffect(() => {
    if (!isEqual(data, dataSource)) {
      setDataSource(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const dynamicColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
    id?: string;
    value?: string | null;
    utcDate?: string;
  })[] = colHeaders.map((header) => {
    return {
      align: "center",
      dataIndex: header,
      editable: true,
      width: "80px",
      id: `${header}Id`,
      value: `${header}value`,
      utcDate: `${header}utcDate`,
      title: (
        <div>
          <div>{header}</div>
        </div>
      ),
      render(value, record) {
        return !record.typeId ? (
          <div className="ventilator-title-cell"></div>
        ) : (
          <div>{value ?? "-"}</div>
        );
      },
    };
  });

  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
    id?: string;
    value?: string | null;
    utcDate?: string;
  })[] = [
    {
      dataIndex: "name",
      align: "center",
      className: "ventilator-table-first-col",
      width: "108px",
      fixed: "left",
      render: (_, record) => {
        return (
          <div className={!record.typeId ? "ventilator-title-cell" : ""}>
            {record.name}
          </div>
        );
      },
    },
    ...dynamicColumns,
  ];

  const handleUpdateData = (
    id: number,
    typeId: number,
    value: string | null,
    newRowData: {
      [key: string]: string | number;
    },
    createdAt: string | number
  ) => {
    const updatedDataSource = data.map((item) => {
      if (item.id === newRowData.id) {
        return newRowData;
      }

      return item;
    });

    setDataSource(updatedDataSource);

    onUpdateCell?.(id, typeId, value, createdAt);
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => {
        return {
          record,
          editable:
            record.name === VitalSignRecordType.IMAGE ? false : col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          id: col.id ? record[col.id] : undefined,
          typeId: record.typeId,
          handleUpdateData,
          onClick: () => {
            if (
              record.name === VitalSignRecordType.IMAGE &&
              showOcrImage &&
              col.value &&
              col.utcDate
            )
              showOcrImage(record[col.value], record[col.utcDate]);
          },
        };
      },
    };
  });

  const components = {
    body: {
      cell: VentilatorEditableCell,
    },
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            headerBorderRadius: 0,
          },
        },
      }}
    >
      <Table
        dataSource={dataSource}
        pagination={false}
        bordered
        className={VENTILATOR_TABLE_CLASSNAME}
        columns={columns as ColumnTypes}
        components={components}
        rowHoverable={false}
        rowKey={(record) => `${record.typeId}-${generateRandomNumber()}`}
        scroll={{
          x: 109,
        }}
      />
    </ConfigProvider>
  );
};

export default VentilatorTable;
