import { Button } from "antd";
interface MenuItem {
  key: number;
  text: string;
  id: string;
}

interface MedicineAddProps {
  handleOpenAdd: () => void;
}

const MedicineAdd = ({ handleOpenAdd }: MedicineAddProps) => {
  return (
    <>
      <Button className="medicine-dropdown-menu" onClick={handleOpenAdd}>
        {" "}
        <span className="medicine-add-icon">+</span>Add
      </Button>
    </>
  );
};

export default MedicineAdd;
