import InTable, { InTableType, MedicineColumn } from "./InTable";
import "./InOutBalance.scss";
import OutTable, { OutMedicine, OutTableType } from "./OutTable";
import BalanceTable, { BalanceType } from "./BalanceTable";
import { SetStateAction, useCallback, useEffect, useState } from "react";
import SelectMedicineInModal from "./medicine/SelectMedicineModal";
import {
  MedicineInDataSource,
  MedicineInEntity,
} from "../../../types/vital-sign/MedicineInEntity.model";
import {
  createInBalance,
  getInBalanceList,
  getInMedicineList,
  getOutBalanceList,
  updateInBalance,
} from "../../../services/in-out-balance/inOutBalanceService";
import { MedicineResponse } from "../../../types/common/BaseResponse.model";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { DATE_FORMAT_SEND_TO_SERVER } from "../../../constants/common/dateFormat.constant";
import {
  formatDateBeforeSendToServer,
  generateRandomNumber,
} from "../../../utils/helpers";
import {
  calcBalanceTable,
  formatInBalanceListResponse,
  formatOutBalanceListResponse,
} from "../../../utils/in-out-balance/InOutBalance.helper";
import CreateOutBalanceModal from "./medicine/CreateOutBalanceModal";
import { IN_OUT_TABLE_HEADER } from "../../../constants/vital-sign/InOutTable.constant";
import {
  CreateInBalanceRequest,
  EditInBalanceRequest,
  MedicineEntityRequest,
} from "../../../types/vital-sign/MedicineRequest.model";
import { transformPdfSubHeaderColumn } from "../../../utils/vital-sign/VitalSign.helper";
import PdfInTable from "./PdfInTable";
import PdfOutTable from "./PdfOutTable";
import PdfBalanceTable from "./PdfBalanceTable";
interface InOutBalanceSectionProps {
  setIsLoading: React.Dispatch<SetStateAction<boolean>>;
  targetDate: dayjs.Dayjs;
}

const initInTableDataSource: InTableType[] = [
  {
    key: 1,
    medicines: {
      medicines: [],
    },
  },
  {
    key: 2,
    id: 2,
    medicines: {
      isTotal: true,
      name: "",
    },
  },
];

const initOutTableDataSource: OutTableType[] = [
  {
    key: 1,
    id: 2,
    medicine: {} as OutMedicine,
  },
  {
    key: 2,
    id: 2,
    medicine: {
      isTotal: true,
      name: "",
    },
  },
];

const initBalanceTable = [
  {
    key: 1,
    balance_title: "IN OUT BALANCE",
  },
];

let initColumnsWidth = {};
IN_OUT_TABLE_HEADER.forEach((header) => {
  initColumnsWidth = {
    ...initColumnsWidth,
    [header]: 91,
  };
});

const InOutBalanceSection = ({
  setIsLoading,
  targetDate,
}: InOutBalanceSectionProps) => {
  const [isShowSelectMedicineIn, setShowSelectMedicineIn] = useState(false);
  const [selectedMedicineInKeys, setSelectedMedicineInKeys] = useState<
    React.Key[]
  >([]);
  const [selectedMedicineRows, setSelectedMedicineRows] = useState<
    MedicineInDataSource[]
  >([]);
  const [medicineDataResponse, setMedicineDataResponse] =
    useState<MedicineResponse<MedicineInEntity[]>>();
  const [isCopy, setIsCopy] = useState(false);

  const [inTableDataSource, setIntableDataSource] = useState<InTableType[]>(
    initInTableDataSource
  );
  const [outTableDataSource, setOutTableDataSource] = useState<OutTableType[]>(
    initOutTableDataSource
  );
  const [balanceTableDataSource, setBalanceTableDataSource] =
    useState<BalanceType[]>(initBalanceTable);
  const [isShowEditModal, setShowEditModal] = useState(false);
  const [currentInBalance, setCurrentInBalance] = useState<MedicineColumn>();

  const [editInBalanceId, setEditInBalanceId] = useState(0);

  const [isShowCreateOutBalance, setShowCreateOutBalance] = useState(false);

  const handleCloseMedicineInModal = () => {
    setShowSelectMedicineIn(false);
  };

  const handleOpenMedicineInModal = () => {
    setShowSelectMedicineIn(true);
  };

  const handleOpenCreateOutBalanceModal = () => {
    setShowCreateOutBalance(true);
  };

  // Get list medicine data master
  const handleFetchMedicineList = useCallback(
    async (
      page?: number,
      limit?: number,
      search?: string,
      id_in_balance?: number
    ) => {
      setIsLoading(true);
      const response = await getInMedicineList(
        page,
        limit,
        search,
        id_in_balance
      );
      setMedicineDataResponse(response);
      setIsLoading(false);
    },
    []
  );

  const { id: admissionRecordId } = useParams();
  // Get In balance data list
  const handleGetInBalanceData = useCallback(async () => {
    const targetDateUTCString = formatDateBeforeSendToServer(
      `${targetDate.format(DATE_FORMAT_SEND_TO_SERVER.YYYYMMDD)}`,
      DATE_FORMAT_SEND_TO_SERVER.YYYYMMDDHHmmss
    );
    if (admissionRecordId) {
      const response = await getInBalanceList(
        Number(admissionRecordId),
        targetDateUTCString
      );
      if (response.data.length) {
        let {
          dataSources,
        }: {
          dataSources: InTableType[];
        } = formatInBalanceListResponse(response, targetDate);
        setIntableDataSource(dataSources);
      } else {
        setIntableDataSource(initInTableDataSource);
      }
    }
  }, [admissionRecordId, targetDate]);

  // Get Out balance data
  const handleGetOutBalanceData = useCallback(async () => {
    const targetDateUTCString = formatDateBeforeSendToServer(
      `${targetDate.format(DATE_FORMAT_SEND_TO_SERVER.YYYYMMDD)}`,
      DATE_FORMAT_SEND_TO_SERVER.YYYYMMDDHHmmss
    );
    if (admissionRecordId) {
      const response = await getOutBalanceList(
        Number(admissionRecordId),
        targetDateUTCString
      );
      if (response.data.length) {
        let {
          dataSources,
        }: {
          dataSources: OutTableType[];
        } = formatOutBalanceListResponse(response, targetDateUTCString);
        setOutTableDataSource(dataSources);
      } else {
        setOutTableDataSource(initOutTableDataSource);
      }
    }
  }, [admissionRecordId, targetDate]);

  // create new in balance
  const insertInDataTable = async (): Promise<number> => {
    let medicineInCreateRequest: CreateInBalanceRequest = {
      id_admission_record: Number(admissionRecordId),
      medicines: [],
    };
    const medicines: MedicineEntityRequest[] = [];
    medicineInCreateRequest = {
      ...medicineInCreateRequest,
      medicines: selectedMedicineInKeys.map((key) => ({
        id: Number(key),
      })),
    };
    const response = await createInBalance(medicineInCreateRequest);
    return Number(response?.data?.id);
  };

  // create in balance time data
  const createInBalanceTime = async (
    inBalanceId: number,
    medicineInRequest: EditInBalanceRequest
  ) => {
    const response = await updateInBalance(inBalanceId, medicineInRequest);
    return response?.data?.id ?? 0;
  };

  // create new in balance by copy exist balance
  const handleCreateCopyInBalance = async (
    medicineInRequest: EditInBalanceRequest
  ) => {
    const inBalanceId =
      editInBalanceId > 0 ? editInBalanceId : await insertInDataTable();
    if (inBalanceId) {
      setEditInBalanceId(inBalanceId);
      const id = await createInBalanceTime(inBalanceId, medicineInRequest);
      if (id) {
        setEditInBalanceId(0);
        setIsCopy(false);
        setShowEditModal(false);
        handleGetInBalanceData();
      }
    }
  };

  // create new in balance
  const handleInsertInDataTable = async () => {
    if (admissionRecordId) {
      const inBalanceId = await insertInDataTable();
      if (inBalanceId) {
        setSelectedMedicineInKeys([]);
        handleGetInBalanceData();
      }
    }
  };

  useEffect(() => {
    const fetchInData = async () => {
      await handleGetInBalanceData();
      await handleGetOutBalanceData();
    };
    fetchInData();
  }, [targetDate, handleGetInBalanceData, handleGetOutBalanceData]);

  const handleCalcBalanceData = () => {
    const { balanceDataSource } = calcBalanceTable(
      inTableDataSource,
      outTableDataSource
    );
    setBalanceTableDataSource([balanceDataSource]);
  };

  useEffect(() => {
    handleCalcBalanceData();
  }, [inTableDataSource, outTableDataSource]);

  const pdfSubColHeaderArray = transformPdfSubHeaderColumn(IN_OUT_TABLE_HEADER);

  return (
    <>
      <div className={"inOutBalanceComponent"}>
        {inTableDataSource.length > 0 && (
          <InTable
            handleOpenMedicineInModal={handleOpenMedicineInModal}
            inTableDataSource={inTableDataSource}
            setIsLoading={setIsLoading}
            handleGetInBalanceData={handleGetInBalanceData}
            setSelectedMedicineInKeys={setSelectedMedicineInKeys}
            isShowEditModal={isShowEditModal}
            setShowEditModal={setShowEditModal}
            setIsCopy={setIsCopy}
            isCopy={isCopy}
            handleCreateCopyInBalance={handleCreateCopyInBalance}
            currentInBalance={currentInBalance}
            setCurrentInBalance={setCurrentInBalance}
            targetDate={targetDate}
            selectedRows={selectedMedicineRows}
            setSelectedRows={setSelectedMedicineRows}
          />
        )}
      </div>

      <div className={"pdf-in-out-balance-component"}>
        {pdfSubColHeaderArray.map((item) => (
          <PdfInTable
            inTableDataSource={inTableDataSource}
            colHeaders={item}
            key={`${generateRandomNumber()}`}
          />
        ))}
      </div>

      <br></br>
      <br></br>
      <div className={"inOutBalanceComponent"}>
        {outTableDataSource.length > 0 && (
          <OutTable
            dataSources={outTableDataSource}
            targetDate={targetDate}
            handleGetOutBalanceData={handleGetOutBalanceData}
            handleOpenCreateOutBalanceModal={handleOpenCreateOutBalanceModal}
          />
        )}
      </div>

      <div className={"pdf-in-out-balance-component"}>
        {pdfSubColHeaderArray.map((item) => (
          <PdfOutTable
            dataSources={outTableDataSource}
            colHeaders={item}
            key={`${generateRandomNumber()}`}
          />
        ))}
      </div>
      <br></br>
      <br></br>
      <div className={"inOutBalanceComponent"}>
        <BalanceTable dataSource={balanceTableDataSource} />
      </div>

      <div className={"pdf-in-out-balance-component"}>
        {pdfSubColHeaderArray.map((item) => (
          <PdfBalanceTable
            dataSource={balanceTableDataSource}
            colHeaders={item}
            key={`${generateRandomNumber()}`}
          />
        ))}
      </div>

      {isShowSelectMedicineIn && (
        <SelectMedicineInModal
          isShow={isShowSelectMedicineIn}
          handleClose={handleCloseMedicineInModal}
          handleFetchMedicineList={handleFetchMedicineList}
          medicineDataResponse={medicineDataResponse}
          handleInsertInDataTable={handleInsertInDataTable}
          selectedKeys={selectedMedicineInKeys}
          selectedRows={selectedMedicineRows}
          setSelectedRows={setSelectedMedicineRows}
          isCopy={isCopy}
          setSelectedMedicineInKeys={setSelectedMedicineInKeys}
          setShowEditModal={setShowEditModal}
          currentInBalance={currentInBalance}
        />
      )}

      {isShowCreateOutBalance && (
        <CreateOutBalanceModal
          handleClose={() => {
            setShowCreateOutBalance(false);
          }}
          isShow={isShowCreateOutBalance}
          handleGetOutBalanceData={handleGetOutBalanceData}
        />
      )}
    </>
  );
};

export default InOutBalanceSection;
