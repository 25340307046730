export enum PATIENT_DETAIL_PARAMS {
  activeTab = "activeTab"
}

export enum PROGRESS_NOTE_PARAMS {
  progressNodeId = "progressNodeId",
  progressNoteFilterDate="progressNoteFilterDate"
}

export enum EXAMINATION_RESULT_PARAMS {
  targetDate = "targetDate"
}

export enum VITAL_SIGN_PARAMS {
  targetDate = "targetDate"
}

export enum PATIENT_LIST_PARAMS {
  tabData = "tabData"
}