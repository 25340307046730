import {
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  TimePicker,
} from "antd";
import { useTranslation } from "react-i18next";
import { SetStateAction, useState } from "react";
import "./MedicineModal.scss";
import {
  MEDICINE_FREQUENCY_OPTIONS,
  MEDICINE_UNIT,
} from "../../../../constants/vital-sign/Medicine.constant";
import { MedicineColumn } from "../InTable";
import { EditInBalanceRequest } from "../../../../types/vital-sign/MedicineRequest.model";
import { updateInBalance } from "../../../../services/in-out-balance/inOutBalanceService";
import dayjs from "dayjs";
import {
  DATE_FORMAT,
  DATE_FORMAT_SEND_TO_SERVER,
} from "../../../../constants/common/dateFormat.constant";
interface EditMedicineModalProps {
  isShow: boolean;
  handleClose: () => void;
  currentInBalance: MedicineColumn | undefined;
  setIsLoading: React.Dispatch<SetStateAction<boolean>>;
  handleGetInBalanceData: () => void;
  isCopy: boolean;
  handleCreateCopyInBalance: (medicineInRequest: EditInBalanceRequest) => void;
}

interface MedicineInData {
  start_time: string;
  end_time: string;
  speed: number | string;
  amount: number | string;
  frequency: number | string;
  start_date: string;
  end_date: string;
  [key: string]: string | number;
}

const EditMedicineModal = ({
  isShow,
  handleClose,
  currentInBalance,
  setIsLoading,
  handleGetInBalanceData,
  isCopy,
  handleCreateCopyInBalance,
}: EditMedicineModalProps) => {
  const { t } = useTranslation();
  const speed = currentInBalance?.speed ?? "";
  const amount = currentInBalance?.amount ?? "";
  const frequency =
    currentInBalance?.frequency ?? MEDICINE_FREQUENCY_OPTIONS[0].value;
  const initMedicineInData: MedicineInData = isCopy
    ? {
        start_time: "",
        end_time: "",
        speed: speed,
        amount: amount,
        frequency: frequency,
        start_date: "",
        end_date: "",
      }
    : {
        start_time: currentInBalance?.start_time ?? "",
        end_time: currentInBalance?.end_time ?? "",
        speed: speed,
        amount: amount,
        frequency: frequency,
        start_date: currentInBalance?.start_date ?? "",
        end_date: currentInBalance?.end_date ?? "",
      };
  const [medicineInData, setMedicineInData] =
    useState<MedicineInData>(initMedicineInData);

  const handleChange = (field: string, value: string | number) => {
    setMedicineInData({
      ...medicineInData,
      [field]: value,
    });
  };

  const handleOk = async () => {
    if (isCopy) {
      setIsLoading(true);
      let medicineInRequest: EditInBalanceRequest = {
        start_time: dayjs(
          `${dayjs(medicineInData.start_date).format(
            DATE_FORMAT_SEND_TO_SERVER.YYYYMMDD
          )} ${medicineInData.start_time}:00`
        ).format(DATE_FORMAT_SEND_TO_SERVER.YYYYMMDDHHmmssz),
        end_time: medicineInData.end_date
          ? dayjs(
              `${dayjs(medicineInData.end_date).format(
                DATE_FORMAT_SEND_TO_SERVER.YYYYMMDD
              )} ${medicineInData.end_time}:00`
            ).format(DATE_FORMAT_SEND_TO_SERVER.YYYYMMDDHHmmssz)
          : null,
        speed: Number(medicineInData.speed),
        unit: MEDICINE_UNIT.AMOUNT,
      };
      if (medicineInData.amount)
        medicineInRequest = {
          ...medicineInRequest,
          frequency: Number(medicineInData.frequency),
          amount: Number(medicineInData.amount),
        };
      handleCreateCopyInBalance(medicineInRequest);
      setIsLoading(false);
    } else {
      handleEditMedicine();
    }
  };

  const handleEditMedicine = async () => {
    setIsLoading(true);
    let medicineInRequest: EditInBalanceRequest = {
      start_time: dayjs(
        `${dayjs(medicineInData.start_date).format(
          DATE_FORMAT_SEND_TO_SERVER.YYYYMMDD
        )} ${medicineInData.start_time}:00`
      ).format(DATE_FORMAT_SEND_TO_SERVER.YYYYMMDDHHmmssz),
      end_time: medicineInData.end_date
        ? dayjs(
            `${dayjs(medicineInData.end_date).format(
              DATE_FORMAT_SEND_TO_SERVER.YYYYMMDD
            )} ${medicineInData.end_time}:00`
          ).format(DATE_FORMAT_SEND_TO_SERVER.YYYYMMDDHHmmssz)
        : null,
      speed: Number(medicineInData.speed),
      unit: MEDICINE_UNIT.AMOUNT,
    };

    if (medicineInData.amount)
      medicineInRequest = {
        ...medicineInRequest,
        frequency: Number(medicineInData.frequency),
        amount: Number(medicineInData.amount),
      };
    await updateInBalance(
      Number(currentInBalance?.in_balance_id),
      medicineInRequest
    );
    handleClose();
    handleGetInBalanceData();
    setIsLoading(false);
  };

  const isInvalidTime = (start: dayjs.Dayjs, end: dayjs.Dayjs) => {
    return end?.isBefore(start) || end?.isSame(start);
  };

  const getStartTimeOfDate = (current: dayjs.Dayjs) => {
    return dayjs(`${current.format(DATE_FORMAT.YYYY_MM_DD)} 00:00:00`);
  };

  const getEndTimeOfDate = (current: dayjs.Dayjs) => {
    return dayjs(`${current?.format(DATE_FORMAT.YYYY_MM_DD)} 23:59:59`);
  };

  const getStartDate = (current: dayjs.Dayjs, endOfDate?: boolean) => {
    if (medicineInData.start_time) {
      return dayjs(
        `${current.format(DATE_FORMAT.YYYY_MM_DD)} ${
          medicineInData.start_time
        }:00`
      );
    }
    return endOfDate ? getEndTimeOfDate(current) : getStartTimeOfDate(current);
  };

  const getEndDate = (current: dayjs.Dayjs) => {
    return medicineInData.end_time
      ? dayjs(
          `${current?.format(DATE_FORMAT.YYYY_MM_DD)} ${
            medicineInData.end_time
          }:00`
        )
      : getEndTimeOfDate(current);
  };

  const disabledStartDate = (current: dayjs.Dayjs) => {
    const startDate = getStartDate(current);
    const endDate = getEndDate(dayjs(medicineInData.end_date));
    return isInvalidTime(startDate, endDate);
  };

  const disabledEndDate = (current: dayjs.Dayjs) => {
    const startDate = getStartDate(dayjs(medicineInData.start_date));
    const endDate = getEndDate(current);
    return isInvalidTime(startDate, endDate);
  };

  const disabledStartTime = (current: dayjs.Dayjs) => {
    const endDate = getEndDate(dayjs(medicineInData.end_date));
    const isDisabled = (time: dayjs.Dayjs) => {
      return isInvalidTime(time, endDate);
    };
    return {
      disabledHours: () => {
        let hours: number[] = [];
        for (let i = 0; i < 24; i++) {
          const startDate = dayjs(
            `${medicineInData.start_date} ${String(i).padStart(
              2,
              "0"
            )}:${current.format("mm")}:00`
          );
          if (isDisabled(startDate)) {
            hours.push(i);
          }
        }
        return hours;
      },
      disabledMinutes: () => {
        let minutes: number[] = [];
        for (let i = 0; i < 60; i++) {
          const startDate = dayjs(
            `${medicineInData.start_date} ${current.format("HH")}:${String(
              i
            ).padStart(2, "0")}:00`
          );
          if (isDisabled(startDate)) {
            minutes.push(i);
          }
        }
        return minutes;
      },
    };
  };

  const disabledEndTime = (current: dayjs.Dayjs) => {
    const startDate = getStartDate(dayjs(medicineInData.start_date));
    const isDisabled = (time: dayjs.Dayjs) => {
      return isInvalidTime(startDate, time);
    };
    return {
      disabledHours: () => {
        let hours: number[] = [];
        for (let i = 0; i < 24; i++) {
          const endDate = dayjs(
            `${medicineInData.end_date} ${String(i).padStart(
              2,
              "0"
            )}:${current.format("mm")}:00`
          );
          if (isDisabled(endDate)) {
            hours.push(i);
          }
        }
        return hours;
      },
      disabledMinutes: () => {
        let minutes: number[] = [];
        for (let i = 0; i < 60; i++) {
          const endDate = dayjs(
            `${medicineInData.end_date} ${current.format("HH")}:${String(
              i
            ).padStart(2, "0")}:00`
          );
          if (isDisabled(endDate)) {
            minutes.push(i);
          }
        }
        return minutes;
      },
    };
  };

  const disableOk = () => {
    let isDisabled = false;
    ["start_date", "start_time", "speed", "frequency"].forEach((key) => {
      if (!medicineInData[key]) {
        isDisabled = true;
        return true;
      }
    });
    if (isDisabled) return isDisabled;
    if (
      (medicineInData.end_date && !medicineInData.end_time) ||
      (!medicineInData.end_date && medicineInData.end_time)
    ) {
      return true;
    }
    const start = getStartDate(dayjs(medicineInData.start_date));
    const end = getEndDate(dayjs(medicineInData.end_date));
    if (isInvalidTime(start, end)) {
      return true;
    }

    return (
      validatorError("amount", medicineInData.amount) ||
      validatorError("speed", medicineInData.speed) ||
      end.isBefore(start) ||
      end.isSame(start)
    );
  };

  const validatorError = (field: string, value: number | string): boolean => {
    if (field === "amount" && value && Number(value) <= 0) {
      return true;
    }
    if (field === "speed" && (!value || Number(value) <= 0)) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Modal
        title={t("Edit info")}
        open={isShow}
        onCancel={handleClose}
        className="medicine-modal edit-medicine-modal form-medicine-modal"
        okText="Save"
        centered
        onOk={handleOk}
        okButtonProps={{ disabled: disableOk() }}
      >
        <div>
          <Form layout="vertical">
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={t("Start date")}
                  name="start_date"
                  rules={[
                    { required: true, message: t("Please input start date!") },
                  ]}
                >
                  <DatePicker
                    defaultValue={
                      medicineInData.start_date
                        ? dayjs(medicineInData.start_date)
                        : null
                    }
                    value={
                      medicineInData.start_date
                        ? dayjs(medicineInData.start_date)
                        : null
                    }
                    format={DATE_FORMAT.YYYY_MM_DD}
                    onChange={(value) => {
                      handleChange(
                        "start_date",
                        value ? value.format(DATE_FORMAT.YYYY_MM_DD) : ""
                      );
                    }}
                    disabledDate={disabledStartDate}
                    placeholder=""
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={t("End date")} name="end_date">
                  <DatePicker
                    defaultValue={
                      medicineInData.end_date
                        ? dayjs(medicineInData.end_date)
                        : null
                    }
                    value={dayjs(medicineInData.end_date)}
                    format={DATE_FORMAT.YYYY_MM_DD}
                    onChange={(value) => {
                      handleChange(
                        "end_date",
                        value ? value.format(DATE_FORMAT.YYYY_MM_DD) : ""
                      );
                    }}
                    disabledDate={disabledEndDate}
                    placeholder=""
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={t("Start time")}
                  name="start_time"
                  rules={[
                    { required: true, message: t("Please input start time!") },
                  ]}
                >
                  <TimePicker
                    defaultValue={
                      medicineInData.start_time
                        ? dayjs(medicineInData.start_time, DATE_FORMAT.HHmm)
                        : null
                    }
                    value={dayjs(medicineInData.start_time, DATE_FORMAT.HHmm)}
                    format={DATE_FORMAT.HHmm}
                    onChange={(value) => {
                      handleChange(
                        "start_time",
                        value ? value.format(DATE_FORMAT.HHmm) : ""
                      );
                    }}
                    changeOnScroll
                    needConfirm={false}
                    suffixIcon={false}
                    disabledTime={disabledStartTime}
                    showNow={false}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={t("End time")} name="end_time">
                  <TimePicker
                    defaultValue={
                      medicineInData.end_time
                        ? dayjs(medicineInData.end_time, DATE_FORMAT.HHmm)
                        : null
                    }
                    value={dayjs(medicineInData.end_time, DATE_FORMAT.HHmm)}
                    format={DATE_FORMAT.HHmm}
                    onChange={(value) => {
                      handleChange(
                        "end_time",
                        value ? value.format(DATE_FORMAT.HHmm) : ""
                      );
                    }}
                    changeOnScroll
                    needConfirm={false}
                    suffixIcon={false}
                    disabledTime={disabledEndTime}
                    showNow={false}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={t("Administration rate")}
                  name="speed"
                  rules={[
                    {
                      validator: async (_, speed) => {
                        if (validatorError("speed", speed)) {
                          return Promise.reject(
                            new Error(
                              t(
                                "Administration rate is required, must be number and greater than 0"
                              )
                            )
                          );
                        }
                      },
                    },
                  ]}
                >
                  <Input
                    defaultValue={medicineInData.speed}
                    value={medicineInData.speed}
                    onChange={(e) => {
                      handleChange("speed", Number(e.target.valueAsNumber));
                    }}
                    type="number"
                    suffix={
                      <span className="surfix">{MEDICINE_UNIT.SPEED}</span>
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t("Amount")}
                  name="amount"
                  rules={[
                    {
                      validator: async (_, amount) => {
                        if (validatorError("amount", amount)) {
                          return Promise.reject(
                            new Error(
                              t("amount must be number and greater than 0")
                            )
                          );
                        }
                      },
                    },
                  ]}
                >
                  <Input
                    defaultValue={medicineInData.amount}
                    value={medicineInData.amount}
                    onChange={(e) => {
                      handleChange("amount", Number(e.target.valueAsNumber));
                    }}
                    type="number"
                    suffix={
                      <span className="surfix">{MEDICINE_UNIT.AMOUNT}</span>
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label={t("Frequency")} name="frequency">
                  <Select
                    defaultValue={medicineInData.frequency}
                    value={medicineInData.frequency}
                    onChange={(value) => {
                      handleChange("frequency", value);
                    }}
                    options={MEDICINE_FREQUENCY_OPTIONS.map((option) => ({
                      value: option.value,
                      label: option.text,
                    }))}
                    disabled={
                      (!!currentInBalance?.frequency && !isCopy) ||
                      !medicineInData.amount
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default EditMedicineModal;
