import { ConfigProvider, Input, Select, Table, Tree } from "antd";
import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BALANCE_IN_STATUS,
  MEDICINE_CATEGORIES
} from "../../../constants/vital-sign/InOutTable.constant";
import { MEDICINE_UNIT_OPTIONS } from "../../../constants/vital-sign/Medicine.constant";
import { IN_TABLE_CLASSNAME } from "../../../constants/vital-sign/VitalSignTable.constant";
import "./InTable.scss";
export interface PdfInTableType {
  [key: string]: string | PdfMedicineColumn | AmountPerHr | number | undefined;
}

export interface PdfMedicineColumn {
  in_balance_id?: number;
  category?: string;
  name?: string;
  medicines?: Medicine[];
  amount?: number;
  speed?: number;
  frequency?: number;
  unit?: string;
  isTotal?: boolean;
  start_date?: string;
  end_date?: string;
  start_time?: string;
  end_time?: string;
}

export interface AmountPerHr {
  id?: number;
  numerator: number;
  denominator: number;
  stoped?: boolean;
}

export interface Medicine {
  id: number;
  name?: string;
  dose_unit?: string;
  dose_amount?: number;
  code?: string;
  isEditing?: boolean;
  medicine_id: number;
}
interface PdfInTableProps {
  inTableDataSource: PdfInTableType[];
  colHeaders: string[];
}

type EditableTableProps = Parameters<typeof Table>[0];
type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

const PdfInTable = ({
  inTableDataSource,
  colHeaders,
}: PdfInTableProps) => {
  const { t } = useTranslation();

  // generate one medicine element in_balance (name, amount, unit)
  const generateMedicineElement = (
    id_in_balance: number,
    medicine: Medicine
  ) => {
    return (
      <div className="medicine-component">
        <div className="medicine-info">
          <span className="medicine-name">{medicine.name}</span>
          <Input
            type="number"
            className="medicine-ml"
            defaultValue={medicine.dose_amount}
            min={0}
            key={`${medicine.dose_amount}-${medicine.id}-${medicine.dose_unit}-amount`}
          />
          <Select
            className="unit-options"
            defaultValue={medicine.dose_unit}
            options={MEDICINE_UNIT_OPTIONS.map((option) => ({
              value: option.value,
              label: option.text,
            }))}
            key={`${medicine.dose_amount}-${medicine.id}-${medicine.dose_unit}-unit`}
          />
        </div>
      </div>
    );
  };

  // generate total medicine info element of in balance (amount, speed, frequency)
  const generateMedicineAmountElement = (
    amount: number,
    speed: number,
    frequency: number,
    unit: string
  ) => {
    return (
      <div className="medicine-component">
        <div className="medicine-info">
          <span className="medicine-ml">
            {amount}
            {unit}
          </span>
          <span className="medicine-ml">
            {speed}
            {unit}/hr
          </span>
          <span className="medicine-ml">
            {frequency > 1 ? `${frequency} times/day` : `${frequency} time/day`}
          </span>
        </div>
      </div>
    );
  };

  // generate medicine tree for in balance form many medicine element and one total medicine info element
  const generateMedicineTree = (record: PdfMedicineColumn) => {
    const medicines = record.medicines ?? [];
    if (!medicines.length) {
      return <></>;
    }
    const treeData = [
      {
        title: (
          <Select
            style={{ width: 120 }}
            defaultValue={record.category ?? MEDICINE_CATEGORIES[0]}
            options={MEDICINE_CATEGORIES.map((item) => ({
              value: item,
              label: item,
            }))}
            className="medicine-tree-title"
          />
        ),
        key: `0-0`,
        children: [
          ...medicines.map((medicine) => ({
            title: generateMedicineElement(
              Number(record.in_balance_id),
              medicine
            ),
            key: `0-0-${record.in_balance_id}__${medicine.id}`,
          })),
          {
            title: generateMedicineAmountElement(
              Number(record.amount),
              Number(record.speed),
              Number(record.frequency),
              record.unit ?? ""
            ),
            key: `0-0-${record.in_balance_id}`,
          },
        ],
      },
    ];
    return (
      <>
        <div className="medicine-cell">
          <Tree
            treeData={treeData}
            showLine
            switcherIcon={null}
            defaultExpandAll
            className="medicine-tree"
          />
        </div>
      </>
    );
  };

  // generate in balance time data columns for in table
  const dynamicColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
    id?: string;
    value?: string | null;
  })[] = colHeaders.map((col) => {
    return {
      title: (
        <div className="title">
          <div>{col}</div>
        </div>
      ),
      dataIndex: col,
      key: col,
      align: "center",
      className: "in-table-col",
      width: `80px`,
      render(record, row) {
        const numerator = record?.numerator;
        const denominator = record?.denominator;
        let cellString = "";
        if (
          (numerator || row.medicines.isTotal) &&
          numerator !== undefined &&
          denominator !== undefined
        ) {
          cellString = numerator + "/" + denominator;
        }
        return (
          <div className={colClassName(record as AmountPerHr)}>
            {cellString ? (
              <div className={`${!row.status ? "inactive" : ""}`}>
                <div className="in-table_cell">
                  <div>{cellString}</div>
                </div>

                <div className="in-table-status">
                  <span className="active"></span>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        );
      },
    };
  });

  // generate first column in table
  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
    id?: string;
    value?: string | null;
  })[] = [
    {
      title: "",
      dataIndex: "medicines",
      key: "medicines",
      align: "left",
      className: "",
      width: "400px",
      fixed: "left",
      render(record, row) {
        if (record?.isTotal) {
          return (
            <>
              <div className="in-total-style">{record.name}</div>
            </>
          );
        } else {
          return generateMedicineTree(record);
        }
      },
    },
    ...dynamicColumns,
  ];

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => {
        return {
          record,
          editable:
            record?.medicines?.isTotal ||
            record.status === BALANCE_IN_STATUS.inActive
              ? false
              : col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          id: record?.medicines?.id ?? undefined,
          typeId: record.typeId,
        };
      },
    };
  });

  const [inTableHeight, setInTableHeight] = useState<number>(() => {
    const initVentilatorTableElement =
      document.getElementsByClassName(IN_TABLE_CLASSNAME);

    if (initVentilatorTableElement.length > 0) {
      return (initVentilatorTableElement[0] as HTMLDivElement).offsetHeight;
    }

    return 0;
  });

  useEffect(() => {
    if (
      !isEqual(
        document.getElementsByClassName(IN_TABLE_CLASSNAME),
        inTableHeight
      ) &&
      document.getElementsByClassName(IN_TABLE_CLASSNAME).length > 0
    ) {
      setInTableHeight(
        (
          document.getElementsByClassName(
            IN_TABLE_CLASSNAME
          )[0] as HTMLDivElement
        ).offsetHeight
      );
    }
  });

  const colClassName = (record: AmountPerHr) => {
    return record?.stoped ? "inbalance-in-active" : "";
  };

  return (
    <>
      <div
        className={"pdf-in-table-section"}
        style={{
          height: inTableHeight,
        }}
      >
        <div
          className={"pdf-in-table-header"}
          style={{
            width: inTableHeight - 65,
            top: inTableHeight,
          }}
        >
          {t("IN")}
        </div>
        <ConfigProvider
          theme={{
            components: {
              Table: {
                headerBorderRadius: 0,
              },
            },
          }}
        >
          <Table
            dataSource={inTableDataSource}
            pagination={false}
            bordered
            className={`${IN_TABLE_CLASSNAME} pdf-in-table`}
            columns={columns as ColumnTypes}
            rowHoverable={false}
            tableLayout="fixed"
            scroll={{
              x: 80,
            }}
          />
        </ConfigProvider>
      </div>
    </>
  );
};
export default PdfInTable;
