export const IN_OUT_TABLE_HEADER = [
    "00:00",
    "01:00",
    "02:00",
    "03:00",
    "04:00",
    "05:00",
    "06:00",
    "07:00",
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
    "23:00",
];
export const MEDICINE_IN = "MEDICINE_IN";
export const MEDICINE_OUT = "MEDICINE_OUT";

export const BALANCE_IN_DELETE_TEMP = 'suspend';
export const BALANCE_IN_DELETE_COMP = 'delete'
export const BALANCE_IN_DELETE_REACTIVE = 'active'

export const DELETE_OPTIONS = [
    {
        key: 1,
        value: BALANCE_IN_DELETE_TEMP,
        label: 'Stop'
    },
    {
        key: 2,
        value: BALANCE_IN_DELETE_COMP,
        label: 'Delete'
    }
]

export enum BALANCE_IN_STATUS {
    active = 1,
    inActive = 2
}

export const MEDICINE_CATEGORIES = [
    'IV',
    'PO',
    'SC',
    'ID',
    'IM',
    'IP',
    'IT',
    'IO'
]

export enum BALANCE_IN_COPY_STEP {
    copyMedicines = 1,
    copyTimes = 2
}