import {
  API_CREATE_VITAL_SIGN_OCR,
  API_GET_VITAL_SIGN,
  API_PUT_VITAL_SIGN,
  API_UPDATE_VITAL_SIGN,
} from "../../configs/api/endpoints";
import networkAdapter from "../../configs/api/networkAdapter";
import { BaseResponse } from "../../types/common/BaseResponse.model";
import { VitalSignReadResponseData } from "../../types/vital-sign/VitalSignReadResponse.model";
import { VitalSignUpdateResponse } from "../../types/vital-sign/VitalSignUpdateResponse.model";

export const getAllVitalSigns = (
  admissionRecordId: number,
  date?: string
): Promise<BaseResponse<VitalSignReadResponseData>> => {
  return networkAdapter.get(`${API_GET_VITAL_SIGN}/${admissionRecordId}`, {
    date,
  });
};

export const updateVitalSign = (
  id: number,
  value: number | null
): Promise<BaseResponse<VitalSignUpdateResponse>> => {
  return networkAdapter.patch(API_UPDATE_VITAL_SIGN, {
    id,
    value,
  });
};

export const putVitalSign = (
  admissionRecordId: number,
  typeId: number,
  value: number | null,
  createdAt: string
) => {
  return networkAdapter.put(API_PUT_VITAL_SIGN, {
    id_admission_record: admissionRecordId,
    id_type: typeId,
    value: value,
    created_at: createdAt,
  });
};

export const createVitalSignOCR = (
  admissionRecordId: string,
  image: string,
  created_date: string
) => {
  const date = new Date();
  const timezoneOffset = 0 - date.getTimezoneOffset() / 60;
  return networkAdapter.post(API_CREATE_VITAL_SIGN_OCR, {
    id_admission_record: admissionRecordId,
    image,
    created_date,
    time_offset: timezoneOffset
  });
};
