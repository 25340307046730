import { ConfigProvider, Input, Table } from "antd";
import { useTranslation } from "react-i18next";
import "./OutTable.scss";
import { OUT_TABLE_CLASSNAME } from "../../../constants/vital-sign/VitalSignTable.constant";
import { useEffect, useState } from "react";
import { isEqual } from "lodash";
import { generateRandomNumber } from "../../../utils/helpers";

export interface OutTableType {
  [key: string]: string | OutMedicine | number | OutAmountPerHr;
}

type EditableTableProps = Parameters<typeof Table>[0];
type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

export interface OutMedicine {
  id?: number;
  name?: string;
  isTotal: boolean;
}
export interface OutAmountPerHr {
  id?: number;
  numerator: number;
  denominator: number;
}

interface PdfOutTableProps {
  dataSources: OutTableType[];
  colHeaders: string[];
}

const PdfOutTable = ({ dataSources, colHeaders }: PdfOutTableProps) => {
  const { t } = useTranslation();

  const generateMedicineElement = (medicine: OutMedicine) => {
    return (
      <>
        {!medicine?.isTotal && medicine?.name ? (
          <div className="medicine-component">
            <div className="medicine-info">
              <Input
                className="medicine-name"
                defaultValue={medicine.name}
                style={{
                  width: `${medicine.name.length * 8 + 10}px`,
                  maxWidth: "400px",
                }}
              />
            </div>
          </div>
        ) : (
          <div className="in-total-style">{medicine.name}</div>
        )}
      </>
    );
  };

  const dynamicColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
    id?: string;
    value?: string | null;
  })[] = colHeaders.map((col) => {
    return {
      title: (
        <div className="title">
          <div>{col}</div>
        </div>
      ),
      dataIndex: col,
      key: col,
      align: "center",
      className: "out-table-col",
      width: `80px`,
      render(outValue, row) {
        const numerator = outValue?.numerator;
        const denominator = outValue?.denominator;
        let cellString = "";
        if (
          (numerator || row.medicine.isTotal) &&
          numerator !== undefined &&
          denominator !== undefined
        ) {
          cellString = numerator + "/" + denominator;
        }

        return (
          <div className="in-table_cell">
            <>{cellString}</>
          </div>
        );
      },
    };
  });

  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
    id?: string;
    value?: string | null;
  })[] = [
    {
      title: "",
      dataIndex: "medicine",
      key: "medicine",
      align: "left",
      className: "",
      width: "400px",
      fixed: "left",
      render(record) {
        return generateMedicineElement(record);
      },
    },
    ...dynamicColumns,
  ];

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => {
        return {
          record,
          editable:
            record?.medicine?.isTotal || !record?.medicine?.id
              ? false
              : col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          id: record.medicine.id ?? undefined,
          typeId: record.typeId,
        };
      },
    };
  });

  const [outTableHeight, setOutTableHeight] = useState<number>(() => {
    const initVentilatorTableElement =
      document.getElementsByClassName(OUT_TABLE_CLASSNAME);

    if (initVentilatorTableElement.length > 0) {
      return (initVentilatorTableElement[0] as HTMLDivElement).offsetHeight;
    }

    return 0;
  });

  useEffect(() => {
    if (
      !isEqual(
        document.getElementsByClassName(OUT_TABLE_CLASSNAME),
        outTableHeight
      ) &&
      document.getElementsByClassName(OUT_TABLE_CLASSNAME).length > 0
    ) {
      setOutTableHeight(
        (
          document.getElementsByClassName(
            OUT_TABLE_CLASSNAME
          )[0] as HTMLDivElement
        ).offsetHeight
      );
    }
  });

  return (
    <>
      <div
        className={"pdf-out-table-section"}
        style={{
          height: outTableHeight + 54,
        }}
      >
        <div
          className={"pdf-out-table-header"}
          style={{
            width: outTableHeight,
            top: outTableHeight + 54,
          }}
        >
          {t("OUT")}
        </div>
        <ConfigProvider
          theme={{
            components: {
              Table: {
                headerBorderRadius: 0,
              },
            },
          }}
        >
          {dataSources && (
            <Table
              dataSource={dataSources}
              pagination={false}
              bordered
              className={`${OUT_TABLE_CLASSNAME} pdf-out-table`}
              columns={columns as ColumnTypes}
              rowHoverable={false}
              tableLayout="fixed"
              rowKey={(record) => `${record.typeId}-${generateRandomNumber()}`}
              scroll={{
                x: 80,
              }}
            />
          )}
        </ConfigProvider>
      </div>
    </>
  );
};
export default PdfOutTable;
